/* eslint-disable max-len, import/no-dynamic-require */
import React from "react";
import KeyValue from "../common/KeyValue";
import PieChart from "../common/PieChart";
import { CATEGORIES } from "../../internals/constants";
import NoDataOnCountry from "./NoDataOnCountry";
import { showMissingValues } from "../../internals/utils";

function Consumption({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data, [
    "cannabis_use",
    "cigarette_consumption",
    "cocaine_use",
    "meat_consumption",
    "milk_consumption",
    "opiates_use",
    "alcohol_consumption",
    "gun_ownership",
    "tobacco_use",
    "",
  ]);

  return (
    <div>
      <br />
      <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="cannabis_use"
        value={data.cannabis_use?.prevalence_rate_percentage}
        units={"Percent of the population"}
        source={data.cannabis_use?.info?.link}
        date={data.cannabis_use?.year}
      />
      <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="cigarette_consumption"
        value={data.cigarette_consumption?.cigarettes}
        units={"a year per person (average)"}
        source={data.cigarette_consumption?.info?.source}
        date={data.cigarette_consumption?.info?.date}
      />
      <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="cocaine_use"
        value={data.cocaine_use?.prevalence_rate_percentage}
        units={"Percent of the population"}
        source={data.cannabis_use?.info?.link}
        date={data.cannabis_use?.year}
      />
      <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="meat_consumption"
        value={data.meat_consumption?.kg_per_person}
        units={"KG per person per year"}
        source={data.meat_consumption?.info?.link}
        date={data.meat_consumption?.info?.date}
      />
      <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="milk_consumption"
        value={data.milk_consumption?.kg_per_year_per_capita_2013}
        units={"KG per person per year"}
        source={data.milk_consumption?.info?.link}
        date={data.milk_consumption?.info?.year}
      />
      <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="opiates_use"
        value={data.opiates_use?.prevalence_rate_percentage}
        units={"Percent of the population"}
        source={data.opiates_use?.info?.link}
      />
      <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="alcohol_consumption"
        value={data.alcohol_consumption?.total_liters}
        units={"Liter per person per year"}
        source={data.opiates_use?.info?.link}
      />
      <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="gun_ownership"
        value={data.gun_ownership?.firearmPossessionPer100K}
        units={"per 100k population"}
        source={data.gun_ownership?.info?.link}
      />
      <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="tobacco_use"
        value={parseFloat(data.tobacco_use?.rate)}
        units={"percent of ppl older than 15"}
        source={data.tobacco_use?.info?.link}
      />
      {data?.alcohol_consumption?.beer_percentage && (
        <PieChart
          series={[
            data?.alcohol_consumption?.beer_percentage,
            data?.alcohol_consumption?.spirits_percentage,
            data?.alcohol_consumption?.wine_percentage,
            data?.alcohol_consumption?.other_percentage,
          ]}
          labels={["Beer", "Spirits", "Wine", "Other"]}
          suffix="%"
        />
      )}
      <br />
    </div>
  );
}

export default Consumption;
