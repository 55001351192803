import styled from 'styled-components/macro';

const Wrapper = styled.div`
  max-height: 40px;
  z-index: 2
`;

const StyledImg = styled.img`
  width: 24px;
  height: auto;
  margin-right: 12px;
`;

export default {
  StyledImg,
  Wrapper,
};
