// Internals
import ACTION_TYPES from './actionTypes';

import {
  // updateQueryString,
  // pauseNationalAnthem,
} from '../internals/utils';

function updateCountry(countryCode, setCountryQS) {
  return async function updateCountryThunk(dispatch) {
    setCountryQS(countryCode);
    if (countryCode === '') {
      dispatch({
        type: ACTION_TYPES.INIT_COUNTRY,
      });
    } else {
      try {
        const response = await fetch(`/api/country/${countryCode}`);
        let countryData = await response.text();
        countryData = JSON.parse(countryData);
        
        dispatch({
          type: ACTION_TYPES.UPDATE_COUNTRY,
          payload: countryData,
        });
      } catch (error) {
        console.error('api/countries Error:');
        console.error(error);
      }
    }
  };
}

function updateKey(key, setTabQS) {
  return async function updateKeyThunk(dispatch) {
    setTabQS(key);
    if (key === '') {
      dispatch({
        type: ACTION_TYPES.INIT_KEY,
      });
    } else {
      try {
        const response = await fetch(`/api/key/${key}`);
        let keyData = await response.text();
        keyData = JSON.parse(keyData);

        dispatch({
          type: ACTION_TYPES.UPDATE_KEY,
          payload: keyData,
        });
      } catch (error) {
        console.error('api/key Error:');
        console.error(error);
      }
    }
  };
}

const updateTab = (tab, setKeyQS) => {
  setKeyQS(tab);
  // pauseNationalAnthem(tab);

  return {
    type: ACTION_TYPES.UPDATE_TAB,
    payload: tab,
  };
};

export default {
  updateCountry,
  updateTab,
  updateKey,
};
