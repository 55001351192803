import React from 'react';
import styled from 'styled-components/macro';
import { Card } from 'antd';
import Tooltip from '../Tooltip';

const { Meta } = Card;

const StyledCard = styled(Card)`
  border-width: 2px;
  width: 185px; 
  margin: 10px 20px;
`;

const StyledImg = styled.img`
  width: auto;
  height: 120px;
  margin: 5px auto;
`;

function LinkComponent({ imgSrc, link, alt, title, description }) {
  if (!link) return null;

  return (
    <Tooltip placement="top" title={ description }>
      <a href={ link } target="_blank" rel="noopener noreferrer">
        <StyledCard
          hoverable
          cover={ <StyledImg alt={ alt } src={ imgSrc } /> }
        >
          <Meta title={ title } />
        </StyledCard>
      </a>
    </Tooltip>
  );
}

export default LinkComponent;
