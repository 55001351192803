/* eslint-disable max-len, camelcase */
import React from "react";

import NoDataOnCountry from "./NoDataOnCountry";
import KeyValue from "../common/KeyValue";
import { CATEGORIES } from "../../internals/constants";
import Notes from "../common/Notes";
import { showMissingValues } from "../../internals/utils";

function MilitaryAndSecurity({ data }) {
  if (!data || data === {}) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data);

  return (
    <div>
      <KeyValue
        tab={CATEGORIES.MILITARY_AND_SECURITY}
        name="expenditures"
        value={data.expenditures?.annual_values?.[0]?.value}
        date={data.expenditures?.annual_values?.[0]?.date}
        units={data.expenditures?.annual_values?.[0]?.units}
        rank={data.expenditures?.global_rank}
      />
      <br />

      <KeyValue
        tab={CATEGORIES.MILITARY_AND_SECURITY}
        name="service_age_and_obligation"
        date={data.service_age_and_obligation?.date}
        value={data.service_age_and_obligation?.years_of_age}
        units="years_of_age"
      />
      <Notes note={data.service_age_and_obligation?.note} />
    </div>
  );
}

export default MilitaryAndSecurity;
