import styled, { css } from 'styled-components/macro';
import { COLORS, dimensions } from '../App/App.styles';
import { setMediaQuery, BREAKPOINTS } from '../../context/mediaQueriesHelpers';

const Wrapper = styled.div`
  min-height: calc(100% - ${dimensions.navbarHeight}px - ${dimensions.footerHeight}px);
  display: flex;

  ${setMediaQuery(BREAKPOINTS.PHONE, BREAKPOINTS.TABLET)`
    flex-direction: column;
  `}
  ${setMediaQuery(BREAKPOINTS.SCREEN, BREAKPOINTS.MAX)`
    flex-direction: row-reverse;
    padding-top: 50px;
  `}
`;

const ImgWrapper = styled.div``;

const StyledImg = styled.img`
  width: 100%;
  margin: auto;
  padding: 0 20px;
`;

const Form = styled.form`
  ${setMediaQuery(BREAKPOINTS.PHONE, BREAKPOINTS.TABLET)`
    margin: auto;
    min-width: 333px;
    `}
  ${setMediaQuery(BREAKPOINTS.SCREEN, BREAKPOINTS.MAX)`
    width: 500px;
  `}
  max-width: 500px;
  padding: 0 20px;
`;

const Header = styled.h1`
  text-align: center;
`;

const InputsStyle = css`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
`;

const Input = styled.input`
  ${InputsStyle}

  &[type=submit] {
    -webkit-appearance: none;
    background: ${COLORS.lightBG};
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;

    &:hover {
      cursor: pointer;
      background: ${COLORS.bg};
    }
    &:active {
      transition: 0.3s all;
      transform: translateY(3px);
      border: 1px solid transparent;
      opacity: 0.8;
    }
  }
`;

const Textarea = styled.textarea`
  ${InputsStyle}
`;

const Select = styled.select`
  ${InputsStyle}
  option{
    font-size: 16px;
    line-height: 58px;
    height: 20px;

  }
`;

const Error = styled.p`
  color: #bf1650;
  font-size: 16px;

  &::before {
    display: inline;
    content: "⚠ ";
  }
`;

const Submitted = styled.p`
  color: ${COLORS.bg};
    font-size: 16px;
    font-weight: 700;

  &::after {
    display: inline;
    content: " 👍";
  }
`;

// const Label = styled.label`
//   line-height: 28px;
// `;

export default {
  Wrapper,
  Header,
  ImgWrapper,
  StyledImg,
  Form,
  Input,
  Textarea,
  Select,
  // Label,
  Submitted,
  Error,
  /* StyledInput, */
};
