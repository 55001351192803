/* eslint-disable max-len, camelcase */
// Modules
import React from "react";

// Internals
import { CATEGORIES } from "../../internals/constants";
import { showMissingValues } from "../../internals/utils";

// Components
import NoDataOnCountry from "./NoDataOnCountry";
import KeyValue from "../common/KeyValue";
import Notes from "../common/Notes";

// Styles
import Styles from "./Tab.styles";

function Communication({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry ta />;
  }

  showMissingValues(data);

  return (
    <div>
      <Styles.Title>Telephone:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.COMMUNICATION}.telephones`}
        name="fixed_lines"
        date={data.telephones?.fixed_lines?.date}
        value={
          data.telephones?.fixed_lines
            ?.subscriptions_per_one_hundred_inhabitants
        }
        units="%"
        rank={data.telephones?.fixed_lines?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.COMMUNICATION}.telephones`}
        name="mobile_cellular"
        date={data.telephones?.mobile_cellular?.date}
        value={
          data.telephones?.mobile_cellular
            ?.subscriptions_per_one_hundred_inhabitants
        }
        units="%"
        rank={data.telephones?.mobile_cellular?.global_rank}
      />
      <br />

      <Styles.Title>Internet:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.COMMUNICATION}.internet`}
        name="users"
        date={data.internet?.users?.date}
        value={data.internet?.users?.percent_of_population}
        units="%"
        rank={data.internet?.users?.global_rank}
      />
      <br />

      {data?.broadcast_media && <Styles.Title>Broadcast Media:</Styles.Title>}
      <Notes note={data?.broadcast_media} />

      {data?.note && <Styles.Title>Notes:</Styles.Title>}
      <Notes note={data?.note} />
    </div>
  );
}

export default Communication;
