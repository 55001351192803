/* eslint-disable max-len, camelcase */
import React from "react";
import { map } from "lodash-es";
import PieChart from "../common/PieChart";
import Styles from "./Tab.styles";
import NoDataOnCountry from "./NoDataOnCountry";
import { showMissingValues } from "../../internals/utils";

import KeyValue from "../common/KeyValue";
import { CATEGORIES } from "../../internals/constants";
import { myStartCase } from "../../internals/utils";

function Energy({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data);

  return (
    <div>
      <Styles.Title>Electricity:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.electricity.access`}
        name="total_electrification"
        date={data.electricity?.access?.date}
        value={data.electricity?.access?.total_electrification.value}
        units={data.electricity?.access?.total_electrification.units}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.electricity`}
        name="consumption"
        date={data.electricity?.consumption?.date}
        value={data.electricity?.consumption?.kWh}
        units="kw/h"
        rank={data.electricity?.consumption?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.electricity`}
        name="production"
        date={data.electricity?.production?.date}
        value={data.electricity?.production?.kWh}
        units="kw/h"
        rank={data.electricity?.production?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.electricity`}
        name="imports"
        date={data.electricity?.imports?.date}
        value={data.electricity?.imports?.kWh}
        units="kw/h"
        rank={data.electricity?.imports?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.electricity`}
        name="exports"
        date={data.electricity?.exports?.date}
        value={data.electricity?.exports?.kWh}
        units="kw/h"
        rank={data.electricity?.exports?.global_rank}
      />
      <KeyValue tab={`${CATEGORIES.ENERGY}.electricity`} name="by_source" />
      {data.electricity?.by_source && (
        <PieChart
          series={map(data.electricity.by_source, (obj) => obj.percent)}
          labels={Object.keys(data.electricity.by_source).map((key) =>
            myStartCase(key)
          )}
          suffix="%"
        />
      )}
      <br />

      <Styles.Title>Oil:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.crude_oil`}
        name="production"
        date={data.crude_oil?.production?.date}
        value={data.crude_oil?.production?.bbl_per_day}
        units="bbl_per_day"
        rank={data.crude_oil?.production?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.crude_oil`}
        name="imports"
        date={data.crude_oil?.imports?.date}
        value={data.crude_oil?.imports?.bbl_per_day}
        units="bbl_per_day"
        rank={data.crude_oil?.imports?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.crude_oil`}
        name="exports"
        date={data.crude_oil?.exports?.date}
        value={data.crude_oil?.exports?.bbl_per_day}
        units="bbl_per_day"
        rank={data.crude_oil?.exports?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.crude_oil`}
        name="proved_reserves"
        date={data.crude_oil?.proved_reserves?.date}
        value={data.crude_oil?.proved_reserves?.bbl}
        units="bbl"
        rank={data.crude_oil?.proved_reserves?.global_rank}
      />
      <br />

      <Styles.Title>Natural Gas:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.natural_gas`}
        name="production"
        date={data.natural_gas?.production?.date}
        value={data.natural_gas?.production?.cubic_metres}
        units="cubic_metres"
        rank={data.natural_gas?.production?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.natural_gas`}
        name="consumption"
        date={data.natural_gas?.consumption?.date}
        value={data.natural_gas?.consumption?.cubic_metres}
        units="cubic_metres"
        rank={data.natural_gas?.consumption?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.natural_gas`}
        name="imports"
        date={data.natural_gas?.imports?.date}
        value={data.natural_gas?.imports?.cubic_metres}
        units="cubic_metres"
        rank={data.natural_gas?.imports?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.natural_gas`}
        name="exports"
        date={data.natural_gas?.exports?.date}
        value={data.natural_gas?.exports?.cubic_metres}
        units="cubic_metres"
        rank={data.natural_gas?.exports?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.natural_gas`}
        name="proved_reserves"
        date={data.natural_gas?.proved_reserves?.date}
        value={data.natural_gas?.proved_reserves?.cubic_metres}
        units="cubic_metres"
        rank={data.natural_gas?.proved_reserves?.global_rank}
      />

      <br />
      <Styles.Title>Refined Petroleum Products:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.refined_petroleum_products`}
        name="production"
        date={data.refined_petroleum_products?.production?.date}
        value={data.refined_petroleum_products?.production?.bbl_per_day}
        units="bbl_per_day"
        rank={data.refined_petroleum_products?.production?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.refined_petroleum_products`}
        name="consumption"
        date={data.refined_petroleum_products?.consumption?.date}
        value={data.refined_petroleum_products?.consumption?.bbl_per_day}
        units="bbl_per_day"
        rank={data.refined_petroleum_products?.consumption?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.refined_petroleum_products`}
        name="imports"
        date={data.refined_petroleum_products?.imports?.date}
        value={data.refined_petroleum_products?.imports?.bbl_per_day}
        units="bbl_per_day"
        rank={data.refined_petroleum_products?.imports?.global_rank}
      />
      <KeyValue
        tab={`${CATEGORIES.ENERGY}.refined_petroleum_products`}
        name="exports"
        date={data.refined_petroleum_products?.exports?.date}
        value={data.refined_petroleum_products?.exports?.bbl_per_day}
        units="bbl_per_day"
        rank={data.refined_petroleum_products?.exports?.global_rank}
      />

      <br />
      <KeyValue
        tab={CATEGORIES.ENERGY}
        name="carbon_dioxide_emissions_from_consumption_of_energy"
        date={data.carbon_dioxide_emissions_from_consumption_of_energy?.date}
        value={
          data.carbon_dioxide_emissions_from_consumption_of_energy?.megatonnes
        }
        units="megatonnes"
        rank={
          data.carbon_dioxide_emissions_from_consumption_of_energy?.global_rank
        }
      />
    </div>
  );
}

export default Energy;
