import styled from 'styled-components/macro';

const Wrapper = styled.div`
  /* display: grid; */
  /* grid-template-columns: 1fr; */
  
  @media (max-width: 450px) {
    /* grid-auto-rows: auto 100vh; */
    /* grid-auto-rows:  200px; */
    /* grid-auto-rows:  auto; */

  }
  @media (min-width: 451px) and (max-width: 920px) {
    /* grid-template-rows: unset; */
    /* grid-auto-rows:  50vh minmax(100px,60vh) auto; */
  }
  @media (min-width: 921px) {
    /* grid-template-rows: minmax(100px, 75vh) auto;  */
  }

`;

export default {
  Wrapper,
};
