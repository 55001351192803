/* eslint-disable max-len, camelcase */
// Modules
import React from "react";

// Components
import NoDataOnCountry from "./NoDataOnCountry";
import KeyValue from "../common/KeyValue";
import VerticalKeyValue from "../common/VerticalKeyValue";
import Comment from "../common/Comment";

// Internals
import { CATEGORIES } from "../../internals/constants";
import { myStartCase } from "../../internals/utils";
import { showMissingValues } from "../../internals/utils";

// Styles
import Styles from "./Tab.styles";

function Geography({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data, [
    "location",
    "area",
    "forest_area",
    "irrigated_land",
    "coastline",
    "elevation",
    "land_boundaries",
    "natural_resources",
  ]);

  return (
    <div>
      <VerticalKeyValue heading name="Location" value={data.location} />

      <br />
      <Styles.Title>Area:</Styles.Title>
      <ul>
        <KeyValue
          listItem
          tab={`${CATEGORIES.GEOGRAPHY}.area`}
          name="total"
          value={data.area?.total?.value}
          units={data.area?.total?.units}
          rank={data.area?.global_rank}
        />
        <KeyValue
          listItem
          tab={`${CATEGORIES.GEOGRAPHY}.area`}
          name="land"
          value={data.area?.land?.value}
          units={data.area?.land?.units}
        />
        <KeyValue
          listItem
          tab={`${CATEGORIES.GEOGRAPHY}.area`}
          name="water"
          value={data.area?.water?.value}
          units={data.area?.water?.units}
        />
        <KeyValue
          listItem
          tab={CATEGORIES.GEOGRAPHY}
          name="irrigated_land"
          value={data.irrigated_land?.value}
          units={data.irrigated_land?.units}
        />
        <KeyValue
          listItem
          tab={CATEGORIES.GEOGRAPHY}
          name="coastline"
          value={data.coastline?.value}
          units={data.coastline?.units}
        />
        <KeyValue
          listItem
          tab={CATEGORIES.GEOGRAPHY}
          name="forest_area"
          value={parseFloat(data.forest_area?.hectares)}
          units={"hectares"}
        />
      </ul>
      {data?.area?.note && (
        <>
          <Comment prefix="Note:&nbsp;" text={data.area.note} />
        </>
      )}

      <Styles.Title>Elevation:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.GEOGRAPHY}.elevation`}
        name="mean_elevation"
        value={data.elevation?.mean_elevation?.value}
        units={data.elevation?.mean_elevation?.units}
      />
      <KeyValue
        tab={`${CATEGORIES.GEOGRAPHY}.elevation`}
        name="highest_point"
        value={data.elevation?.highest_point?.elevation?.value}
        valueName={data.elevation?.highest_point?.name}
        units={data.elevation?.highest_point?.elevation?.units}
      />
      <KeyValue
        tab={`${CATEGORIES.GEOGRAPHY}.elevation`}
        name="lowest_point"
        value={data.elevation?.lowest_point?.elevation?.value}
        valueName={data.elevation?.lowest_point?.name}
        units={data.elevation?.lowest_point?.elevation?.units}
      />

      <br />
      <Styles.Title>Land Boundaries:</Styles.Title>
      <KeyValue
        style={{ paddingLeft: "40px" }}
        tab={`${CATEGORIES.GEOGRAPHY}.land_boundaries`}
        name="total"
        value={data.land_boundaries?.total?.value}
        units={data.land_boundaries?.total?.units}
      />
      {data.land_boundaries?.border_countries && (
        <>
          <ol>
            {data.land_boundaries.border_countries
              .sort((a, b) => a.border_length - b.border_length)
              .map((b, index) => (
                <KeyValue
                  key={index}
                  listItem
                  name={b.country}
                  value={b.border_length.value}
                  units={b.border_length.units}
                />
              ))}
          </ol>
        </>
      )}
      <br />

      {data.natural_resources?.resources && (
        <>
          <Styles.Title>Natural Resources:</Styles.Title>
          <ol>
            {data.natural_resources.resources.sort().map((nr, index) => (
              <li key={index}>
                <Styles.KeyStyle>{myStartCase(nr)}</Styles.KeyStyle>
              </li>
            ))}
          </ol>
        </>
      )}
    </div>
  );
}

export default Geography;
