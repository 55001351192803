import React from 'react';
import { Tooltip } from 'antd';
import { COLORS } from '../../../routes/App/App.styles';

function CustomTooltip({ children, title, placement = 'left' }) {
  return (
    <Tooltip
      color={ COLORS.bg }
      title={ title }
      placement={ placement }
    >
      { children }
    </Tooltip>
  );
}

export default React.memo(CustomTooltip);
