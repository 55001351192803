// Modules
import React, { useState, useEffect } from 'react';
import { isNumber, isString } from 'lodash-es';

// Internals
import {
  addCommasToNumber,
  myStartCase,
  convertTitle,
} from '../../../internals/utils';
import { CONSTANTS, countryAudioUrlsHash } from '../../../internals/constants';
import { usePrevious } from '../../../hooks';
import { BREAKPOINTS, useBreakpoint } from '../../../context/mediaQueriesHelpers.js';

// Styles
import Styles, { StyledTable } from './ComparisonTable.styles';

function ComparisonTable({
  selectedCountries,
  tableData,
  handleCountryClick,
}) {
  // const { _setCountry } = useContext(MyContext);
  const previousTableData = usePrevious(tableData);
  const [columns, setColumns] = useState([]);
  const [selectedCountriesData, setSelectedCountriesData] = useState([]);
  const breakPoint = useBreakpoint();
  
  useEffect(() => { // Update table
    let columnNames = [...new Set(
      tableData
        .map((item) => Object.keys(item)) // incase some countries have more properties then the first value
        .flat(),
    )];

    if (columnNames.includes('note')) { // relocate 'note' to  position - one before last
      columnNames = columnNames.filter((col) => col !== 'note');
      columnNames.push('note');
    }
    if (columnNames.includes('date')) { // relocate 'date' to  position - last
      columnNames = columnNames.filter((col) => col !== 'date');
      columnNames.push('date');
    }
    // Filter out rank columns
    columnNames = columnNames.filter((col) => (col !== 'global_rank') && (col !== 'rank'));

    // TODO: check - france.Geography.coastline
    columnNames = columnNames.filter((col) => (
      col !== 'metropolitan_france' && col !== 'key'
    ));

    const TempColumns = columnNames.map((title) => ({ // generate columns for table
      title: convertTitle(title),
      dataIndex: title,
      // width: title === 'country' ? 300 : 400, // only works if x isn't auto
      key: title,
      fixed: ((breakPoint[BREAKPOINTS.PHONE] || breakPoint[BREAKPOINTS.TABLET]) && title === 'country'),
      render: (cellValue) => {
        if (title === 'audio_url') {
          // return 'Temporarily unavailable';
          return (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <audio className="national_anthem" controls>
              <source src={ countryAudioUrlsHash[cellValue] } type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          );
        }
        if (title === 'country') {
          return (
            <>
              <img
                src={ `/assets/flags/${cellValue}.svg` }
                style={ { width: '30px', height: 'auto', marginRight: '10px' } }
                alt={ `flag of ${cellValue}` }
              />&nbsp;
              <Styles.TableTDCountry onClick={ () => handleCountryClick(cellValue) }>
                { cellValue }
              </Styles.TableTDCountry>
            </>
          );
        }
        if (isNumber(cellValue)) {
          return addCommasToNumber(cellValue);
        }
        if (title === 'units' && isString(cellValue)) {
          return myStartCase(cellValue);
        }

        return cellValue;
      },
      sorter: (a, b) => {
        if (typeof a[title] === 'number') {
          const bValue = (typeof b[title] === 'number') ? b[title] : -Infinity;

          return a[title] - bValue;
        }

        if (typeof a[title] === 'string' && a[title] !== CONSTANTS.NOT_AVAILABLE) {
          const nameA = a[title].toUpperCase(); // ignore upper and lowercase
          const nameB = (b[title] || '').toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        }

        if (a[title] === undefined) {
          return -1;
        }

        return -1;
      },
    }));

    if (selectedCountries.length) {
      const newSelectedCountriesData = selectedCountries
        .map((selectedCountryName) => tableData
          .find((tableCountry) => tableCountry.country === selectedCountryName),
        );

      setSelectedCountriesData(newSelectedCountriesData);
    }

    setColumns(TempColumns);
  }, [selectedCountries, previousTableData, tableData, handleCountryClick, breakPoint]);

  return (
    <Styles.TableWrapper>
      <StyledTable
        bordered
        columns={ columns }
        dataSource={ (selectedCountries.length > 0) ? selectedCountriesData : tableData }
        size="small"
        scroll={ { x: 'auto' } }
        pagination={ {
          // total: tableData.length,
          pageSize: tableData.length,
          hideOnSinglePage: true,
        } }
      />
    </Styles.TableWrapper>
  );
}

export default ComparisonTable;
