import styled from "styled-components/macro";
import { sharedStyles, COLORS } from "../../routes/App/App.styles";

import { ValueStyle, KeyStyle } from "../common/KeyValue/KeyValueBody.styles";

const Title = styled.div`
  ${sharedStyles.fontStyleBold}
  font-size: 18px;
  text-decoration: underline;
  line-height: 37px;
  color: ${COLORS.bg};
  margin-top: 10px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  ${sharedStyles.fontStyleMedium}
  font-size: 18px;
  line-height: 37px;
  color: ${COLORS.darkGrey};
  font-weight: 500;

  span {
    ${sharedStyles.fontStyleBold}
    color: ${COLORS.bg};
    font-weight: 700;
  }
`;

const VideoResponsive = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 90%;
  display: grid;
  place-items: center;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export default {
  Title,
  Header,
  ValueStyle,
  KeyStyle,
  LinksWrapper,
  VideoResponsive,
};
