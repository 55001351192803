/* eslint-disable max-len, camelcase */
// Modules
import React, { useEffect } from "react";

// Components
import NoDataOnCountry from "./NoDataOnCountry";
import KeyValue from "../common/KeyValue";
import Comment from "../common/Comment";
import VerticalKeyValue from "../common/VerticalKeyValue";

// Internals
import { CATEGORIES } from "../../internals/constants";
import { showMissingValues } from "../../internals/utils";

// Styles
import Styles from "./Tab.styles";

function Government({ data /* , countryName */ }) {
  useEffect(() => {
    // load a new anthem when the country changes
    const tabAudioElement = document.getElementById("national_anthem");
    if (tabAudioElement) {
      // if this country has an anthem
      document.getElementById("national_anthem").load();
      /* when playing it's anthem - we pause all other audio
        tabAudioElement.onplay = function () { 
        const anthemElements = document.querySelectorAll(".national_anthem")
        const anthemElementsArray = Array.prototype.slice.call(anthemElements);
        anthemElementsArray
          .filter((aud) => aud !== tabAudioElement)
          .forEach(function (audioElement) {
            if (!audioElement.paused) {
              audioElement.pause();
              audioElement.load();
            }
          })
        }
      */
    }

    return () => {
      if (tabAudioElement) {
        tabAudioElement.pause();
      }
    };
  }, [data]);

  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data);

  return (
    <div>
      <Styles.Title>Names:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.GOVERNMENT}.country_name`}
        name="conventional_short_form"
        value={data.country_name?.conventional_short_form}
      />
      <KeyValue
        tab={`${CATEGORIES.GOVERNMENT}.country_name`}
        name="conventional_long_form"
        value={data.country_name?.conventional_long_form}
      />
      <KeyValue
        tab={`${CATEGORIES.GOVERNMENT}.country_name`}
        name="local_short_form"
        value={data.country_name?.local_short_form}
      />
      <KeyValue
        tab={`${CATEGORIES.GOVERNMENT}.country_name`}
        name="local_long_form"
        value={data.country_name?.local_long_form}
      />
      <KeyValue
        tab={`${CATEGORIES.GOVERNMENT}.country_name`}
        name="abbreviation"
        value={data.country_name?.abbreviation}
      />
      <KeyValue
        tab={`${CATEGORIES.GOVERNMENT}.country_name`}
        name="former"
        value={data.country_name?.former}
      />
      <VerticalKeyValue
        tab={`${CATEGORIES.GOVERNMENT}.country_name`}
        name="etymology"
        value={data.country_name?.etymology}
      />

      <br />
      <br />
      <VerticalKeyValue
        tab={CATEGORIES.GOVERNMENT}
        name="flag_description"
        value={data.flag_description?.description}
      />
      {data.flag_description?.note && (
        <Comment prefix="*&nbsp;" text={data.flag_description?.note} />
      )}
      <br />
      <br />

      <KeyValue
        tab={CATEGORIES.GOVERNMENT}
        name="government_type"
        value={data.government_type}
      />
      <KeyValue
        tab={CATEGORIES.GOVERNMENT}
        name="independence"
        value={data.independence?.date}
      />
      <KeyValue
        tab={CATEGORIES.GOVERNMENT}
        name="legal_system"
        value={data.legal_system}
      />
      <br />

      {data.national_holidays && (
        <>
          <KeyValue tab={CATEGORIES.GOVERNMENT} name="national_holidays" />
          <ol>
            {data.national_holidays.map((holiday, index) => (
              <li key={index}>
                <Styles.KeyStyle>{holiday.name}</Styles.KeyStyle>:{" "}
                <Styles.ValueStyle>
                  {holiday.day} {holiday.original_year}{" "}
                  {holiday.note && ` - ${holiday.note}`}
                </Styles.ValueStyle>
              </li>
            ))}
          </ol>
        </>
      )}
      <br />

      <VerticalKeyValue
        tab={CATEGORIES.GOVERNMENT}
        name="national_anthem"
        value={data.national_anthem?.name}
      />
      {data.national_anthem?.note && (
        <Comment prefix="*&nbsp;" text={data.national_anthem.note} />
      )}

      {/* <br /> // TODO: add a link to the anthem
      { (data.national_anthem?.audio_url) && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio id="national_anthem" controls >
          <source src={ data.national_anthem?.audio_url } type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      ) } */}
    </div>
  );
}

export default Government;
