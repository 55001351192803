import React from 'react';
import { Helmet } from 'react-helmet';

import { CONSTANTS, CATEGORIES } from '../../internals/constants';

function MainHeader({
  countryName,
  countryCode,
  tab,
}) {
  const title = countryName
    ? CONSTANTS.MAIN_TITLE_WITH_COUNTRY(countryName, tab)
    : CONSTANTS.APP_NAME;

  let description;
  if (tab) {
    description = `${CONSTANTS.MAIN_DESCRIPTION[CATEGORIES[tab.toUpperCase().split(' ').join('_')]](countryName)}`
  } else {
    description = CONSTANTS.APP_DESCRIPTION;
  }

  return (
    <Helmet>
      <title>{ title }</title>
      <link rel="canonical" href={ `https://www.countryopedia.com/?country=${countryCode}&tab=${tab}` } />
      <meta
        name="description"
        content={ description }
      />
      <meta
        name="keywords"
        content={ `${countryName}, ${tab}, country, countries, geography, people, economy, compare countries, countryopedia, map` }
      />
    </Helmet>
  );
}

export default MainHeader;
