import { css, createGlobalStyle  } from 'styled-components';
import styled from 'styled-components/macro';
import { Tabs } from 'antd';

export const { TabPane } = Tabs;

const fontStyleBold = css`
  font-family: 'Montserrat-Bold', arial, sans-serif;
`;
const fontStyleMedium = css`
  font-family: 'Montserrat-Medium', arial, sans-serif;
`;
const fontStyleRegular = css`
  font-family: 'Montserrat-Regular', arial, sans-serif;
`;
const fontStyleSemiBold = css`
  font-family: 'Montserrat-SemiBold', arial, sans-serif;
`;

export const COLORS = {
  // body background is in App.css
  black: '#ddddd',
  white: '#fff',
  background: '#f6f6f6',
  bg: '#175d78',
  lightBG: '#549ab569',
  polyHover: '#00ffb1',
  heatMapMin: '#c8e8f4',
  heatMapMax: '#034660',
  key: '#034660',
  value: '#279cd6',
  value2: '#ff7875',

  border: '#dddddd',
  tableText: '#222222',
  darkGrey: '#383838',
  lightGrey: '#595959',

  pieChart: [
    '#7c7287',
    '#ed89cb',
    '#f3c677',
    '#455bea',
    '#89c9e3',
  ],
  polygons: [
    '#faa919',
    '#e88bba',
    '#fad119',
    '#89c9e3',
    '#482ec7',
    '#8d81c7',
    '#2b84a8',
    '#9bcc59',
    '#d0be75',
    '#d4763b',
    '#ac4725',
    '#a098c7',
    '#2ec7be',
    '#e8e78b',
    '#f087bc',
    '#9fde95',
    '#758fd0',
    '#e8a78b',
    '#e99d15',
    '#e75a1e',
  ],
};

export const dimensions = {
  navbarHeight: 53,
  footerHeight: 65,
};

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0px;
  }
  body {
    background-color: ${COLORS.background};
    font-family: 'Montserrat-Regular', arial, sans-serif !important;
    height: auto;
    margin: 0;
  }
  #root {
    margin: 0px;
  }
  &::selection {
    background: ${COLORS.value}
  }
`;

export const TabsStyled = styled(Tabs)`
  && .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
  }
`;

const Main = styled.main`
  height: 100vh;
`;

export const sharedStyles = {
  fontStyleBold,
  fontStyleMedium,
  fontStyleRegular,
  fontStyleSemiBold,
};

export default {
  GlobalStyle,
  Main,
};
