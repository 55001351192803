import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

const store = createStore(
  rootReducer,
  (process.env.NODE_ENV === 'development')
    ? composeWithDevTools(applyMiddleware(thunkMiddleware))
    : applyMiddleware(thunkMiddleware),
);

export default store;
