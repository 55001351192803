import styled, { css } from 'styled-components/macro';
import {
  BREAKPOINTS,
  setMediaQuery,
} from '../../context/mediaQueriesHelpers';
import { COLORS, dimensions } from '../App/App.styles';

const Wrapper = styled.div`
  
  ${setMediaQuery(BREAKPOINTS.PHONE, BREAKPOINTS.TABLET)(css`
    display: flex;
    flex-direction: column;
    grid-template-columns: unset;
    `)}

  ${setMediaQuery(BREAKPOINTS.SCREEN, BREAKPOINTS.MAX)(css`
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    `)}

    ${({ isTable }) => { /* eslint-disable indent */
      const footerAndNavbar = dimensions.navbarHeight + dimensions.footerHeight;
      const gridTemplateRows = isTable
        ? css`grid-template-rows: minmax(100px, 65vh) auto;`
        : css`grid-template-rows: calc(100vh - ${footerAndNavbar}px) auto;`;

      return gridTemplateRows;
    /* eslint-enable indent */
  }}
`;

const MapContainer = styled.div` // map & Select
  border: 1px solid ${COLORS.border};

  ${setMediaQuery(BREAKPOINTS.TABLET)(css`
    display: grid;
    grid-template-rows: repeat(10, 1fr);
    grid-template-columns: 1fr;
    height: 450px;
  `)}
  ${setMediaQuery(BREAKPOINTS.SCREEN, BREAKPOINTS.MAX)(css`
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: 1fr 1fr 1fr;
  `)}

  ${setMediaQuery(BREAKPOINTS.SCREEN)(css`
    grid-row: 1;
    grid-column: 5/10;
  `)}
  ${setMediaQuery(BREAKPOINTS.BIG_SCREEN)(css`
    grid-row: 1;
    grid-column: 5/10;
  `)}
  ${setMediaQuery(BREAKPOINTS.MAX)(css`
    grid-row: 1;
    grid-column: 4/10;
  `)}

  div {
    &:nth-child(1){ // CountriesAndTerritories
      ${setMediaQuery(BREAKPOINTS.TABLET)(css`
        grid-row: 1/2;
        grid-column: 1/2;
      `)}
      ${setMediaQuery(BREAKPOINTS.SCREEN, BREAKPOINTS.MAX)(css`
        z-index: 2;
        grid-row: 1/3;
        grid-column: 1/2;
      `)}
    }
    &:nth-child(2){ // Map
      ${setMediaQuery(BREAKPOINTS.PHONE)``/* not rendered */}
      ${setMediaQuery(BREAKPOINTS.TABLET)(css`
        z-index: 1;
        grid-row: 1/11;
        grid-column: 1/2;
      `)}
      ${setMediaQuery(BREAKPOINTS.SCREEN, BREAKPOINTS.MAX)(css`
        z-index: 1;
        grid-row: 1/7;
        grid-column: 1/4;
      `)}
    }
  }
`;

const TabsContainer = styled.div` // Tabs
  ${setMediaQuery(BREAKPOINTS.PHONE, BREAKPOINTS.TABLET)(css`
    height: 1600px; ${/* TODO: fit content */''}
  `)}
  ${setMediaQuery(BREAKPOINTS.SCREEN)(css`
    grid-row: 1;
    grid-column: 1/5;
  `)}
  ${setMediaQuery(BREAKPOINTS.BIG_SCREEN)(css`
    grid-row: 1;
    grid-column: 1/5;
  `)}
  ${setMediaQuery(BREAKPOINTS.MAX)(css`
    grid-row: 1;
    grid-column: 1/4;
  `)}
`;

const ComparisonTableContainer = styled.div` // Table
  ${setMediaQuery(BREAKPOINTS.PHONE)``}
  ${setMediaQuery(BREAKPOINTS.TABLET)``}
  ${setMediaQuery(BREAKPOINTS.SCREEN, BREAKPOINTS.MAX)(css`
    grid-column: 2/9;
  `)}
`;

export default {
  Wrapper,
  MapContainer,
  TabsContainer,
  ComparisonTableContainer,
};
