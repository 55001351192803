/* eslint-disable max-len, camelcase */
// Modules
import React from "react";

// Internals
import { CATEGORIES } from "../../internals/constants";
import { myStartCase } from "../../internals/utils";

// Components
import NoDataOnCountry from "./NoDataOnCountry";
import KeyValue from "../common/KeyValue";
import PieChart from "../common/PieChart";
import Notes from "../common/Notes";
import { showMissingValues } from '../../internals/utils';

// Styles
import Styles from "./Tab.styles";

function TransnationalIssues({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data)
  const renderRefugees = () => {
    let refugees;
    let RefugeesData;
    if (data.refugees_and_iternally_displaced_persons?.refugees?.by_country) {
      const isAllRefugeesWithNumber =
        data.refugees_and_iternally_displaced_persons.refugees.by_country.every(
          (type) => typeof type.people === "number"
        );

      if (isAllRefugeesWithNumber) {
        refugees =
          data.refugees_and_iternally_displaced_persons.refugees.by_country.reduce(
            (acc, type) => {
              acc.series.push(type.people);
              acc.labels.push(type.country_of_origin);

              return acc;
            },
            {
              series: [],
              labels: [],
            }
          );

        RefugeesData = () => (
          <PieChart
            series={refugees.series}
            labels={refugees.labels}
            suffix="people"
          />
        );
      } else {
        RefugeesData = () => (
          <ul>
            {data.refugees_and_iternally_displaced_persons.refugees.by_country.map(
              (type, index) => (
                <KeyValue
                  key={index}
                  listItem
                  name={type.name}
                  value={type.percent}
                  units="people"
                />
              )
            )}
          </ul>
        );
      }
    } else {
      RefugeesData = () => "No Refugees";
    }

    return (
      <>
        <KeyValue name="Refugees" />
        {/* { data.refugees_and_iternally_displaced_persons?.refugees?.by_country */}
        <RefugeesData />
        {/* : } */}
        <Notes
          note={data.refugees_and_iternally_displaced_persons?.refugees?.note}
        />
      </>
    );
  };

  return (
    <div>
      <Styles.Title>Refugees And Internally Displaced Persons:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.TRANSNATIONAL_ISSUES}.refugees_and_iternally_displaced_persons`}
        name="internally_displaced_persons"
        date={
          data.refugees_and_iternally_displaced_persons
            ?.internally_displaced_persons?.date
        }
        value={
          data.refugees_and_iternally_displaced_persons
            ?.internally_displaced_persons?.people
        }
        units="people"
      />
      <Notes
        note={
          data.refugees_and_iternally_displaced_persons
            ?.internally_displaced_persons?.note
        }
      />
      <KeyValue
        tab={`${CATEGORIES.TRANSNATIONAL_ISSUES}.refugees_and_iternally_displaced_persons`}
        name="stateless_persons"
        date={
          data.refugees_and_iternally_displaced_persons?.stateless_persons?.date
        }
        value={
          data.refugees_and_iternally_displaced_persons?.stateless_persons
            ?.people
        }
        units="people"
      />
      {renderRefugees()}
      <Notes note={data.refugees_and_iternally_displaced_persons?.note} />

      {data.disputes && (
        <>
          <Styles.Title>International Disputes:</Styles.Title>
          <ul>
            {data.disputes.map((dispute, index) => (
              <li key={index}>
                <Styles.ValueStyle>{myStartCase(dispute)}</Styles.ValueStyle>
              </li>
            ))}
          </ul>
        </>
      )}
      <Styles.Title>Illicit Drugs:</Styles.Title>
      <Notes note={data?.illicit_drugs?.note} />

      {data.trafficking_in_persons && (
        <>
          <Styles.Title>Human Trafficking:</Styles.Title>
          <Styles.KeyStyle>Current Situation: </Styles.KeyStyle>
          <Notes note={data.trafficking_in_persons?.current_situation} />
          <Styles.KeyStyle>Tier Rating: </Styles.KeyStyle>
          <Notes note={data.trafficking_in_persons?.tier_rating} />
        </>
      )}
    </div>
  );
}

export default TransnationalIssues;
