// Modules
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// Internal
// import { CustomSelect, Option } from '../common/CustomSelect';
import { MyContext } from '../../context/myContext';
import ComparisonTable from '../common/ComparisonTable';
import { getCountryCodeByName } from '../../internals/utils';

import Styles, {
  StyledCloseButton,
  StyledSelect,
  Option,
} from './KeyComparisonContainer.styles';

function KeyComparisonContainer({
  tableTitle,
  keyPath,
  tableData,
  isMain,
  closeTable,
}) {
  const history = useHistory();
  const { _setCountry } = useContext(MyContext);
  const [selectedCountries, setSelectedCountries] = useState([]);

  // useEffect(() => { // Add listeners to anthems - when one plays, the rest are muted
  //   setTimeout(() => { // without the timeout there are no anthems on the dom
  //     const anthemElements = document.querySelectorAll('.national_anthem');
  //     const anthemElementsArray = Array.prototype.slice.call(anthemElements);
  //     anthemElementsArray.forEach((currAud) => {
  //       currAud.onplay = function () {
  //         anthemElementsArray
  //           .filter((aud) => aud !== currAud)
  //           .forEach((audioElement) => {
  //             if (!audioElement.paused) {
  //               audioElement.pause();
  //               audioElement.load();
  //             }
  //           });
  //       };
  //     });
  //   }, 300);
  // }, [tableData]);

  const handleOnSelect = (countryName) => {
    const newCountry = tableData.find((country) => {
      return country.country === countryName;
    });
    setSelectedCountries((prevCountries) => [...prevCountries, newCountry.country]);
  };

  const handleOnDeselect = (countryName) => {
    setSelectedCountries((prevCountries) => {
      return prevCountries.filter((currCountryName) => currCountryName !== countryName);
    });
  };

  const handleOnClear = () => setSelectedCountries([]);

  const handleCountryClick = (countryName) => {
    const countryCode = getCountryCodeByName(countryName);

    if (isMain) {
      _setCountry(countryCode || '');
      window.scrollTo(0, 0);
    } else {
      const tab = keyPath.split('.')[0];
      history.push(`/?country=${countryCode}&tab=${tab}`);
    }
  };

  return (
    <Styles.Wrapper>
      <Styles.TableHeader>
        List of countries by <Styles.Title>{ tableTitle } </Styles.Title>
      </Styles.TableHeader>
      <Styles.SelectAndCloseRow>
        {
          isMain && (
            <StyledCloseButton
              onClick={ closeTable }
            >
              <span>Close Table</span>
            </StyledCloseButton>
          )
        }
        <label htmlFor="rc_select_1" >
          <StyledSelect
            virtual={false}
            mode="multiple"
            allowClear
            // autoClearSearchValue
            placeholder="Select Counties"
            // showSearch
            onClear={ handleOnClear }
            onDeselect={ handleOnDeselect }
            onSelect={ handleOnSelect }
          >
            { tableData.map((country) => (
              <Option
                key={ country.country }
                value={ country.country }
                label={ country.country }
              >
                <img
                  src={ `/assets/flags/${country.country}.svg` } // TODO: Change to png
                  alt={ country.country }
                  style={ { width: '24px', height: 'auto' } }
                />
                {`   ${country.country}` }
              </Option>
            )) }
          </StyledSelect>

        </label>
      </Styles.SelectAndCloseRow>
      <ComparisonTable
        selectedCountries={ selectedCountries }
        tableData={ tableData }
        handleCountryClick={ handleCountryClick }
      />
    </Styles.Wrapper>
  );
}

export default KeyComparisonContainer;
