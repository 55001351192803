import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { css } from 'styled-components';
import Logo from '../../../assets/logo/logo.svg';
import { COLORS } from '../../../routes/App/App.styles';

const types = {
  tab: css`
    display: block;
    margin: 0 auto;
  `,

  big: css`
  position: fixed !important;
  top: 50%;
  left: 54%;
  margin-top: 35px;
  margin-left: -65px;
  `,
};

function Spinner({ type }) {
  return (
    <div>
      <img
        style={ {
          position: 'fixed',
          top: '50%',
          left: '54%',
          marginTop: '-125px',
          marginLeft: '-125px',
        } }
        hight="150"
        width="150"
        src={ Logo }
        alt="logo"
      />
      <PropagateLoader
        css={ types[type] }
        size={ 20 }
        color={ COLORS.value }
        loading
      // { ...props }
      />
    </div>
  );
}

export default Spinner;
