/* eslint-disable max-len, camelcase */
import React from "react";
import { map } from "lodash-es";
import NoDataOnCountry from "./NoDataOnCountry";
import PieChart from "../common/PieChart";
import KeyValue from "../common/KeyValue";
import Styles from "./Tab.styles";
import { showMissingValues } from "../../internals/utils";

import { CATEGORIES } from "../../internals/constants";
import { myStartCase } from "../../internals/utils";

function Economy({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data);

  return (
    <div>
      <KeyValue
        tab={CATEGORIES.ECONOMY}
        name="population_below_poverty_line"
        date={data.population_below_poverty_line?.date}
        value={data.population_below_poverty_line?.value}
        units={data.population_below_poverty_line?.units}
      />
      <KeyValue
        tab={CATEGORIES.ECONOMY}
        name="unemployment_rate"
        date={data.unemployment_rate?.annual_values?.[0]?.date}
        value={data.unemployment_rate?.annual_values?.[0]?.value}
        units={data.unemployment_rate?.annual_values?.[0]?.unit}
      />
      <br />

      <Styles.Title>Budget:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.ECONOMY}.budget`}
        name="expenditures"
        value={data.budget?.expenditures?.value}
        units={data.budget?.expenditures?.units}
      />
      <KeyValue
        tab={`${CATEGORIES.ECONOMY}.budget`}
        name="revenues"
        value={data.budget?.revenues?.value}
        units={data.budget?.revenues?.units}
      />
      <KeyValue
        tab={CATEGORIES.ECONOMY}
        name="budget_surplus_or_deficit"
        date={data.budget_surplus_or_deficit?.date}
        value={data.budget_surplus_or_deficit?.percent_of_gdp}
        units="%"
        rank={data.budget_surplus_or_deficit?.global_rank}
      />
      <br />

      <Styles.Title>Balance:</Styles.Title>
      <KeyValue
        tab={CATEGORIES.ECONOMY}
        name="current_account_balance"
        value={data.current_account_balance?.annual_values?.[0]?.value}
        units={data.current_account_balance?.annual_values?.[0]?.units}
      />
      <KeyValue
        tab={CATEGORIES.ECONOMY}
        name="external_debt"
        date={data.external_debt?.annual_values?.[0]?.date}
        value={data.external_debt?.annual_values?.[0]?.value}
        units={data.external_debt?.annual_values?.[0]?.units}
      />
      <KeyValue
        tab={CATEGORIES.ECONOMY}
        name="public_debt"
        date={data.public_debt?.annual_values[0]?.date}
        value={data.public_debt?.annual_values[0]?.value}
        units={data.public_debt?.annual_values[0]?.units}
      />
      <br />

      <Styles.Title>GDP:</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.ECONOMY}.gdp`}
        name="purchasing_power_parity"
        date={data.gdp?.purchasing_power_parity?.annual_values?.[0]?.date}
        value={data.gdp?.purchasing_power_parity?.annual_values?.[0]?.value}
        units={data.gdp?.purchasing_power_parity?.annual_values?.[0]?.units}
      />
      <KeyValue
        tab={`${CATEGORIES.ECONOMY}.gdp`}
        name="per_capita_purchasing_power_parity"
        date={
          data.gdp?.per_capita_purchasing_power_parity?.annual_values?.[0]?.date
        }
        value={
          data.gdp?.per_capita_purchasing_power_parity?.annual_values?.[0]
            ?.value
        }
        units={
          data.gdp?.per_capita_purchasing_power_parity?.annual_values?.[0]
            ?.units
        }
      />
      <KeyValue
        tab={`${CATEGORIES.ECONOMY}.gdp`}
        name="real_growth_rate"
        units={data.gdp?.real_growth_rate?.annual_values?.[0].units}
        value={data.gdp?.real_growth_rate?.annual_values?.[0]?.value}
        date={data.gdp?.real_growth_rate?.annual_values?.[0]?.date}
        rank={data.gdp?.real_growth_rate?.global_rank}
      />
      <br />

      <KeyValue
        tab={CATEGORIES.ECONOMY}
        name="imports"
        date={data.imports?.total_value?.annual_values?.[0]?.date}
        value={data.imports?.total_value?.annual_values?.[0]?.value}
        units={data.imports?.total_value?.annual_values?.[0]?.units}
        rank={data.imports?.total_value?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.ECONOMY}
        name="exports"
        date={data.exports?.total_value?.annual_values?.[0]?.date}
        value={data.exports?.total_value?.annual_values?.[0]?.value}
        units={data.exports?.total_value?.annual_values?.[0]?.units}
        rank={data.exports?.total_value?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.ECONOMY}
        name="industrial_production_growth_rate"
        date={data.industrial_production_growth_rate?.date}
        value={
          data.industrial_production_growth_rate?.annual_percentage_increase
        }
        units="annual_percentage_increase"
        rank={data.industrial_production_growth_rate?.global_rank}
      />
      <KeyValue
        name="inflation_rate"
        date={data.inflation_rate?.annual_values?.[0]?.date}
        value={data.inflation_rate?.annual_values?.[0]?.value}
        units={data.inflation_rate?.annual_values?.[0]?.units}
      />
      <br />

      {data.labor_force?.by_occupation?.occupation && (
        <>
          <Styles.Title>Labor Force:</Styles.Title>
          <PieChart
            series={map(
              data.labor_force.by_occupation.occupation,
              (obj) => obj.value
            )}
            labels={Object.keys(data.labor_force.by_occupation.occupation).map(
              (i) => myStartCase(i)
            )}
            suffix="%"
          />
          <br />
        </>
      )}

      {data.industries?.industries && (
        <>
          <Styles.Title>Industries:</Styles.Title>
          <ol>
            {data.industries.industries.sort().map((industry, index) => (
              <li key={index}>
                <Styles.ValueStyle>{myStartCase(industry)}</Styles.ValueStyle>
              </li>
            ))}
          </ol>
        </>
      )}
    </div>
  );
}

export default Economy;
