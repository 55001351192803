// https://medium.com/better-programming/how-to-use-media-queries-programmatically-in-react-4d6562c3bc97
// https://gs.statcounter.com/screen-resolution-stats#monthly-202008-202011-bar

/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';
import { findKey } from 'lodash-es';

const defaultValue = {};
const BreakpointContext = createContext(defaultValue);

// constants
export const BREAKPOINTS = {
  PHONE: 'PHONE', // 0-450  35%
  TABLET: 'TABLET', // 451-800 5%
  SCREEN: 'SCREEN', // 801-1450 20%
  BIG_SCREEN: 'BIG_SCREEN', // 1451-1600 5%
  MAX: 'MAX', // 1601-∞  12%
};

const breakpoints = {
  [BREAKPOINTS.PHONE]: 450, // 35% of devices smaller
  [BREAKPOINTS.TABLET]: 900, // 800 // 5% of devices smaller
  [BREAKPOINTS.SCREEN]: 1200, // 1450, // 20% of devices smaller
  [BREAKPOINTS.BIG_SCREEN]: 1601, // 5% of devices smaller & 12% bigger
};

const breakpointsValue = Object.values(breakpoints).sort((a, b) => a - b);

export const mediaQueries = {
  // max-width: x - " “If [device width] is less than or equal to x, apply these styles {…}”"
  // min-width: x - " “If [device width] is greater than or equal to x, apply these styles {…}”"
  [BREAKPOINTS.PHONE]: `(max-width: ${breakpoints[BREAKPOINTS.PHONE]}px)`,
  [BREAKPOINTS.TABLET]: `(min-width: ${breakpoints[BREAKPOINTS.PHONE] + 1}px) and (max-width: ${breakpoints[BREAKPOINTS.TABLET]}px)`,
  [BREAKPOINTS.SCREEN]: `(min-width: ${breakpoints[BREAKPOINTS.TABLET] + 1}px) and (max-width: ${breakpoints[BREAKPOINTS.SCREEN]}px)`,
  [BREAKPOINTS.BIG_SCREEN]: `(min-width: ${breakpoints[BREAKPOINTS.SCREEN] + 1}px) and (max-width: ${breakpoints[BREAKPOINTS.BIG_SCREEN]}px)`,
  [BREAKPOINTS.MAX]: `(min-width: ${breakpoints[BREAKPOINTS.BIG_SCREEN] + 1}px)`,
};

// Context
const BreakpointProvider = ({ children }) => {
  const [queriesMatch, setQueriesMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists = {};
    const mediaQueriesKeys = Object.keys(mediaQueries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = mediaQueriesKeys.reduce((acc, media) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);

        return acc;
      }, {});
      setQueriesMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};
      mediaQueriesKeys.forEach((media) => {
        if (typeof mediaQueries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(mediaQueries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false; // should not come here
        }
      });
      setQueriesMatch(matches);
      isAttached = true; // ?
      mediaQueriesKeys.forEach((media) => {
        if (typeof mediaQueries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener); // ?
        }
      });
    }

    return () => { // useEffect return
      if (isAttached) {
        mediaQueriesKeys.forEach((media) => {
          if (typeof mediaQueries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, []);

  const currentQuery = findKey(queriesMatch, (isQueryMatch) => isQueryMatch);
  const value = { ...queriesMatch, currentQuery };
  /* value = {
    BIG_SCREEN: false,
    MAX: false,
    PHONE: false,
    SCREEN: true,
    TABLET: false,
    currentQuery: "SCREEN",
  } */

  return (
    <BreakpointContext.Provider value={ value }>
      {children }
    </BreakpointContext.Provider>
  );
};

function useBreakpoint() {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }

  return context;
}

export { useBreakpoint, BreakpointProvider };

// CSS
export const setMediaQuery = (breakpoint, breakpoint2) => (style) => {
  if (breakpoint2) {
    const breakpointIndex = breakpointsValue.indexOf(breakpoints[breakpoint]);
    if (breakpoint === BREAKPOINTS.PHONE) {
      if (breakpoint2 === BREAKPOINTS.MAX) {
        throw new Error('setMediaQuery got all ranges');
      }
      
      return `@media (max-width: ${breakpoints[breakpoint2]}px) { ${style} }`;
    }
    if (breakpoint2 === BREAKPOINTS.MAX) {
      return `@media (min-width: ${breakpointsValue[breakpointIndex - 1]}px) { ${style} }`;
    }
    // const breakpointIndex = breakpointsValue.findIndex(breakpoints[breakpoint]);
    
    return `@media (min-width: ${breakpointsValue[breakpointIndex - 1]}px) (max-width: ${breakpoints[breakpoint2]}px) { ${style} }`;
  }

  return `@media ${mediaQueries[breakpoint]} { ${style}; }`;
};
