/* eslint-disable camelcase */
// Modules
import {
  startCase,
  isNumber,
  forEach,
  find,
  pickBy,
} from 'lodash-es';
import queryString from 'query-string';

// Internals
import {
  CATEGORIES,
  TITLES,
  initialCountriesListToMap,
} from './constants.js';

// -=-=-=-=-=-=-=-=-=-=-= shared with server -=-=-=-=-=-=-=-=-=-=-
export const getCountryCodeByName = (name) => { // TODO: also in server
  const selectedCountry = find(
    initialCountriesListToMap,
    (country) => country.name === name,
  );

  return selectedCountry.id;
};
// -=-=-=-=-=-=-=-=-=-=-= shared with server -=-=-=-=-=-=-=-=-=-=-

// Modifiers
export const addCommasToNumber = (number) => {
  if (isNumber(number)) {
    const numberWithCommas = Math.round(number, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');// TODO-1: 1. Math.round() doesn't have 2nd argument, and replacing it with number.toFixed(2) gives all number a 2 fixed (even for integers)
    return numberWithCommas;
  }

  return number;
};

export const convertTitle = (title) => {
  if (title.includes('_to_')) {
    return title.replace('_to_', '-');
  }

  return startCase(title);
};

export const myStartCase = (str) => {
  if (typeof str === 'string' && str.length > 4) {
    return startCase(str);
  }

  return str;
};

// Side effects
/** stops national anthem audio when changing tabs - not pure.
  *
  * @param {string} tabName 
  * @returns {undefine} side-effect;
  */
export const pauseNationalAnthem = (tabName) => { // pause the anthem on tab change
  if (tabName !== CATEGORIES.GOVERNMENT) {
    const nationalAnthemElement = document.getElementById('national_anthem');
    if (nationalAnthemElement) {
      nationalAnthemElement.pause();
      nationalAnthemElement.load();
    }
  }
};

export const updateQueryString = (key, value) => {
  if (window.history.pushState) {
    const parsedQS = queryString.parse(window.location.search);
    parsedQS[key] = value;
    let stringified = queryString.stringify(parsedQS);

    // When entering irrelevant QS in list route - we omit them
    if (window.location.pathname === '/list') {
      stringified = queryString.stringify({ key: parsedQS.key });
    }
    const newQS = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${stringified}`;
    window.history.pushState({ path: newQS }, '', newQS);
  }
};

// Generators
export const generateKeysHashListByTitles = (list) => {
  const keyObj = {
    // energy: {
    // 'refined Petroleum Products Production (bbl/day)': 'Energy.refined_petroleum_products.production',
  };

  forEach(list, (value, key) => {
    const splitted = key.split('.');
    if (!keyObj[splitted[0]]) {
      keyObj[splitted[0]] = {};
      keyObj[splitted[0]][value] = key;
    } else {
      keyObj[splitted[0]][value] = key;
    }
  });

  return keyObj;
};

// Validators
export const validateAMChartsCode = (AMChartsCode) => {
  const country = initialCountriesListToMap.find((c) => c.id === AMChartsCode);
  if (country) {
    return AMChartsCode;
  }

  return '';
};

export const validateTab = (tab) => {
  const tabsArray = Object.values(CATEGORIES);

  if (tabsArray.includes(tab)) {
    return tab;
  }

  return CATEGORIES.GEOGRAPHY;
};

export const validateKey = (key) => {
  if (TITLES[key]) {
    return key;
  }

  return '';
};


export const showMissingValues = (obj, added = ['']) => {
  let missing;
  if (process.env.NODE_ENV === "development") {
    missing = pickBy(obj, (_, key) => !added.includes(key));
    console.log('missing: ', Object.keys(missing));
    console.log(missing);
  } else {
    missing = pickBy(obj, (_, key) => !added.includes(key));
    console.log('missing: ', Object.keys(missing));
  }
}