import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '../../assets/logo/favicon.ico';

import { CONSTANTS } from '../../internals/constants';

function AppHeader() {
  return (
    <Helmet>
      <title>{ CONSTANTS.APP_NAME }</title>
      <meta
        name="description"
        content={ CONSTANTS.APP_DESCRIPTION }
      />
      {/* <link rel="manifest" href="%PUBLIC_URL%/manifest.json" /> */ }
      <link rel="icon" href={ favicon } />
      {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" /> */ }
    </Helmet>
  );
}

export default AppHeader;
