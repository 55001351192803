// Modules
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';

// Internals
import store from '../../redux/store';
import { BreakpointProvider } from '../../context/mediaQueriesHelpers';

// Components
import AppHeader from './App.header';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/common/Spinner';
import List from '../List';
import Main from '../Main';
import About from '../About';
import Contact from '../Contact';

// Styles
import Styles from './App.styles';
import './App.css';
import 'antd/dist/antd.css';

function App() {
  return (
    <Provider store={ store }>
      <BreakpointProvider>
        <React.Suspense fallback={ <Spinner type="big" /> }>
          <Router>
            <QueryParamProvider ReactRouterRoute={ Route }>
              <AppHeader />
              <Styles.GlobalStyle />
              <Styles.Main>
                <Navbar />
                <Switch>
                  <Route exact path="/list" ><List /></Route>
                  <Route exact path="/about" ><About /></Route>
                  <Route exact path="/contact" ><Contact /></Route>
                  <Route path="/"><Main /></Route>
                </Switch>
                <Footer />
              </Styles.Main>
            </QueryParamProvider>
          </Router>
        </React.Suspense>
      </BreakpointProvider>
    </Provider>

  );
}

export default App;
