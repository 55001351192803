import styled from 'styled-components/macro';
import { Table as AntTable } from 'antd';
import { sharedStyles, COLORS } from '../../../routes/App/App.styles';

const TableWrapper = styled.div`
  border-radius: 5px;
`;

const stickyStyle = `
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 45px !important;
  z-index: 3;
`;

export const StyledTable = styled(AntTable)`

  & .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last.ant-table-column-has-sorters {
    ${stickyStyle}
    z-index: 4;
  }

  & .ant-table-thead > tr > th {
    ${stickyStyle}
    ${sharedStyles.fontStyleSemiBold}
    font-size: 14px;
    color: ${COLORS.white};
    font-weight: 600;
    background-color: ${COLORS.bg};
  }

  & .ant-table-column-sorters-with-tooltip {
    padding: 0 30px;
  }

  & .ant-table-column-sorters-with-tooltip.ant-tooltip-open {
    color: ${COLORS.bg};
  }
  
  & .ant-table-content {
    overflow: unset !important;
  }
  & .ant-table-tbody .ant-table-row .ant-table-cell {
    ${sharedStyles.fontStyleMedium} 
    padding: 0 30px;
    font-size: 14px;
    line-height: 38px;
    color: ${COLORS.tableText};
    font-weight: 500;
  }
`;

const TableTDCountry = styled.span`
  text-decoration: underline;
  &: hover {
    cursor: pointer;
  }
`;

export default {
  TableWrapper,
  TableTDCountry,
};
