// Modules
import React, { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// Internals
import { MyContext } from '../../../context/myContext';
import { myStartCase } from '../../../internals/utils';
import { useBreakpoint, BREAKPOINTS } from '../../../context/mediaQueriesHelpers';

// Components
import Comment from '../Comment';

// Styles
import TabStyles from '../../categories/Tab.styles';
import KeyValueBodyStyles, { KeyStyle } from '../KeyValue/KeyValueBody.styles';

export default function VerticalKeyValue({ heading, name, value, tab }) {
  const breakPoint = useBreakpoint();
  const history = useHistory();
  const { _setKey } = useContext(MyContext);
  const __setKey = useCallback(() => {
    const keyPath = `${tab}.${name}`;
    if (breakPoint[BREAKPOINTS.PHONE] || breakPoint[BREAKPOINTS.TABLET]) {
      history.push(`/list/?key=${keyPath}`);
    } else {
      _setKey(keyPath);
    }
  }, [tab, name, _setKey, history, breakPoint]);

  const renderKey = () => {
    return tab
      ? <span onClick={ __setKey }>{ myStartCase(name) }</span>
      : myStartCase(name);
  }

  return (
    <>
      <KeyValueBodyStyles.KeyWrapper vertical>
        <div>
          {
            heading
              ? (
                <TabStyles.Title>
                  { renderKey() }:
                </TabStyles.Title>
              )
              : (
                <KeyStyle>
                  { renderKey() }:
                </KeyStyle>
              )
          }
        </div>
      </KeyValueBodyStyles.KeyWrapper>
      <Comment text={ value } />
    </>
  );
}
