/* eslint-disable max-len, camelcase */
import React from "react";

import Styles from "./Tab.styles";
import LinkComponent from "../common/LinkComponent";
import NoDataOnCountry from "./NoDataOnCountry";
// import geographyNowLogo from '../../assets/links/geographyNowLogo.jpg';
import nationsOnline from "../../assets/links/nationsOnline.png";
import theWorldBankLogo from "../../assets/links/worldbank.svg";
import wikipediaLogo from "../../assets/links/wikipedia.png";
import worldOMetersLogo from "../../assets/links/worldOMetersLogo.jpg";

function Links({ data, countryName }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }

  return (
    <>
      <Styles.Header>
        Learn more about <span>{countryName}</span> in these sources:
      </Styles.Header>
      <Styles.LinksWrapper>
        {data.geographyNowLink && (
          <Styles.VideoResponsive>
            <iframe
              width="853"
              height="480"
              src={`https://www.youtube.com/embed/${
                data?.geographyNowLink?.split("v=")[1].split("&")[0]
              }`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Geography Now"
            />
          </Styles.VideoResponsive>
        )}
        {/* <LinkComponent
          link={ data.geographyNowLink }
          imgSrc={ geographyNowLogo }
          alt="Geography Now logo"
          title="Geography Now"
          description="A Youtube Channel that cover profiles on every single country of the world"
        /> */}
        <LinkComponent
          link={data.wikipediaLink}
          imgSrc={wikipediaLogo}
          alt="Wikipedia logo"
          title="Wikipedia"
          description="The collaborative online encyclopedia"
        />
        <LinkComponent
          link={data.worldMeterLink}
          imgSrc={worldOMetersLogo}
          alt="World O Meter logo"
          title="WorldOMeter"
          description="A reference website that provides counters and real-time statistics for diverse topics"
        />
        <LinkComponent
          link={data.worldBankLink}
          imgSrc={theWorldBankLogo}
          alt="World Bank logo"
          title="World Bank"
          description="An international financial institution that provides loans and grants to the governments of low and middle income countries for the purpose of pursuing capital projects"
        />
        <LinkComponent
          link={data.nationsOnlineProjectLink}
          imgSrc={nationsOnline}
          alt="nations Online Project logo"
          title="Nations Online"
          description="An holistic and philanthropic project, dedicated to art, culture, history, and the uniqueness of a nation and its people"
        />
      </Styles.LinksWrapper>
    </>
  );
}

export default Links;
