/* eslint-disable import/no-dynamic-require */
// Modules
import React from "react";
import { isNumber, isString } from "lodash-es";
import styled from "styled-components/macro";
import { Table as AntdTable } from "antd";

// Internals
import sitesIconsList from "../../data/sitesIconsList.json";
import { CATEGORIES } from "../../internals/constants";
import { myStartCase } from "../../internals/utils";

// Styles
import { COLORS } from "../../routes/App/App.styles";

// Components
import NoDataOnCountry from "./NoDataOnCountry";
import KeyValue from "../common/KeyValue";

const StyledTable = styled(AntdTable)`
  padding-right: 22px;
  & .ant-table-thead > tr > th {
  }
  & .ant-table-tbody > tr > td {
    color: ${COLORS.black};
    & a {
      color: ${COLORS.black};
      text-decoration: underline;
    }
  }

  & .ant-table-row.ant-table-row-level-0 {
    :hover {
    }
  }
`;

function MostVisitedSites({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }

  data.forEach((site, index) => {
    site.key = `${site.url}-${index}`;
  });

  const columnNames = [
    "rank",
    "URL",
    "DailyTimeOnSite",
    "DailyPageViewsPerVisitor",
  ];
  const columns = columnNames.map((title, index) => ({
    title: myStartCase(title),
    dataIndex: title,
    // key: title,
    render: (cellValue) => {
      if (title === "URL") {
        const siteImgSrc = sitesIconsList.includes(cellValue)
          ? cellValue
          : "not-available.png";

        return (
          <span style={{ display: "flex", flexDirection: "row" }} key={index}>
            <img
              src={`/assets/sites_imgs/${siteImgSrc}`}
              style={{ width: "30px", height: "auto" }}
              onError={(event) => {
                event.target.src = "/assets/sites_imgs/not-available.png";
                event.onerror = null;
              }}
              alt="logo"
            />
            &nbsp;&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.${cellValue}`}
            >
              {`www.${cellValue.toLowerCase()}`}
            </a>
          </span>
        );
      }

      return cellValue;
    },
    sorter: (a, b) => {
      if (isNumber(a[title])) {
        const bValue = typeof b[title] === "number" ? b[title] : -Infinity;

        return a[title] - bValue;
      }

      if (isString(a[title])) {
        const nameA = a[title].toUpperCase(); // ignore upper and lowercase
        const nameB = (b[title] || "").toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      }

      if (a[title] === undefined) {
        return -1;
      }

      return -1;
    },
  }));

  return (
    <div>
      <KeyValue name={CATEGORIES.MOST_VISITED_SITES} />
      <StyledTable
        columns={columns}
        dataSource={data}
        size="small"
        pagination={{
          total: data.length,
          pageSize: data.length,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
}

export default MostVisitedSites;
