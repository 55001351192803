const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
const UPDATE_TAB = 'UPDATE_TAB';
const UPDATE_KEY = 'UPDATE_KEY';
const INIT_COUNTRY = 'INIT_COUNTRY';
const INIT_KEY = 'INIT_KEY';

export default {
  UPDATE_COUNTRY,
  UPDATE_TAB,
  UPDATE_KEY,
  INIT_COUNTRY,
  INIT_KEY,
};
