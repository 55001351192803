/* eslint-disable max-len */
import React from 'react';

import AboutHeader from './About.header';
import styles, { StyledLink } from './About.styles';

export default function About() {
  return (
    <styles.Container>
      <AboutHeader />

      <styles.Title>Who Are We?</styles.Title>
      <styles.StyledP>
        Countryopedia is a free country based encyclopedia project with the purpose of providing easy, fast,
        intuitive access to various types of information about the countries (and territories) of the world.
      </styles.StyledP>

      <br />
      <styles.Title>Our Vision:</styles.Title>
      <styles.StyledP>
        While the information that we display is not unique and can also be found on other websites, we found most of them
        very uncomfortable to use and navigate, especially if you want to compare information between different countries.
        <br />
        Being the map enthusiasts that we are, we believe that knowing where the country lays on the map is a very important thing to know about a country, that&#39;s why we have integrated a dynamic map alongside the information tabs so you will always know where on the globe the country you are reading on is located.
      </styles.StyledP>

      <br />
      <styles.Title>Resources:</styles.Title>
      <styles.StyledP>
        Most of the information is derived from the CIA factbook.
        <br />
        Other parts are manually gathered by our team from various sources like Wikipedia and the UN records.
      </styles.StyledP>
      <br />
      <br />
      <styles.StyledP>
        Have a suggestion or an idea of something you would like to know about a country and we don&apos;t show it yet?
        <StyledLink to="/contact">&nbsp; Let us know</StyledLink>
      </styles.StyledP>

      {/* <styles.StyledImg alt="about" src={ about } /> */ }
    </styles.Container>
  );
}
