/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import KeyValueBody from './KeyValueBody';

function KeyValue(props) {
  const { name, listItem } = props;

  if (!name) return <span />;
  if (listItem) {
    return (
      <li>
        <KeyValueBody { ...props } />
      </li>
    );
  }

  return (
    <>
      <KeyValueBody { ...props } />
    </>
  );
}

export default KeyValue;
