import styled from 'styled-components/macro';

import { sharedStyles, COLORS } from '../../../routes/App/App.styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const KeyStyle = styled.span`
  ${sharedStyles.fontStyleSemiBold}
  font-size: 15px;
  line-height: 32px;
  color: ${COLORS.key};
`;

export const ValueStyle = styled.span`
  ${sharedStyles.fontStyleMedium}
  font-size: 15px;
  gap: 3px;
  letter-spacing: 0px;
  line-height: 32px;
  color: ${COLORS.lightGrey};
  font-weight: 500;
`;

export const KeyWrapper = styled(KeyStyle)`
  ${KeyStyle};
  display: inline-block;
  width:  ${({vertical}) => vertical ? '100%' : '50%'};
  text-align: left;

  & span {
    &: hover {
    text-decoration: underline;
    cursor: pointer;
    }
  }
  font-weight: ${({ isValueHover }) => isValueHover ? 'bolder' : 'inherit'};
  text-decoration: ${({ isValueHover }) => isValueHover ? 'underline' : 'inherit'};
`;

export const ValueWrapper = styled(ValueStyle)`
  display: flex;
  width: 50%;
  padding-left: 3px;
  text-align: left;
  font-weight: ${({ isKeyHover }) => isKeyHover ? 'bolder' : 'inherit'};
`;

export const MaleImg = styled.img.attrs(() => ({
  src: require('../../../assets/icons/male.svg'),
  title: 'male',
  alt: 'male',
}))`
  height: 23px;
`;

export const FemaleImg = styled.img.attrs(() => ({
  src: require('../../../assets/icons/female.svg'),
  title: 'female',
  alt: 'female',
}))`
  height: 23px;
`;

const ReelWrapper = styled.span`
  display: inline;
  position: relative;
  top: 8px;
`;
export const theme = {
  reel: {
    height: '1em',
    display: 'flex',
    alignItems: 'flex-end',
    overflowY: 'hidden',
    fontWeight: '500',
    color: `${COLORS.value}`,
    lineHeight: '0.95em',
  },
  group: {
    transitionDelay: '0ms',
    transitionTimingFunction: 'ease-in-out',
    transform: 'translate(0, 0)',
    height: '2em',
  },
  number: {
    height: '1em',
  },
};

export const selectedTheme = {
  ...theme,
  reel: {
    ...theme.reel,
    fontWeight: 'bolder',
  },
};

export default {
  Wrapper,
  KeyWrapper,
  ValueWrapper,
  KeyStyle,
  ValueStyle,
  ReelWrapper,
};
