import React, { useRef } from 'react';
import styles, { OptGroup, Option, PrivateSelect } from './CustomTreeSelect.styles';

export { OptGroup, Option };

const CustomSelect = (props) => {
  const containerRef = useRef(null);

  const { children = {}/* , dropdownStyle */ } = props;

  return (
    <>
      <styles.PrivateSelectRefContainer ref={ containerRef } className="selectRefContainer" />
      <PrivateSelect
        getPopupContainer={ () => containerRef.current }
        { ...props } // eslint-disable-line 
      >
        { children }
      </PrivateSelect>
    </>
  );
};

export default CustomSelect;
