// Modules
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryParam, StringParam } from 'use-query-params';

// Internals
import selectors from '../../redux/selectors';
import actions from '../../redux/actions';
import {
  validateKey,
  // generateKeysHashListByTitles,
} from '../../internals/utils';
// import { TITLES } from '../../internals/constants';

// Components
import ScrollTopArrow from '../../components/common/ScrollToTopArrow';
import ListHeader from './List.header';
import KeyComparisonContainer from '../../components/KeyComparisonContainer';
// import CustomSelect, { Option, OptGroup } from '../../components/common/CustomTreeSelect/CustomTreeSelect';

// Styles
import Styles from './List.styles';
// import GeneralStyles from '../../components/common/generalStyles';

// const keysHashList = generateKeysHashListByTitles(TITLES); // TODO: remove to server

function List() {
  const [keyQS, setKeyQS] = useQueryParam('key', StringParam);
  const validatedKeyQS = validateKey(keyQS);
  const tableData = useSelector(selectors.getTableData);
  const key = useSelector(selectors.getKey);

  const dispatch = useDispatch();

  useEffect(() => {
    if (keyQS !== key.keyPath) {
      dispatch(actions.updateKey(validatedKeyQS, setKeyQS));
    }
  }, []); // eslint-disable-line 

  // const handleSelectChange = (keyPath) => {
  //   dispatch(actions.updateKey(keyPath));
  // };

  // const renderCustomTreeSelect = () => (
  //   <CustomSelect
  //     autoClearSearchValue={ false }
  //     placeholder="Choose a parameter to compare"
  //     showSearch
  //     value={ key.keyPath || '' }
  //     onChange={ handleSelectChange }
  //   >
  //     {
  //       Object.keys(keysHashList).map((category) => (
  //         <OptGroup
  //           key={ category }
  //           label={ category }
  //         >
  //           {
  //             Object.keys(keysHashList[category])
  //               .map((keyTitle) => (
  //                 <Option
  //                   key={ keysHashList[category][keyTitle] }
  //                   value={ keysHashList[category][keyTitle] }
  //                 >
  //                   <GeneralStyles.ListItem>{ keyTitle }</GeneralStyles.ListItem>
  //                 </Option>
  //               ))
  //           }
  //         </OptGroup>
  //       ))
  //     }
  //   </CustomSelect>
  // );

  return (
    <Styles.Wrapper>
      <ScrollTopArrow />
      <ListHeader keyTitle={ key.keyTitle } />
      {/* { renderCustomTreeSelect() } */}

      <KeyComparisonContainer
        tableTitle={ key.keyTitle }
        keyPath={ key.keyPath }
        tableData={ tableData }
      />
    </Styles.Wrapper>
  );
}

export default List;
