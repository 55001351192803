// Modules
import React from 'react';
import { useForm } from 'react-hook-form';

// Internals
import ContactHeader from './Contact.header';

// Styles
import Styles from './Contact.styles';
import contact from '../../assets/contact.png';

export default function Contact() {
  const [isSubmittedResolved, setIsSubmittedResolved] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isRejected, setIsRejected] = React.useState(false);

  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = async (data) => {
    setIsSubmitted(true);

    const options = {
      method: 'post',
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch('/api/form/x', options);
      if (response.status !== 200) {
        setIsRejected(true);
      } else {
        setIsSubmittedResolved(true);
        reset();
      }
      setIsSubmitted(false);
    } catch (error) {
      setIsSubmitted(false);
      setIsSubmittedResolved(true);
      console.error('api/contact Error:');
      console.error(error);
    }
  };

  // const { isSubmitted } = formState;

  return (
    <Styles.Wrapper>
      <ContactHeader />
      <Styles.Form onSubmit={ handleSubmit(onSubmit) }>
        <Styles.Header>Contact Us</Styles.Header>

        {/* <Styles.Label>Name</Styles.Label> */ }
        <Styles.Input
          type="text"
          placeholder="Name"
          name="name"
          ref={ register({ required: true, maxLength: 120 }) }
        />
        { errors.name && <Styles.Error>You forgot to fill in your name</Styles.Error> }

        {/* <Styles.Label>Email</Styles.Label> */ }
        <Styles.Input
          type="text"
          placeholder="Email"
          name="email"
          ref={ register({ required: true, pattern: /^\S+@\S+$/i }) }
        />
        { errors.email && <Styles.Error>You forgot to fill in your Email</Styles.Error> }

        <Styles.Input
          type="text"
          placeholder="Subject"
          name="subject"
          ref={ register({ required: true }) }
        />
        { errors.subject && <Styles.Error>You forgot to fill in the subject</Styles.Error> }

        {/* <Styles.Label>Message</Styles.Label> */ }
        <Styles.Textarea
          placeholder="What's on your mind..."
          name="message"
          ref={ register({ required: true }) }
        />
        { errors.message && <Styles.Error>You forgot to write your message</Styles.Error> }

        { isSubmittedResolved && <Styles.Submitted>We got your message</Styles.Submitted> }
        { isRejected && <Styles.Error>We could not deliver your message</Styles.Error> }
        <Styles.Input disabled={ isSubmitted } type="submit" />
      </Styles.Form>
      <Styles.ImgWrapper>
        <Styles.StyledImg alt="contact" src={ contact } />
      </Styles.ImgWrapper>
    </Styles.Wrapper>
  );
}
