import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { sharedStyles, COLORS, dimensions } from '../App/App.styles';

const Container = styled.div`
  min-height: calc(100% - ${dimensions.navbarHeight}px - ${dimensions.footerHeight}px);
  text-align: justify;
  padding: 18px 30px;
  max-width: 1000px;
  margin: auto;
`;

const Header = styled.h1`
  margin-top: 30px
`;

const Title = styled.div`
  ${sharedStyles.fontStyleBold}
  font-size: 28px;
  line-height: 37px;
  color: ${COLORS.bg};
  font-weight: 700;
  margin-top: 15px;
`;

const StyledP = styled.div`
  font-size: 20px;
  ${sharedStyles.fontStyleMedium}
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.bg};
`;

const StyledImg = styled.img`
  width: 61%;
  margin: auto;
`;

export default {
  Header,
  Title,
  StyledP,
  Container,
  StyledImg,
};
