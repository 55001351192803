const getCountry = ({ mainReducer }) => {
  return mainReducer.country;
};

const getKey = ({ mainReducer }) => {
  return mainReducer.key;
};

const getTab = ({ mainReducer }) => {
  return mainReducer.tab;
};

const getCountriesListToMap = ({ mainReducer }) => {
  return mainReducer.countriesListToMap;
};

const getTableData = ({ mainReducer }) => {
  return mainReducer.tableData;
};

export default {
  getCountry,
  getKey,
  getTab,
  getCountriesListToMap,
  getTableData,
};
