/* eslint-disable import/no-dynamic-require */
// Modules
import React from 'react';

// Internals
import { useBreakpoint, BREAKPOINTS } from '../../context/mediaQueriesHelpers';
import { CATEGORIES } from '../../internals/constants';

// Components
import NoCountrySelected from './NoCountrySelected';
import Tooltip from '../common/Tooltip';
import CategoriesRenderer from '../categories/CategoriesRenderer';

// Styles
import Styles, {
  StyledScrollbars,
  StyledTabs,
  StyledTabPane,
  StyledTab,
} from './CategoryTabs.styles';

function CategoryTabs({ selectedTab, onTabChange, selectedCountry }) {
  const breakPoint = useBreakpoint();
  const tabsMode = breakPoint[BREAKPOINTS.PHONE] || breakPoint[BREAKPOINTS.TABLET] ? 'top' : 'left';
  const tooltipPlacement = breakPoint[BREAKPOINTS.PHONE] || breakPoint[BREAKPOINTS.TABLET] ? 'bottom' : 'left';

  return (
    <StyledTabs
      animated={ { inkBar: false, tabPane: breakPoint === 's' } }
      tabPosition={ tabsMode }
      onChange={ onTabChange }
      activeKey={ selectedTab }
    >
      {[...Object.values(CATEGORIES)].map((tabName) => (
        <StyledTabPane
          key={ tabName }
          tab={ (
            <StyledTab>
              <Tooltip placement={ tooltipPlacement } title={ tabName }>
                <Styles.StyledTabImg
                  src={ require(`../../assets/icons/${tabName}.svg`) }
                  alt={ tabName }
                />
              </Tooltip>
            </StyledTab>
          ) }
        >
          <StyledScrollbars>
            { selectedCountry.AMChartsName ? (
              <>
                <Styles.TabHeader>
                  <>
                    <Styles.HeaderIconImg
                      src={ require(`../../assets/icons/${tabName}.svg`) }
                      alt={ tabName }
                    />
                    <Styles.StyledHeader>{ tabName }</Styles.StyledHeader>
                    <Styles.HeaderFlagImg
                      src={ `/assets/flags/${selectedCountry.AMChartsName}.svg` }
                      alt={ selectedCountry.AMChartsName }
                    />
                  </>
                </Styles.TabHeader>
                <CategoriesRenderer
                  key={ tabName }
                  tabKey={ tabName }
                  data={ selectedCountry.data?.[tabName] }
                  countryName={ selectedCountry.AMChartsName }
                />
              </>
            ) : <NoCountrySelected />}
          </StyledScrollbars>
        </StyledTabPane>
      )) }
    </StyledTabs>

  );
}

export default React.memo(CategoryTabs);
