/* eslint-disable max-len, import/no-dynamic-require */
import React from "react";
import KeyValue from "../common/KeyValue";
import { CATEGORIES } from "../../internals/constants";
import NoDataOnCountry from "./NoDataOnCountry";
import { showMissingValues } from "../../internals/utils";

function General({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data);

  return (
    <div>
      <br />
      <KeyValue tab={CATEGORIES.GENERAL} name="unCode" value={data.unCode} />
      <KeyValue
        tab={CATEGORIES.GENERAL}
        name="internetCode"
        value={data.internetCode}
      />
      <KeyValue
        tab={CATEGORIES.GENERAL}
        name="alpha2Code"
        value={data.alpha2Code}
      />
      <KeyValue
        tab={CATEGORIES.GENERAL}
        name="alpha3Code"
        value={data.alpha3Code}
      />
      <KeyValue
        tab={CATEGORIES.GENERAL}
        name="callingCodes"
        value={data.callingCodes?.[0]?.name}
      />
      <KeyValue
        tab={CATEGORIES.GENERAL}
        name="currencies"
        value={
          <span>
            {" "}
            {data.currencies?.[0]?.name} ({data.currencies?.[0]?.code} -{" "}
            {data.currencies?.[0]?.symbol})
          </span>
        }
      />
      <br />
    </div>
  );
}

export default General;
