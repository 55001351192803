// Modules
import React from 'react';
import Chart from 'react-apexcharts';

// Internals
import { addCommasToNumber } from '../../../internals/utils';

// Styles
import { COLORS } from '../../../routes/App/App.styles';

function PieChart({ series, labels, suffix }) {
  const options = {
    tooltip: {
      custom: ({ w, series: innerSeries, seriesIndex }) => {
        return `${w.config.labels[seriesIndex]} - ${innerSeries[seriesIndex].toFixed(1)} ${suffix}`;
      },
    },
    dataLabels: { enabled: false },
    labels,
    chart: {
      fontFamily: "'Montserrat-Medium', arial, sans-serif",
    },
    fill: {
      type: 'gradient',
      colors: COLORS.pieChart,
    },
    legend: {
      markers: {
        fillColors: COLORS.pieChart,
      },
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '500',
      formatter(val, opts) {
        return `${val} - ${addCommasToNumber(+opts.w.globals.series[opts.seriesIndex].toFixed(1))}${suffix || ''}`;
      },
    },
  };
  // TODO: clean 👇🏼 code after QA pass
  const longestString = () => {
    let longestLabel = 0;
    labels.forEach((str) => {
      if (str.length > longestLabel) {
        longestLabel = str.length;
      }
    });

    let longestSeries = 0;
    series.forEach((item) => {
      let current = item;;
      if (typeof item === 'number') {
        current = addCommasToNumber(item)
      }
      if (current.length > longestSeries) {
        longestSeries = current.length;
      }
    });
    return longestSeries+1+longestLabel+1+suffix.length;
  };
  const longest = longestString();
  const getWidthByLongestString = (longest) => {
    const x = 250;
    if (longest <= 20) {
      return x + longest * 5;
    } else if (longest <= 30) {
      return x + longest * 6;
    } else if (longest <= 40) {
      return x + longest * 7;
    } else if (longest <= 70) {
      return x + longest * 7;
    } else if (longest <= 110) {
      return x + longest * 8;
    } else {
      return x + longest * 10;
    }
  };

  return (
    <div
      style={{
        // outline: "2px solid red",
        height: 150,
        width: '100%',
        overflowY: 'hidden',
      }}
    >
      <div style={ { 
        height: 150,
        width: '100%',
        // background: "pink",
        // outline: "4px green solid"
        
      } }>
        <Chart
          height="95%"
          width={getWidthByLongestString(longest)} // function
          // width={600} // function
          type="pie"
          series={ series }
          options={ options }
          />
      </div>
    </div>
  );
}

export default PieChart;
