// Modules
import React, { useState, useContext } from 'react';

// Components
import CustomSelect, { Option, OptGroup } from '../common/CustomTreeSelect/CustomTreeSelect';

// Internals
import { countriesAndTerritoriesNamesAndFlagsList } from '../../internals/constants';
import { getCountryCodeByName } from '../../internals/utils';
import { MyContext } from '../../context/myContext';
import { useBreakpoint, BREAKPOINTS } from '../../context/mediaQueriesHelpers';

// Styles
import GeneralStyles from '../common/generalStyles';
import Styles from './CountriesAndTerritoriesList.styles';

const CountriesAndTerritoriesList = ({ selectedCountry }) => {
  const { _setCountry } = useContext(MyContext);
  const [isShowTerritories, setShowTerritories] = useState(true); // eslint-disable-line 
  const breakPoint = useBreakpoint();
  const placeHolder = breakPoint[BREAKPOINTS.PHONE]
    ? 'Select a country'
    : 'Select (or click) a country';
  const handleSelectChange = (countryName) => {
    const countryCode = getCountryCodeByName(countryName);
    _setCountry(countryCode || '');
  };

  // const onRadioChange = (e) => {
  //   setShowTerritories(e.target.checked);
  // };

  return (
    <Styles.Wrapper>
      <label htmlFor="rc_select_0" >
        <CustomSelect
          autoClearSearchValue={ false }
          placeholder={ placeHolder }
          showSearch
          virtual={false}
          value={ selectedCountry.AMChartsName || undefined }
          onChange={ handleSelectChange }
        >
          {
            Object.keys(countriesAndTerritoriesNamesAndFlagsList).map((continent) => {
              return (
                <OptGroup
                  key={ continent }
                  label={ continent }
                >
                  { countriesAndTerritoriesNamesAndFlagsList[continent]
                    .reduce((acc, country) => {
                      if (isShowTerritories || country.UNMembershipAdmissionDate) {
                        acc.push(
                          <Option
                            key={ country.code }
                            value={ country.name }
                          >
                            <Styles.StyledImg
                              src={ `/assets/flags/${country.name}.svg` }
                              alt={ country.name }
                            />
                            <GeneralStyles.ListItem>{ country.name }</GeneralStyles.ListItem>
                          </Option>,
                        );
                      }

                      return acc;
                    }, []) }
                </OptGroup>
              );
            })
          }
        </CustomSelect>
      </label>
    </Styles.Wrapper>
  );
};

export default CountriesAndTerritoriesList;
