import styled from 'styled-components/macro';
import { Layout } from 'antd';

import { sharedStyles, dimensions } from '../../routes/App/App.styles';

const { Footer } = Layout;

const Wrapper = styled(Footer)`
  ${sharedStyles.fontStyleSemiBold}
  display: flex;
  flex-direction: column-reverse; 
  align-items: center;
  height: ${dimensions.footerHeight}px;
  padding: 0;
`;

const link = styled.a.attrs(({ href }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  href,
}))`
  cursor: pointer
`;

const StyledImg = styled.img`
  height: 90%;
  margin: 0 15px;
`;

export default {
  Wrapper,
  StyledImg,
  link,
};
