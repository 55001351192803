import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo/logo.svg';
import text from '../../assets/logo/text.svg';

import Styles, { StyledColLogo } from './Navbar.styles';

const Navbar = () => {
  return (
    <Styles.Nav>
      <StyledColLogo>
        <Link to="/">
          <Styles.StyledImg alt="logo" src={ logo } />
          <Styles.StyledImg alt="name" src={ text } />
        </Link>
      </StyledColLogo>
      {/* <Link to="/list"><Styles.NavTab>List</Styles.NavTab></Link> TODO: restore */}
      <Link to="/about"><Styles.NavTab>About</Styles.NavTab></Link>
      <Link to="/contact"><Styles.NavTab>Contact</Styles.NavTab></Link>
    </Styles.Nav>
  );
};

export default Navbar;
