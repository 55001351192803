import styled from 'styled-components/macro';
import { Scrollbars } from 'react-custom-scrollbars';
import { Tabs } from 'antd';

import { setMediaQuery, BREAKPOINTS } from '../../context/mediaQueriesHelpers';
import {
  sharedStyles,
  COLORS,
} from '../../routes/App/App.styles';

const { TabPane } = Tabs;

export const StyledTabs = styled(Tabs)`
  height: 100%;
  border: 1px solid ${COLORS.border};
  
  & div.ant-tabs-content.ant-tabs-content-left {
    height: 100%;
  } 
  & div.ant-tabs-content.ant-tabs-content-top {
    height: 100%;
  }
  && .ant-tabs-nav .ant-tabs-tab {
    /* padding: 6px 19px; */
    padding: 0;
    margin: 0;
  };
  &&& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  }

  &&& .ant-tabs-ink-bar {
    width: 5px;
    height: 60px;
    border-radius: 5px;
    background-color: ${COLORS.bg};
  
    // TODO: 
    ${setMediaQuery(BREAKPOINTS.PHONE)`
      height: 6px;
    `}
    ${setMediaQuery(BREAKPOINTS.TABLET)`
      height: 6px;
    `}
  }
  && .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active{ // selected
  }
`;
export const StyledTabPane = styled(TabPane)`
  height: 100%;
`;

export const StyledScrollbars = styled(Scrollbars)`
  margin: 0 5px 0;
`;

export const StyledTab = styled.span`
`;

const StyledTabImg = styled.img`
  width: 37px;
  margin: 7px;
  height: auto;
`;

const HeaderIconImg = styled.img`
  margin-left: 5;
  width: 43px;
  height: auto;
`;

const HeaderFlagImg = styled.img`
  width: 112px;
  height: auto;
  float: right;
  margin: 5px 10px 0 0;
  border: 1px ${COLORS.border} solid;
`;

const StyledHeader = styled.h1`
  display: inline-block;
  ${sharedStyles.fontStyleBold}
  font-size: 24px;
  line-height: 57px;
  color: ${COLORS.bg};
  margin: 0px 0 18px 20px;
`;

const TabHeader = styled.div`
  margin-bottom: 13px;
`;

export default {
  StyledTabImg,
  HeaderIconImg,
  HeaderFlagImg,
  StyledHeader,
  TabHeader,
};
