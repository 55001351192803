import styled from 'styled-components/macro';
import { Select } from 'antd';
import Button from '../common/Button';

import { sharedStyles, COLORS } from '../../routes/App/App.styles';

export const { Option } = Select;

const Wrapper = styled.div`
  margin-bottom: 52px;
`;

const TableHeader = styled.div`
  ${sharedStyles.fontStyleBold}
  text-align: center;
  font-size: 24px;
  margin-top: 10px;
  line-height: 45px;
  background-color: ${COLORS.background};
  color: ${COLORS.lightGrey};
  font-weight: 700;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;

  @media (max-width: 450px) {
    font-size: 20px;
    line-height: 30px;
  };
`;

const Title = styled.span`
  color: ${COLORS.bg};
  text-transform: lowercase;
`;

const SelectAndCloseRow = styled.div`
  height: 70px;
  margin-top: 5px;
`;

export const StyledSelect = styled(Select)`
  border: 1px solid ${COLORS.border};
  width: 60%;
  border: 1px solid ${COLORS.border};
  border-radius: 5px;
  
  @media(max-width: 450px) {
    width: 90%;
    margin: 5px 13px; // TODO:
  }

  && .ant-select-selector{
    background-color: ${COLORS.white};
    height: 100%;
    .ant-select-selection-placeholder {
      ${sharedStyles.fontStyleMedium}
      font-size: 16px;
      font-weight: 500;
      color: ${COLORS.lightGrey};
    }
  }
`;

export const StyledCloseButton = styled(Button)`
  display: inline;
  float: right;
`;

export const StyledShowTableButton = styled(Button)`
  margin: 28px 46% 0;
`;

export default {
  Wrapper,
  TableHeader,
  SelectAndCloseRow,
  Title,
};
