/* eslint-disable max-len */
// Modules
import React from 'react';

// Internals
import { useBreakpoint, BREAKPOINTS } from '../../context/mediaQueriesHelpers';
import { MyContext } from '../../context/myContext';
import { initialCountriesListToMap } from '../../internals/constants';

// Components
import Button from '../common/Button';
import arrow from '../../assets/icons/arrow.png';

// TODO: Temporary component - REMOVE after google crawls the site (replace with ip detection in Main.js) 
export default function NoCountrySelected() {
  const breakpoint = useBreakpoint();
  const isVerticalView = breakpoint[BREAKPOINTS.PHONE] || breakpoint[BREAKPOINTS.TABLET];
  const { _setCountry } = React.useContext(MyContext);

  const selectRandomCountry = () => _setCountry(
    initialCountriesListToMap[Math.floor(Math.random() * (initialCountriesListToMap.length))].id
  );

  return (
    <div
      style={ {
        marginTop: '15px',
        display: 'flex',
        flexDirection: 'column',
        color: '#175d78',
        textAlign: 'center',
        fontSize: breakpoint[BREAKPOINTS.PHONE] ? '1em' : '1.4em',
      } }
    >
      <h2
        style={ {
          textDecoration: 'underline',
          fontWeight: 900,
          color: '#175D78',
        } }
      >
        Welcome to Countryopedia
      </h2>
      <p>Here you can discover information about the countries and territories of the world in a simple & intuitive way.</p>

      <p
        style={ {
          textAlign: isVerticalView ? 'center' : 'end',
        } }
      >
        Click on a country or select it from the list
        {
          !breakpoint[BREAKPOINTS.PHONE] && (
            <img
              style={ {
                width: '42px',
                height: 'auto',
                transform: isVerticalView ? 'rotate(-90deg)' : '',
              } }
              src={ arrow }
              alt="right arrow"
            />
          )
        }
      </p>
      <p
        style={ {
          textAlign: isVerticalView ? 'center' : 'start',
        } }
      >
        {
          !breakpoint[BREAKPOINTS.PHONE] && (
            <img
              style={ {
                transform: isVerticalView ? 'rotate(-90deg)' : 'rotate(180deg)',
                width: '42px',
                height: 'auto',
              } }
              src={ arrow }
              alt="right arrow"
            />
          )
        }
        Explore the different categories
      </p>
      <hr />
      <p style={ { alignSelf: 'center' } } >Not sure with which country to start?</p>
      <Button
        onClick={ selectRandomCountry }
        style={ { alignSelf: 'center', width: '200px' } }
      >
        Pick a random country
      </Button>
    </div >
  );
}
