import React from 'react';
import ReactDOM from 'react-dom';

import App from './routes/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();


if (process.env.NODE_ENV === 'production') {
  console.log(`
                                    ,;;;, 
                                  ,;;;;;;;, 
              .;;;,            ,;;;;;;;;;;;, 
              .;;%%;;;,        ,;;;;;;;;;;;;;, 
              ;;%%%%%;;;;,.    ;;;;;;;;;;;;;;; 
              ;;%%%%%%%%;;;;;, ;;;;;;;;;;;;;;; 
              ';;%%%%%%%%%;;;;;,;;;;;;;;;;;;;' 
              ';;%%%%%%%%%%;;;;,;;;;;;;;;;;' 
                ';;;%%%%%%%%;;;;,;;;;;;;;;' 
                    ';;;%%%%%%;;;;.;;;.;;; 
                      ';;;%%%;;;;;;.;;;,; .,;;' 
                          ';;;;;;;;;;,;;;;;;'.,;;;, 
                            ;;;;;;;;;;;;;;;;;;;;;,. 
            .          ..,,;;;;;......;;;;;;;.... '; 
            ;;,..,;;;;;;;;;;;;..;;;;;;..;;;;.;;;;;. 
            ';;;;;;;;;;;;;;..;;;a@@@@a;;;;;;;a@@@@a, 
          .,;;;;;;;;;;;;;;;.;;;a@@@@@@@@;;;;;,@@@@@@@a, 
        .;;;,;;;;;;;;;;;;;;;;;@@@@@'  @@;;;;;;,@  '@@@@;, 
      ;' ,;;;,;;;;;;;;;;;;;;;@@@@@aa@@;;;;,;;;,@aa@@@@;;;,.,; 
        ;;;,;;;;;;;;;;;;;;;;;;@@@@@@@;;;,;a@@'      ';;;;;;;' 
        ' ;;;,;;;;;;;;;;;;;;;;;;;;;;;;,;a@@@       #  ;;,;;, 
  .//////,,;,;;;;;;;;;;;;;;;,;;;;;;;;,;;a@@@a,        ,a;;;,;;, 
  %,/////,;;;;;;;;;;;;;;;;;;;;,;,;,;;;;a@@@@@@aaaaaaa@@@;;;;;'; 
  '%%%%,/,;;;;;;;;;;;;;;;;;;;;;;;;;;;;;@@@@@@@@@@@;00@@;;;;;' 
    %%%%%%,;;;;;;;;;;;;;;;;;;;;;;;;;;;a@@@@@@@@@@;00@@;;;;;' 
    '%%%%%%%%%%,;;;;;;;;;;;;;;;;;;;;a@@@@@@@@@;00@@;;;;;' 
      '%%%%%%%%%%%%%%%,::::;;;;;;;;a@@@@@@@;00@@@::;;;%%%%%, 
        '%%%%%%%%%%%%%%%,::::;;;;;@@@@@@' 0@@@@::;;%%%%%%%%' 
            Oo%%%%%%%%%%%%,::::;;a@@@@@'  ,@@@::;;%%%%%%%' 
            'OOo%%%%%%%%%%,::::@@@@@'    @@;::;%%%%%%'      
              'OOOo%%%%%%%%,:::@@@@,;;;,a@:;;%%%%%'         
                'OOOOOo%%%%%,:::@@@aaaa@';;%%%%'            
                    'OOOO;@@@@@@@@aa@@@@@@@@@'          
                        ;@@@@@@@@@@@@@@@@@@@'           
                        @@@@@@@@''@@@@@@@@'            
                        '@@@@@'    @@@@@'                
                          '@@'       @@'

                                    /\\ 
                              　／￣￣  ￣￣￣￣￣￣￣￣￣￣￣￣￣￣\\                  
                              | ...What are you doing here?  |         
                              ＼＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿/        
  `);
}