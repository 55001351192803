import styled from 'styled-components/macro';
import { Button } from 'antd';
import { sharedStyles, COLORS } from '../../../routes/App/App.styles';

const StyledCloseButton = styled(Button)`
  ${sharedStyles.fontStyleMedium}
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: ${COLORS.bg};
  
  span {
    color: ${COLORS.white};
  }
  
  :hover, :focus {
    border: 2px solid ${COLORS.bg};
    background-color: ${COLORS.white};
    span{
      color: ${COLORS.bg};
    }
    :active {
      font-weight: 700;
    }
  }
`;

export default StyledCloseButton;
