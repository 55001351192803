// Modules
import React, {
  useEffect,
  // useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryParam, StringParam } from 'use-query-params';

// Internals
import { BREAKPOINTS, useBreakpoint } from '../../context/mediaQueriesHelpers';
import selectors from '../../redux/selectors';
import actions from '../../redux/actions';
import { MyContext } from '../../context/myContext';
import {
  validateAMChartsCode,
  validateTab,
  validateKey,
} from '../../internals/utils';

// Components
import ScrollTopArrow from '../../components/common/ScrollToTopArrow';
// import Spinner from '../../components/common/Spinner';
import MainHeader from './Main.header';
import CountriesAndTerritoriesList from '../../components/CountriesAndTerritoriesList';
import CategoryTabs from '../../components/CategoryTabs';

// Styles
import styles from './Main.styles';

const BigMap = React.lazy(() => import('../../components/BigMap'));
const KeyComparisonContainer = React.lazy(() => import('../../components/KeyComparisonContainer'));

function Main() {
  const breakPoint = useBreakpoint();
  const dispatch = useDispatch();

  // const getCountryGeoLocation = async () => {
  //   const result = await fetch('https://geolocation-db.com/json/c0593a60-4159-11eb-80cd-db15f946225f');
  //   const stringedResult = await result.text();
  //   const GeoCountryCode = JSON.parse(stringedResult).country_code;

  //   const validatedCountryQS = validateAMChartsCode(GeoCountryCode);
  //   dispatch(actions.updateCountry(validatedCountryQS));
  // };

  // Get QS
  const [isBackTriggered, seIsBackTriggered] = React.useState(0)
  const [countryQS, setCountryQS] = useQueryParam('country', StringParam);
  const [tabQS, setTabQS] = useQueryParam('tab', StringParam);
  const [keyQS, setKeyQS] = useQueryParam('key', StringParam);

  // Validate QS
  const validatedCountryQS = validateAMChartsCode(countryQS);
  const validatedTabQS = validateTab(tabQS);
  const validatedKeyQS = validateKey(keyQS);

  // Get Current country, tab & key
  const country = useSelector(selectors.getCountry);
  const key = useSelector(selectors.getKey);
  const tab = useSelector(selectors.getTab);
  const countriesListToMap = useSelector(selectors.getCountriesListToMap);
  const tableData = useSelector(selectors.getTableData);


  useEffect(() => {
    window.addEventListener('popstate', function(event) {
      seIsBackTriggered(Math.random())
    }, false);

  }, []);
  useEffect(() => {
    // IF validatedQS is different from current - update
    if (countryQS !== country.AMChartsCode) {
      // if (validatedCountryQS === '') {
      //   getCountryGeoLocation();
      // } else {
      dispatch(actions.updateCountry(validatedCountryQS, setCountryQS));
      // }
    }
    if (tabQS !== tab) {
      dispatch(actions.updateTab(validatedTabQS, setTabQS));
    }
    if (keyQS !== key.keyPath) {
      dispatch(actions.updateKey(validatedKeyQS, setKeyQS));
    }
    // return () => { }
  }, [isBackTriggered]); // eslint-disable-line 

  const updateCountryDispatcher = (c) => dispatch(actions.updateCountry(c, setCountryQS));
  const updateTabDispatcher = (t) => dispatch(actions.updateTab(t, setTabQS));
  const updateKeyDispatcher = (k) => dispatch(actions.updateKey(k, setKeyQS));

  return (
    <MyContext.Provider
      value={ {
        _setCountry: updateCountryDispatcher,
        _setTab: updateTabDispatcher,
        _setKey: updateKeyDispatcher,
      } }
    >
      <ScrollTopArrow />
      <styles.Wrapper isTable={ key.keyPath }>
        <MainHeader
          countryName={ country.AMChartsName }
          countryCode={ country.AMChartsCode }
          tab={ tab }
        />
        <styles.MapContainer>
          <CountriesAndTerritoriesList
            selectedCountry={ country }
          />
          { breakPoint.currentQuery && breakPoint.currentQuery !== BREAKPOINTS.PHONE && ( // conditionally render map
            <BigMap
              selectedCountry={ country }
              countriesListToMap={ countriesListToMap }
              keyTitle={ key.keyTitle }
            />
          ) }
        </styles.MapContainer>
        <React.Suspense >
          <styles.TabsContainer isTable={ key.keyPath }>
            <CategoryTabs
              selectedTab={ tab }
              selectedCountry={ country }
              onTabChange={ updateTabDispatcher }
            />
          </styles.TabsContainer>
        </React.Suspense>
        { key.keyPath && (
          <styles.ComparisonTableContainer>
            {/* <React.Suspense fallback={ <Spinner type="tab" /> }> */ }
            <KeyComparisonContainer
              tableTitle={ key.keyTitle }
              tableData={ tableData }
              isMain
              closeTable={ () => updateKeyDispatcher('') }
            />
            {/* </React.Suspense> */ }
          </styles.ComparisonTableContainer>
        ) }
      </styles.Wrapper>
    </MyContext.Provider>
  );
}

export default Main;
