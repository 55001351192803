import React from 'react';
import styled from 'styled-components/macro';

import { myStartCase } from '../../../internals/utils';
import { sharedStyles, COLORS } from '../../../routes/App/App.styles';

const StyledComment = styled.span`
  text-align: left;
  font-style: italic;
  ${sharedStyles.fontStyleMedium}
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 32px;
  color: ${COLORS.lightGrey};
  font-weight: 500;
`;

function Comment({ text, prefix = '' }) {
  return (
    <StyledComment>
      {prefix }
      {myStartCase(text) }
    </StyledComment>
  );
}

export default Comment;
