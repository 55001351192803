import React from 'react';
import Comment from '../Comment';

export default function Notes({ note }) {
  if (note) {
    return (
      <ul style={ { marginTop: '10px' } }>
        {
          note.split(';').map((i, index) => (
            <li key={ index }>
              <Comment text={ i.concat('.') } />
            </li>
          ))
        }
      </ul>
    );
  }

  return '';
}
