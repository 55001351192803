import React from 'react';
import { Helmet } from 'react-helmet';

import { CONSTANTS } from '../../internals/constants';

function ContactHeader() {
  return (
    <Helmet>
      <title>{ CONSTANTS.CONTACT_TITLE }</title>
      <meta
        name="description"
        content={ CONSTANTS.CONTACT_DESCRIPTION }
      />
    </Helmet>
  );
}

export default ContactHeader;
