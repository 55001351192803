import React from 'react';
import styled from 'styled-components/macro';

import { CATEGORIES } from '../../internals/constants';
import General from './General';
import Geography from './Geography';
import People from './People';
import Government from './Government';
import Links from './Links';
import Economy from './Economy';
import Consumption from './Consumption';
import Indices from './Indices';
import Energy from './Energy';
import Communication from './Communication';
import MilitaryAndSecurity from './MilitaryAndSecurity';
import TransnationalIssues from './TransnationalIssues';
import MostVisitedSites from './MostVisitedSites';
// import Food from './Food';

const StyledDiv = styled.div`
  background: rgba(239, 214, 172, 0.39);
  border-radius: 16px;
  padding: 0 5px 0;
  margin: 8px;
`;

const StyledHeader = styled.h2`
  text-align: center;
  text-decoration: underline;
`;

function CategoriesRenderer({ tabKey, countryName, data }) {
  switch (tabKey) {
    case CATEGORIES.GENERAL:
      return <General countryName={ countryName } data={ data } />;
    case CATEGORIES.CONSUMPTION:
      return <Consumption countryName={ countryName } data={ data } />;
    case CATEGORIES.INDICES:
      return <Indices countryName={ countryName } data={ data } />;
    case CATEGORIES.MOST_VISITED_SITES:
      return <MostVisitedSites data={ data } />;
    // case CATEGORIES.FOOD:
    //   return <Food countryName={ countryName } data={ data } />;
    case CATEGORIES.LINKS:
      return <Links countryName={ countryName } data={ data } />;
    case CATEGORIES.TRANSNATIONAL_ISSUES:
      if (countryName === 'Palestinian Territories') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>West Bank:</StyledHeader>
              <TransnationalIssues data={ data.west_bank } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Gaza Strip:</StyledHeader>
              <TransnationalIssues data={ data.gaza_strip } />
            </StyledDiv>
          </>
        );
      }
      if (countryName === 'Svalbard and Jan Mayen') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>Svalbard:</StyledHeader>
              <TransnationalIssues data={ data.svalbard } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Jan Mayen:</StyledHeader>
              <TransnationalIssues data={ data.jan_mayen } />
            </StyledDiv>
          </>
        );
      }

      return <TransnationalIssues data={ data } />;
    case CATEGORIES.GEOGRAPHY:
      if (countryName === 'Palestinian Territories') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>West Bank:</StyledHeader>
              <Geography data={ data.west_bank } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Gaza Strip:</StyledHeader>
              <Geography data={ data.gaza_strip } />
            </StyledDiv>
          </>
        );
      }
      if (countryName === 'Svalbard and Jan Mayen') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>Svalbard:</StyledHeader>
              <Geography data={ data.svalbard } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Jan Mayen:</StyledHeader>
              <Geography data={ data.jan_mayen } />
            </StyledDiv>
          </>
        );
      }

      return <Geography data={ data } />;
    case CATEGORIES.PEOPLE:
      if (countryName === 'Palestinian Territories') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>West Bank:</StyledHeader>
              <People data={ data.west_bank } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Gaza Strip:</StyledHeader>
              <People data={ data.gaza_strip } />
            </StyledDiv>
          </>
        );
      }
      if (countryName === 'Svalbard and Jan Mayen') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>Svalbard:</StyledHeader>
              <People data={ data.svalbard } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Jan Mayen:</StyledHeader>
              <People data={ data.jan_mayen } />
            </StyledDiv>
          </>
        );
      }

      return <People data={ data } />;
    case CATEGORIES.GOVERNMENT:
      if (countryName === 'Palestinian Territories') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>West Bank:</StyledHeader>
              <Government countryName={ countryName } data={ data.west_bank } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Gaza Strip:</StyledHeader>
              <Government countryName={ countryName } data={ data.gaza_strip } />
            </StyledDiv>
          </>
        );
      }
      if (countryName === 'Svalbard and Jan Mayen') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>Svalbard:</StyledHeader>
              <Government countryName={ countryName } data={ data.svalbard } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Jan Mayen:</StyledHeader>
              <Government countryName={ countryName } data={ data.jan_mayen } />
            </StyledDiv>
          </>
        );
      }

      return <Government countryName={ countryName } data={ data } />;
    case CATEGORIES.ECONOMY:
      if (countryName === 'Palestinian Territories') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>West Bank:</StyledHeader>
              <Economy data={ data.west_bank } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Gaza Strip:</StyledHeader>
              <Economy data={ data.gaza_strip } />
            </StyledDiv>
          </>
        );
      }
      if (countryName === 'Svalbard and Jan Mayen') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>Svalbard:</StyledHeader>
              <Economy data={ data.svalbard } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Jan Mayen:</StyledHeader>
              <Economy data={ data.jan_mayen } />
            </StyledDiv>
          </>
        );
      }

      return <Economy data={ data } />;
    case CATEGORIES.ENERGY:
      if (countryName === 'Palestinian Territories') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>West Bank:</StyledHeader>
              <Energy data={ data.west_bank } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Gaza Strip:</StyledHeader>
              <Energy data={ data.gaza_strip } />
            </StyledDiv>
          </>
        );
      }
      if (countryName === 'Svalbard and Jan Mayen') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>Svalbard:</StyledHeader>
              <Energy data={ data.svalbard } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Jan Mayen:</StyledHeader>
              <Energy data={ data.jan_mayen } />
            </StyledDiv>
          </>
        );
      }

      return <Energy data={ data } />;
    case CATEGORIES.COMMUNICATION:
      if (countryName === 'Palestinian Territories') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>West Bank:</StyledHeader>
              <Communication data={ data.west_bank } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Gaza Strip:</StyledHeader>
              <Communication data={ data.gaza_strip } />
            </StyledDiv>
          </>
        );
      }
      if (countryName === 'Svalbard and Jan Mayen') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>Svalbard:</StyledHeader>
              <Communication data={ data.svalbard } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Jan Mayen:</StyledHeader>
              <Communication data={ data.jan_mayen } />
            </StyledDiv>
          </>
        );
      }

      return <Communication data={ data } />;
    case CATEGORIES.MILITARY_AND_SECURITY:
      if (countryName === 'Palestinian Territories') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>West Bank:</StyledHeader>
              <MilitaryAndSecurity data={ data.west_bank } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Gaza Strip:</StyledHeader>
              <MilitaryAndSecurity data={ data.gaza_strip } />
            </StyledDiv>
          </>
        );
      }
      if (countryName === 'Svalbard and Jan Mayen') {
        return (
          <>
            <StyledDiv>
              <StyledHeader>Svalbard:</StyledHeader>
              <MilitaryAndSecurity data={ data.svalbard } />
            </StyledDiv>
            <StyledDiv>
              <StyledHeader>Jan Mayen:</StyledHeader>
              <MilitaryAndSecurity data={ data.jan_mayen } />
            </StyledDiv>
          </>
        );
      }

      return <MilitaryAndSecurity data={ data } />;

    default:
      return 'Error in tab;';
  }
}

export default React.memo(CategoriesRenderer);
