/* eslint-disable max-len, import/no-dynamic-require */
import React from "react";
import KeyValue from "../common/KeyValue";
// import PieChart from "../common/PieChart";
import { CATEGORIES } from "../../internals/constants";
import NoDataOnCountry from "./NoDataOnCountry";
import { showMissingValues } from "../../internals/utils";

export default function Indices({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data, [
    "climate_change_performance_index",
    "EF_english_proficiency_index",
    "composite_index_of_national_capability",
    "corruption_perceptions_index",
    "global_corruption_barometer",
    "democracy_dictatorship_index",
    "global_peace_index",
    "democracy_index",
    "ease_of_doing_business_index",
    "economic_complexity",
    "economic_freedom",
    "economic_freedom_of_the_world_survey",
    "environmental_vulnerability_index",
    "fragile_states_index",
    "global_competitiveness_report",
    "global_gender_gap_report",
    "global_terrorism_index",
    "linguistic_diversity_index",
    "where_to_be_born_index",
    "world_giving_index",
  ]);

  return (
    <div>
      <br />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="climate_change_performance_index"
        value={parseFloat(data.climate_change_performance_index?.score)}
        units={"%"}
        source={data.climate_change_performance_index?.info?.link}
        tooltip={data.climate_change_performance_index?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="EF_english_proficiency_index"
        value={parseFloat(data.EF_english_proficiency_index?.score)}
        source={data.EF_english_proficiency_index?.info?.link}
        tooltip={data.EF_english_proficiency_index?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="composite_index_of_national_capability"
        value={parseFloat(data.composite_index_of_national_capability?.CINC)}
        source={data.composite_index_of_national_capability?.info?.link}
        tooltip={data.composite_index_of_national_capability?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="corruption_perceptions_index"
        value={parseFloat(data.corruption_perceptions_index?.score_2020)}
        units={"%"}
        source={data.corruption_perceptions_index?.info?.link}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="global_corruption_barometer"
        value={parseFloat(
          data.global_corruption_barometer?.percentage_of_people_who_paid_bribes
        )}
        units={"Percent of people have paid bribes"}
        source={data.global_corruption_barometer?.info?.link}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="democracy_dictatorship_index"
        value={data.democracy_dictatorship_index?.type}
        source={data.democracy_dictatorship_index?.info?.link}
        tooltip={data.democracy_dictatorship_index?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="global_peace_index"
        value={parseFloat(data.global_peace_index?.score)}
        source={data.global_peace_index?.info?.link}
        tooltip={data.global_peace_index?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="democracy_index"
        value={parseFloat(data.democracy_index?.score_2020)}
        source={data.democracy_index?.info?.link}
        tooltip={data.democracy_index?.info?.explanation}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="ease_of_doing_business_index"
        value={data.ease_of_doing_business_index?.classification}
        source={data.ease_of_doing_business_index?.info?.link}
        tooltip={data.ease_of_doing_business_index?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="economic_complexity"
        value={parseFloat(data.economic_complexity?.complexity)}
        source={data.economic_complexity?.info?.link}
        tooltip={data.economic_complexity?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="economic_freedom"
        value={parseFloat(data.economic_freedom?.score)}
        source={data.economic_freedom?.info?.link}
        tooltip={data.economic_freedom?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="economic_freedom_of_the_world_survey"
        value={parseFloat(data.economic_freedom_of_the_world_survey?.score)}
        source={data.economic_freedom_of_the_world_survey?.info?.link}
        tooltip={data.economic_freedom_of_the_world_survey?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="environmental_vulnerability_index"
        value={parseFloat(data.environmental_vulnerability_index?.score)}
        source={data.environmental_vulnerability_index?.info?.link}
        tooltip={data.environmental_vulnerability_index?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="fragile_states_index"
        value={parseFloat(data.fragile_states_index?.complexity)}
        source={data.fragile_states_index?.info?.link}
        tooltip={data.fragile_states_index?.info?.explanation}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="global_competitiveness_report"
        value={parseFloat(data.global_competitiveness_report?.complexity)}
        source={data.global_competitiveness_report?.info?.link}
        tooltip={data.global_competitiveness_report?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="global_gender_gap_report"
        value={parseFloat(data.global_gender_gap_report?.score)}
        source={data.global_gender_gap_report?.info?.link}
        tooltip={data.global_gender_gap_report?.info?.explanation}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="global_terrorism_index"
        value={parseFloat(data.global_terrorism_index?.score)}
        source={data.global_terrorism_index?.info?.link}
        tooltip={data.global_terrorism_index?.info?.explanation}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="linguistic_diversity_index"
        value={parseFloat(data.linguistic_diversity_index?.LDI)}
        source={data.linguistic_diversity_index?.info?.link}
        tooltip={data.linguistic_diversity_index?.info?.description}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="where_to_be_born_index"
        units={"/10"}
        value={parseFloat(data.where_to_be_born_index?.score)}
        source={data.where_to_be_born_index?.info?.link}
        tooltip={data.where_to_be_born_index?.info?.explanation}
      />
      <KeyValue
        tab={`${CATEGORIES.INDICES}`}
        name="world_giving_index"
        units={"/147"}
        value={parseFloat(data.world_giving_index?.rank_2018)}
        source={data.world_giving_index?.info?.link}
        tooltip={data.world_giving_index?.info?.description}
      />
      {/* <KeyValue
        tab={`${CATEGORIES.CONSUMPTION}`}
        name="gun_ownership"
        value={data.gun_ownership?.firearmPossessionPer100K}
        units={"per 100k population"}
        source={data.gun_ownership?.info?.link}
      />
      {data?.alcohol_consumption?.beer_percentage && (
        <PieChart
          series={[
            data?.alcohol_consumption?.beer_percentage,
            data?.alcohol_consumption?.spirits_percentage,
            data?.alcohol_consumption?.wine_percentage,
            data?.alcohol_consumption?.other_percentage,
          ]}
          labels={["Beer", "Spirits", "Wine", "Other"]}
          suffix="%"
        />
      )} */}
      <br />
    </div>
  );
}
