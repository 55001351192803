/* eslint-disable max-len */

// -=-=-=-=-=-=-=-=-=-=-= shared with server -=-=-=-=-=-=-=-=-=-=-
export const CATEGORIES = {  // server & client
  GEOGRAPHY: 'Geography',
  PEOPLE: 'People',
  GOVERNMENT: 'Government',
  ECONOMY: 'Economy',
  ENERGY: 'Energy',
  COMMUNICATION: 'Communication',
  MILITARY_AND_SECURITY: 'Military and Security',
  TRANSNATIONAL_ISSUES: 'Transnational issues',
  MOST_VISITED_SITES: 'Most Visited Sites',
  LINKS: 'Links',
  GENERAL: 'General',
  CONSUMPTION: 'Consumption',
  INDICES: 'Indices',
  // LAWS: 'Laws',
  // ACHIEVEMENTS: 'Achievements',
  // EDUCATION: 'Education',
  // OUTPUT: 'Output',
  // WIKIPEDIA: 'Wikipedia',
  // FOOD: 'Food',
  // COVID19: 'Covid-19',
  // TRANSPORTATION: 'Transportation',
};

export const initialCountriesListToMap = [ // client & server
  {
    id: 'AF',
    name: 'Afghanistan',
  },
  {
    id: 'AL',
    name: 'Albania',
  },
  {
    id: 'DZ',
    name: 'Algeria',
  },
  {
    id: 'AS',
    name: 'American Samoa',
  },
  {
    id: 'AD',
    name: 'Andorra',
  },
  {
    id: 'AO',
    name: 'Angola',
  },
  {
    id: 'AI',
    name: 'Anguilla',
  },
  {
    id: 'AQ',
    name: 'Antarctica',
  },
  {
    id: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    id: 'AR',
    name: 'Argentina',
  },
  {
    id: 'AM',
    name: 'Armenia',
  },
  {
    id: 'AW',
    name: 'Aruba',
  },
  {
    id: 'AU',
    name: 'Australia',
  },
  {
    id: 'AT',
    name: 'Austria',
  },
  {
    id: 'AZ',
    name: 'Azerbaijan',
  },
  {
    id: 'BS',
    name: 'Bahamas',
  },
  {
    id: 'BH',
    name: 'Bahrain',
  },
  {
    id: 'BD',
    name: 'Bangladesh',
  },
  {
    id: 'BB',
    name: 'Barbados',
  },
  {
    id: 'BY',
    name: 'Belarus',
  },
  {
    id: 'BE',
    name: 'Belgium',
  },
  {
    id: 'BZ',
    name: 'Belize',
  },
  {
    id: 'BJ',
    name: 'Benin',
  },
  {
    id: 'BM',
    name: 'Bermuda',
  },
  {
    id: 'BT',
    name: 'Bhutan',
  },
  {
    id: 'BO',
    name: 'Bolivia',
  },
  {
    id: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    id: 'BW',
    name: 'Botswana',
  },
  {
    id: 'BV',
    name: 'Bouvet Island',
  },
  {
    id: 'BR',
    name: 'Brazil',
  },
  {
    id: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    id: 'VG',
    name: 'British Virgin Islands',
  },
  {
    id: 'BN',
    name: 'Brunei',
  },
  {
    id: 'BG',
    name: 'Bulgaria',
  },
  {
    id: 'BF',
    name: 'Burkina Faso',
  },
  {
    id: 'BI',
    name: 'Burundi',
  },
  {
    id: 'KH',
    name: 'Cambodia',
  },
  {
    id: 'CM',
    name: 'Cameroon',
  },
  {
    id: 'CA',
    name: 'Canada',
  },
  {
    id: 'CV',
    name: 'Cape Verde',
  },
  {
    id: 'KY',
    name: 'Cayman Islands',
  },
  {
    id: 'CF',
    name: 'Central African Republic',
  },
  {
    id: 'TD',
    name: 'Chad',
  },
  {
    id: 'CL',
    name: 'Chile',
  },
  {
    id: 'CN',
    name: 'China',
  },
  {
    id: 'CX',
    name: 'Christmas Island',
  },
  {
    id: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  {
    id: 'CO',
    name: 'Colombia',
  },
  {
    id: 'KM',
    name: 'Comoros',
  },
  {
    id: 'CK',
    name: 'Cook Islands',
  },
  {
    id: 'CR',
    name: 'Costa Rica',
  },
  {
    id: 'HR',
    name: 'Croatia',
  },
  {
    id: 'CU',
    name: 'Cuba',
  },
  {
    id: 'CW',
    name: 'Curaçao',
  },
  {
    id: 'CY',
    name: 'Cyprus',
  },
  {
    id: 'CZ',
    name: 'Czechia',
  },
  {
    id: 'CD',
    name: 'Democratic Republic of Congo',
  },
  {
    id: 'DK',
    name: 'Denmark',
  },
  {
    id: 'DJ',
    name: 'Djibouti',
  },
  {
    id: 'DM',
    name: 'Dominica',
  },
  {
    id: 'DO',
    name: 'Dominican Republic',
  },
  {
    id: 'TL',
    name: 'Timor-Leste',
  },
  {
    id: 'EC',
    name: 'Ecuador',
  },
  {
    id: 'EG',
    name: 'Egypt',
  },
  {
    id: 'SV',
    name: 'El Salvador',
  },
  {
    id: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    id: 'ER',
    name: 'Eritrea',
  },
  {
    id: 'EE',
    name: 'Estonia',
  },
  {
    id: 'SZ',
    name: 'eSwatini',
  },
  {
    id: 'ET',
    name: 'Ethiopia',
  },
  {
    id: 'FO',
    name: 'Faroe Islands',
  },
  {
    id: 'FK',
    name: 'Falkland Islands',
  },
  {
    id: 'FM',
    name: 'Federated States of Micronesia',
  },
  {
    id: 'FJ',
    name: 'Fiji',
  },
  {
    id: 'FI',
    name: 'Finland',
  },
  {
    id: 'FR',
    name: 'France',
  },
  {
    id: 'PF',
    name: 'French Polynesia',
  },
  {
    id: 'GA',
    name: 'Gabon',
  },
  {
    id: 'GM',
    name: 'Gambia',
  },
  {
    id: 'GE',
    name: 'Georgia',
  },
  {
    id: 'DE',
    name: 'Germany',
  },
  {
    id: 'GH',
    name: 'Ghana',
  },
  {
    id: 'GI',
    name: 'Gibraltar',
  },
  {
    id: 'GR',
    name: 'Greece',
  },
  {
    id: 'GL',
    name: 'Greenland',
  },
  {
    id: 'GD',
    name: 'Grenada',
  },
  {
    id: 'GU',
    name: 'Guam',
  },
  {
    id: 'GT',
    name: 'Guatemala',
  },
  {
    id: 'GG',
    name: 'Guernsey',
  },
  {
    id: 'GN',
    name: 'Guinea',
  },
  {
    id: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    id: 'GY',
    name: 'Guyana',
  },
  {
    id: 'HT',
    name: 'Haiti',
  },
  {
    id: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    id: 'HN',
    name: 'Honduras',
  },
  {
    id: 'HK',
    name: 'Hong Kong',
  },
  {
    id: 'HU',
    name: 'Hungary',
  },
  {
    id: 'IS',
    name: 'Iceland',
  },
  {
    id: 'IN',
    name: 'India',
  },
  {
    id: 'ID',
    name: 'Indonesia',
  },
  {
    id: 'IR',
    name: 'Iran',
  },
  {
    id: 'IQ',
    name: 'Iraq',
  },
  {
    id: 'IE',
    name: 'Ireland',
  },
  {
    id: 'IM',
    name: 'Isle of Man',
  },
  {
    id: 'IL',
    name: 'Israel',
  },
  {
    id: 'IT',
    name: 'Italy',
  },
  {
    id: 'CI',
    name: "Côte d'Ivoire",
  },
  {
    id: 'JM',
    name: 'Jamaica',
  },
  {
    id: 'JP',
    name: 'Japan',
  },
  {
    id: 'JE',
    name: 'Jersey',
  },
  {
    id: 'JO',
    name: 'Jordan',
  },
  {
    id: 'KZ',
    name: 'Kazakhstan',
  },
  {
    id: 'KE',
    name: 'Kenya',
  },
  {
    id: 'KI',
    name: 'Kiribati',
  },
  {
    id: 'XK',
    name: 'Kosovo',
  },
  {
    id: 'KW',
    name: 'Kuwait',
  },
  {
    id: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    id: 'LA',
    name: "Lao People's Democratic Republic",
  },
  {
    id: 'LV',
    name: 'Latvia',
  },
  {
    id: 'LB',
    name: 'Lebanon',
  },
  {
    id: 'LS',
    name: 'Lesotho',
  },
  {
    id: 'LR',
    name: 'Liberia',
  },
  {
    id: 'LY',
    name: 'Libya',
  },
  {
    id: 'LI',
    name: 'Liechtenstein',
  },
  {
    id: 'LT',
    name: 'Lithuania',
  },
  {
    id: 'LU',
    name: 'Luxembourg',
  },
  {
    id: 'MO',
    name: 'Macau',
  },
  {
    id: 'MG',
    name: 'Madagascar',
  },
  {
    id: 'MW',
    name: 'Malawi',
  },
  {
    id: 'MY',
    name: 'Malaysia',
  },
  {
    id: 'MV',
    name: 'Maldives',
  },
  {
    id: 'ML',
    name: 'Mali',
  },
  {
    id: 'MT',
    name: 'Malta',
  },
  {
    id: 'MH',
    name: 'Marshall Islands',
  },
  {
    id: 'MR',
    name: 'Mauritania',
  },
  {
    id: 'MU',
    name: 'Mauritius',
  },
  {
    id: 'MX',
    name: 'Mexico',
  },
  {
    id: 'MD',
    name: 'Moldova',
  },
  {
    id: 'MC',
    name: 'Monaco',
  },
  {
    id: 'MN',
    name: 'Mongolia',
  },
  {
    id: 'ME',
    name: 'Montenegro',
  },
  {
    id: 'MS',
    name: 'Montserrat',
  },
  {
    id: 'MA',
    name: 'Morocco',
  },
  {
    id: 'MZ',
    name: 'Mozambique',
  },
  {
    id: 'MM',
    name: 'Myanmar',
  },
  {
    id: 'NA',
    name: 'Namibia',
  },
  {
    id: 'NR',
    name: 'Nauru',
  },
  {
    id: 'NP',
    name: 'Nepal',
  },
  {
    id: 'NL',
    name: 'Netherlands',
  },
  {
    id: 'NC',
    name: 'New Caledonia',
  },
  {
    id: 'NZ',
    name: 'New Zealand',
  },
  {
    id: 'NI',
    name: 'Nicaragua',
  },
  {
    id: 'NE',
    name: 'Niger',
  },
  {
    id: 'NG',
    name: 'Nigeria',
  },
  {
    id: 'NU',
    name: 'Niue',
  },
  {
    id: 'NF',
    name: 'Norfolk Island',
  },
  {
    id: 'KP',
    name: 'North Korea',
  },
  {
    id: 'MK',
    name: 'North Macedonia',
  },
  {
    id: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    id: 'NO',
    name: 'Norway',
  },
  {
    id: 'OM',
    name: 'Oman',
  },
  {
    id: 'PK',
    name: 'Pakistan',
  },
  {
    id: 'PW',
    name: 'Palau',
  },
  {
    id: 'PS',
    name: 'Palestinian Territories',
  },
  {
    id: 'PA',
    name: 'Panama',
  },
  {
    id: 'PG',
    name: 'Papua New Guinea',
  },
  {
    id: 'PY',
    name: 'Paraguay',
  },
  {
    id: 'PE',
    name: 'Peru',
  },
  {
    id: 'PH',
    name: 'Philippines',
  },
  {
    id: 'PN',
    name: 'Pitcairn Islands',
  },
  {
    id: 'PL',
    name: 'Poland',
  },
  {
    id: 'PT',
    name: 'Portugal',
  },
  {
    id: 'PR',
    name: 'Puerto Rico',
  },
  {
    id: 'QA',
    name: 'Qatar',
  },
  {
    id: 'CG',
    name: 'Republic of Congo',
  },
  {
    id: 'RO',
    name: 'Romania',
  },
  {
    id: 'RU',
    name: 'Russia',
  },
  {
    id: 'RW',
    name: 'Rwanda',
  },
  {
    id: 'BL',
    name: 'Saint Barthelemy',
  },
  {
    id: 'SH',
    name: 'Saint Helena',
  },
  {
    id: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    id: 'LC',
    name: 'Saint Lucia',
  },
  {
    id: 'MF',
    name: 'Saint Martin',
  },
  {
    id: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    id: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    id: 'WS',
    name: 'Samoa',
  },
  {
    id: 'SM',
    name: 'San Marino',
  },
  {
    id: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    id: 'SA',
    name: 'Saudi Arabia',
  },
  {
    id: 'SN',
    name: 'Senegal',
  },
  {
    id: 'RS',
    name: 'Serbia',
  },
  {
    id: 'SC',
    name: 'Seychelles',
  },
  {
    id: 'SL',
    name: 'Sierra Leone',
  },
  {
    id: 'SG',
    name: 'Singapore',
  },
  {
    id: 'SX',
    name: 'Sint Maarten',
  },
  {
    id: 'SK',
    name: 'Slovakia',
  },
  {
    id: 'SI',
    name: 'Slovenia',
  },
  {
    id: 'SB',
    name: 'Solomon Islands',
  },
  {
    id: 'SO',
    name: 'Somalia',
  },
  {
    id: 'ZA',
    name: 'South Africa',
  },
  {
    id: 'GS',
    name: 'South Georgia and South Sandwich Islands',
  },
  {
    id: 'KR',
    name: 'South Korea',
  },
  {
    id: 'SS',
    name: 'South Sudan',
  },
  {
    id: 'ES',
    name: 'Spain',
  },
  {
    id: 'LK',
    name: 'Sri Lanka',
  },
  {
    id: 'SD',
    name: 'Sudan',
  },
  {
    id: 'SR',
    name: 'Suriname',
  },
  {
    id: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    id: 'SE',
    name: 'Sweden',
  },
  {
    id: 'CH',
    name: 'Switzerland',
  },
  {
    id: 'SY',
    name: 'Syria',
  },
  {
    id: 'TW',
    name: 'Taiwan',
  },
  {
    id: 'TJ',
    name: 'Tajikistan',
  },
  {
    id: 'TZ',
    name: 'Tanzania',
  },
  {
    id: 'TH',
    name: 'Thailand',
  },
  {
    id: 'TG',
    name: 'Togo',
  },
  {
    id: 'TK',
    name: 'Tokelau',
  },
  {
    id: 'TO',
    name: 'Tonga',
  },
  {
    id: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    id: 'TN',
    name: 'Tunisia',
  },
  {
    id: 'TR',
    name: 'Turkey',
  },
  {
    id: 'TM',
    name: 'Turkmenistan',
  },
  {
    id: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    id: 'TV',
    name: 'Tuvalu',
  },
  {
    id: 'UG',
    name: 'Uganda',
  },
  {
    id: 'UA',
    name: 'Ukraine',
  },
  {
    id: 'AE',
    name: 'United Arab Emirates',
  },
  {
    id: 'GB',
    name: 'United Kingdom',
  },
  {
    id: 'US',
    name: 'United States',
  },
  {
    id: 'VI',
    name: 'US Virgin Islands',
  },
  {
    id: 'UY',
    name: 'Uruguay',
  },
  {
    id: 'UZ',
    name: 'Uzbekistan',
  },
  {
    id: 'VU',
    name: 'Vanuatu',
  },
  {
    id: 'VA',
    name: 'Vatican City',
  },
  {
    id: 'VE',
    name: 'Venezuela',
  },
  {
    id: 'VN',
    name: 'Vietnam',
  },
  {
    id: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    id: 'EH',
    name: 'Western Sahara',
  },
  {
    id: 'YE',
    name: 'Yemen',
  },
  {
    id: 'ZM',
    name: 'Zambia',
  },
  {
    id: 'ZW',
    name: 'Zimbabwe',
  },
];
// -=-=-=-=-=-=-=-=-=-=-= shared with server -=-=-=-=-=-=-=-=-=-=-

export const TITLES = { // API & client
  // 'General.names': "country's Names",
  // 'General.names.nativeName': "country's Native Name",
  // 'General.names.officialName': "country's Official Name",
  'General.unCode': 'UN Code',
  'General.internetCode': 'internet Code',
  'General.alpha2Code': 'Alpha 2 Code',
  'General.alpha3Code': 'Alpha 3 Code',
  'General.callingCodes': 'Calling Codes',
  'General.currencies': 'Currencies',
  'Geography.area.total': 'Total Area',
  'Geography.area.land': 'Total Land Area',
  'Geography.area.water': 'Total Water Area',
  'Geography.irrigated_land': 'Total Irrigated Land Area',
  'Geography.coastline': 'Coastline',
  'Geography.elevation.mean_elevation': 'Mean Elevation',
  'Geography.elevation.highest_point': 'Highest Elevation Point',
  'Geography.elevation.lowest_point': 'Lowest Elevation Point',
  'Geography.land_boundaries.total': 'Total Land Boundaries',
  'People.nationality': 'Nationality',
  'People.population': 'Population',
  'People.population_growth_rate': 'Population Growth Rate (%)',
  'People.birth_rate': 'Births (/1000)',
  'People.total_fertility_rate': 'Fertility Rate (children born per woman)',
  'People.contraceptive_prevalence_rate': 'Contraceptive Prevalence Rate',
  'People.infant_mortality_rate': 'Infant Mortality Rate',
  'People.net_migration_rate': 'Migrants (/1000)',
  'People.hiv_aids.adult_prevalence_rate': 'HIV Aids (%)',
  'People.adult_obesity': 'Adult Obesity (%)',
  'People.physicians_density': 'Physicians (/1000)',
  'People.death_rate': 'Death (/1000)',
  'People.maternal_mortality_rate': 'Deaths (/100k births)',
  'People.hospital_bed_density': 'Hospital Bed Density (/1000)',
  'People.urbanization.urban_population': 'Urban Population',
  'People.urbanization.rate_of_urbanization': 'Rate of Urbanization (%)',
  'People.education_expenditures': 'Education Expenditures (% of GDP)',
  'People.literacy': 'Literacy (%)',
  'People.school_life_expectancy': 'School Life Expectancy',
  'People.youth_unemployment': 'Youth Unemployment',
  'People.life_expectancy_at_birth': 'Life Expectancy At Birth',
  'People.mothers_mean_age_at_first_birth': 'Mothers Mean Age At First Birth',
  'People.median_age': 'Median Age',
  'People.age_structure': 'Age Structure',
  'Government.country_name.conventional_short_form': "country's Name",
  'Government.country_name.conventional_long_form': "country's Conventional Long  Name",
  'Government.country_name.local_short_form': "country's Local Short Name",
  'Government.country_name.local_long_form': "country's Local Long Name",
  'Government.country_name.abbreviation': "country's abbreviation Name",
  'Government.country_name.former': "country's Former Name",
  'Government.flag_description': 'Flag Description',
  'Government.government_type': 'Government Type',
  'Government.independence': 'Independence Date',
  'Government.legal_system': 'Legal System',
  'Government.national_holidays': 'National Holidays',
  'Government.national_anthem': 'National Anthem',
  'Government.country_name.etymology': 'Etymology',
  'Economy.population_below_poverty_line': 'Population Below Poverty Line (%)',
  'Economy.unemployment_rate': 'Unemployment Rate (%)',
  'Economy.budget': 'Budget',
  'Economy.budget.expenditures': 'Expenditures',
  'Economy.budget.revenues': 'Revenues',
  'Economy.budget_surplus_or_deficit': 'Budget Surplus Or Deficit (% GDP)',
  'Economy.current_account_balance': 'Current Account Balance',
  'Economy.external_debt': 'External Debt',
  'Economy.public_debt': 'Public Debt',
  'Economy.gdp.per_capita_purchasing_power_parity': 'GDP - Purchasing Power Parity (/capita)',
  'Economy.gdp.purchasing_power_parity': 'GDP - Purchasing Power Parity',
  'Economy.imports': 'Imports',
  'Economy.exports': 'Exports',
  'Economy.gdp.real_growth_rate': 'GDP - Real Growth Rate',
  'Economy.industrial_production_growth_rate': 'Industrial Production Growth Rate (Annual % Increase)',
  'Energy.electricity.access.total_electrification': 'Country Total Electrification (%)',
  'Energy.electricity.consumption': 'Electricity Consumption (kWh)',
  'Energy.electricity.production': 'Electricity Production (kWh)',
  'Energy.electricity.imports': 'Electricity Imports (kWh)',
  'Energy.electricity.exports': 'Electricity Exports (kWh)',
  'Energy.electricity.by_source': 'Electricity By Source',
  'Energy.crude_oil.production': 'Crude Oil Production (bbl/day)',
  'Energy.crude_oil.imports': 'Crude Oil Imports (bbl/day)',
  'Energy.crude_oil.exports': 'Crude Oil Exports (bbl/day)',
  'Energy.crude_oil.proved_reserves': 'Crude Oil Proved Reserves (bbl)',
  'Energy.natural_gas.production': 'Natural Gas Production (cubic metres)',
  'Energy.natural_gas.consumption': 'Natural Gas Consumption (cubic metres)',
  'Energy.natural_gas.imports': 'Natural Gas Imports (cubic metres)',
  'Energy.natural_gas.exports': 'Natural Gas Exports (cubic metres)',
  'Energy.natural_gas.proved_reserves': 'Natural Gas Proved Reserves (cubic Metres)',
  'Energy.refined_petroleum_products.production': 'refined Petroleum Products Production (bbl/day)',
  'Energy.refined_petroleum_products.consumption': 'refined Petroleum Products Consumption (bbl/day)',
  'Energy.refined_petroleum_products.imports': 'refined Petroleum Products Imports (bbl/day)',
  'Energy.refined_petroleum_products.exports': 'refined Petroleum Products Exports (bbl/day)',
  'Energy.carbon_dioxide_emissions_from_consumption_of_energy': 'Carbon Dioxide Emissions (megatonnes)',
  'Communication.internet.users': 'Internet Users (%)',
  'Communication.telephones.fixed_lines': 'Fixed Telephones Lines subscriptions (%)',
  'Communication.telephones.mobile_cellular': 'Mobile Cellular subscriptions (%)',
  'Military and Security.expenditures': 'Military Expenditures',
  'Military and Security.service_age_and_obligation': 'Military Service Age',
  'Transnational issues.refugees_and_iternally_displaced_persons.stateless_persons': 'Stateless Persons',
  'Transnational issues.refugees_and_iternally_displaced_persons.internally_displaced_persons': 'Internally Displaced Persons',
  'Consumption.cigarette_consumption': 'Cigarette Consumption',
  'Consumption.cannabis_use': 'Cannabis Consumption',
  'Consumption.cocaine_use': 'Cocaine Consumption',
  'Consumption.meat_consumption': 'Meat Consumption',
  'Consumption.milk_consumption': 'Milk Consumption',
  'Consumption.opiates_use': 'Opiates Use',
  'Consumption.alcohol_consumption': 'Alcohol Consumption',
  'Consumption.gun_ownership': 'Gun Ownership',
  'Indices.climate_change_performance_index': 'Climate Change Performance Index',
  'Indices.EF_english_proficiency_index': 'English Proficiency Index',
  'Indices.composite_index_of_national_capability': 'Composite Index Of National Capability',
  'Indices.corruption_perceptions_index': 'Corruption Perceptions Index',
  'Indices.global_corruption_barometer': 'Percentage Of People Who Paid Bribes',
  'Indices.democracy_dictatorship_index': 'democracy Dictatorship Index',
  'Indices.global_peace_index': 'Global Peace Index',
  'Indices.democracy_index': 'Democracy Index',
  'Indices.ease_of_doing_business_index': 'Ease of Doing Business Index',
  'Indices.economic_complexity': 'Economic Complexity Index',
  'Indices.economic_freedom': 'Economic Freedom Index',
  'Indices.economic_freedom_of_the_world_survey': 'economic Freedom Of The World Survey',
  'Indices.environmental_vulnerability_index': 'Environmental Vulnerability Index',
  'Indices.fragile_states_index': 'Fragile States Index',
  'Indices.global_gender_gap_report': 'Global Gender Gap Report',
  'Indices.global_terrorism_index': 'Global Terrorism Index',
  'Indices.linguistic_diversity_index': 'Linguistic Diversity Index',
  'Indices.where_to_be_born_index': 'Where To Be Born Index',
  'Indices.world_giving_index': 'World Giving Index',
  'Indices.tobacco_use': 'Tobacco Use',
  'Geography.forest_area': 'Forest Area',
};

export const CONSTANTS = { // client
  NOT_AVAILABLE: 'N/A',
  APP_NAME: 'Countryopedia - The Countries Encyclopedia',
  APP_DESCRIPTION: "CountryOpedia is a country-based encyclopedia that provides easy, fast and intuitive access to interesting information about the world's countries.",
  ABOUT_TITLE: 'About - Countryopedia',
  CONTACT_TITLE: 'Contact Us - Countryopedia',
  CONTACT_DESCRIPTION: 'Contact Countryopedia --> Here you can send us general queries, comments, suggestion & partnership opportunities.',
  LIST_TITLE: 'List of Countries by',
  LIST_DESCRIPTION: 'A comprehensive list of Countries and territories by',
  MAIN_TITLE_WITH_COUNTRY: (countryName, tab) => `${tab} of ${countryName} - Countryopedia`,
  MAIN_DESCRIPTION: {
    [CATEGORIES.GEOGRAPHY]: (country) => `Explore the geography of ${country} and learn about the country's area size, borders, elevation, natural resources, population and more...`,
    [CATEGORIES.PEOPLE]: (country) => `Explore the People of ${country} and learn about the country's population, ethnic groups, languages, age structure birth % death rate and more...`,
    [CATEGORIES.GOVERNMENT]: (country) => `Explore the government of ${country} and learn about the country's name, flag, government, holidays, national anthem and more...`,
    [CATEGORIES.ECONOMY]: (country) => `Explore the economy of ${country} and learn about the country's GDP, budget, balance, imports, exports, labour force, industries and more...`,
    [CATEGORIES.ENERGY]: (country) => `Explore the energy of ${country} and learn about the country's electricity production and consumption by oil, gas, clean energy and more...`,
    [CATEGORIES.COMMUNICATION]: (country) => `Explore the communication of ${country} and learn about the country's Internet usage and connectivity, mobile & landline phone usage and more...`,
    [CATEGORIES.MILITARY_AND_SECURITY]: (country) => `Explore the Military of ${country} and learn about the country's military expenditure, service age and obligations, security forces and more...`,
    [CATEGORIES.TRANSNATIONAL_ISSUES]: (country) => `Explore the transnational issues of ${country} and learn about the country's international disputes, refugees, displaced persons and more...`,
    [CATEGORIES.MOST_VISITED_SITES]: (country) => `Explore the most visited websites of ${country} and learn the people here spend most of their time online...`,
    [CATEGORIES.LINKS]: (country) => `External link to other sources about ${country} includes Wikipedia, WorldOMeter, Nations Online, World Bank, Geography Now and more... `,
    [CATEGORIES.GENERAL]: (country) => `A conclusive list of ${country}'s general codes:  UN code, Internet code, Alpha code, calling code and more...`,
    [CATEGORIES.CONSUMPTION]: (country) => `${country}'s Consumption of products`,
    [CATEGORIES.INDICES]: (country) => `${country}'s rating in various world indices`,
  },
};

// Initial variables
export const countriesAndTerritoriesNamesAndFlagsList = { // client
  Asia: [
    {
      name: 'Afghanistan',
      code: 'AF',
      UNMembershipAdmissionDate: '19-11-46',
    },
    {
      name: 'Armenia',
      code: 'AM',
      UNMembershipAdmissionDate: '1992-02-02T23:00:00.000Z',
    },
    {
      name: 'Azerbaijan',
      code: 'AZ',
      UNMembershipAdmissionDate: '1992-02-02T23:00:00.000Z',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
      UNMembershipAdmissionDate: '17-09-74',
    },
    {
      name: 'Bhutan',
      code: 'BT',
      UNMembershipAdmissionDate: '21-09-71',
    },
    {
      name: 'China',
      code: 'CN',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Georgia',
      code: 'GE',
      UNMembershipAdmissionDate: '31-07-92',
    },
    {
      name: 'India',
      code: 'IN',
      UNMembershipAdmissionDate: '30-10-45',
    },
    {
      name: 'Japan',
      code: 'JP',
      UNMembershipAdmissionDate: '18-12-56',
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
      UNMembershipAdmissionDate: '1992-02-02T23:00:00.000Z',
    },
    {
      name: 'Kyrgyzstan',
      code: 'KG',
      UNMembershipAdmissionDate: '1992-02-02T23:00:00.000Z',
    },
    {
      name: 'Maldives',
      code: 'MV',
      UNMembershipAdmissionDate: '21-09-65',
    },
    {
      name: 'Mongolia',
      code: 'MN',
      UNMembershipAdmissionDate: '27-10-61',
    },
    {
      name: 'Nepal',
      code: 'NP',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'North Korea',
      code: 'KP',
      UNMembershipAdmissionDate: '17-09-91',
    },
    {
      name: 'Pakistan',
      code: 'PK',
      UNMembershipAdmissionDate: '30-09-47',
    },
    {
      name: 'Russia',
      code: 'RU',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'South Korea',
      code: 'KR',
      UNMembershipAdmissionDate: '17-09-91',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Tajikistan',
      code: 'TJ',
      UNMembershipAdmissionDate: '1992-02-02T23:00:00.000Z',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
      UNMembershipAdmissionDate: '1992-02-02T23:00:00.000Z',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
      UNMembershipAdmissionDate: '1992-02-02T23:00:00.000Z',
    },
  ],
  Europe: [
    {
      name: 'Albania',
      code: 'AL',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Andorra',
      code: 'AD',
      UNMembershipAdmissionDate: '28-07-93',
    },
    {
      name: 'Austria',
      code: 'AT',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Belarus',
      code: 'BY',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Belgium',
      code: 'BE',
      UNMembershipAdmissionDate: '27-12-45',
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
      UNMembershipAdmissionDate: '22-05-92',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Croatia',
      code: 'HR',
      UNMembershipAdmissionDate: '22-05-92',
    },
    {
      name: 'Czechia',
      code: 'CZ',
      UNMembershipAdmissionDate: '19-01-93',
    },
    {
      name: 'Denmark',
      code: 'DK',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Estonia',
      code: 'EE',
      UNMembershipAdmissionDate: '17-09-91',
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Finland',
      code: 'FI',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'France',
      code: 'FR',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Germany',
      code: 'DE',
      UNMembershipAdmissionDate: '18-09-73',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Greece',
      code: 'GR',
      UNMembershipAdmissionDate: '25-10-45',
    },
    {
      name: 'Guernsey',
      code: 'GG',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Hungary',
      code: 'HU',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Ireland',
      code: 'IE',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Isle of Man',
      code: 'IM',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Italy',
      code: 'IT',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Jersey',
      code: 'JE',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Kosovo',
      code: 'XK',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Latvia',
      code: 'LV',
      UNMembershipAdmissionDate: '17-09-91',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
      UNMembershipAdmissionDate: '18-09-90',
    },
    {
      name: 'Lithuania',
      code: 'LT',
      UNMembershipAdmissionDate: '17-09-91',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Malta',
      code: 'MT',
      UNMembershipAdmissionDate: '1964-01-11T23:00:00.000Z',
    },
    {
      name: 'Moldova',
      code: 'MD',
      UNMembershipAdmissionDate: '1992-02-02T23:00:00.000Z',
    },
    {
      name: 'Monaco',
      code: 'MC',
      UNMembershipAdmissionDate: '28-05-93',
    },
    {
      name: 'Montenegro',
      code: 'ME',
      UNMembershipAdmissionDate: '28-06-06',
    },
    {
      name: 'Netherlands',
      code: 'NL',
      UNMembershipAdmissionDate: '1945-10-11T23:00:00.000Z',
    },
    {
      name: 'North Macedonia',
      code: 'MK',
      UNMembershipAdmissionDate: '1993-08-03T22:00:00.000Z',
    },
    {
      name: 'Norway',
      code: 'NO',
      UNMembershipAdmissionDate: '27-11-45',
    },
    {
      name: 'Poland',
      code: 'PL',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Portugal',
      code: 'PT',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Romania',
      code: 'RO',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'San Marino',
      code: 'SM',
      UNMembershipAdmissionDate: '1992-02-02T23:00:00.000Z',
    },
    {
      name: 'Serbia',
      code: 'RS',
      UNMembershipAdmissionDate: '2000-01-10T23:00:00.000Z',
    },
    {
      name: 'Slovakia',
      code: 'SK',
      UNMembershipAdmissionDate: '19-01-93',
    },
    {
      name: 'Slovenia',
      code: 'SI',
      UNMembershipAdmissionDate: '22-05-92',
    },
    {
      name: 'Spain',
      code: 'ES',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Sweden',
      code: 'SE',
      UNMembershipAdmissionDate: '19-11-46',
    },
    {
      name: 'Switzerland',
      code: 'CH',
      UNMembershipAdmissionDate: '2002-10-08T22:00:00.000Z',
    },
    {
      name: 'United Kingdom',
      code: 'GB',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Vatican City',
      code: 'VA',
      UNMembershipAdmissionDate: null,
    },
  ],
  Africa: [
    {
      name: 'Algeria',
      code: 'DZ',
      UNMembershipAdmissionDate: '1962-08-09T23:00:00.000Z',
    },
    {
      name: 'Angola',
      code: 'AO',
      UNMembershipAdmissionDate: '1976-01-11T23:00:00.000Z',
    },
    {
      name: 'Benin',
      code: 'BJ',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Botswana',
      code: 'BW',
      UNMembershipAdmissionDate: '17-10-66',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Burundi',
      code: 'BI',
      UNMembershipAdmissionDate: '18-09-62',
    },
    {
      name: 'Cameroon',
      code: 'CM',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Cape Verde',
      code: 'CV',
      UNMembershipAdmissionDate: '16-09-75',
    },
    {
      name: 'Central African Republic',
      code: 'CF',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Chad',
      code: 'TD',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Comoros',
      code: 'KM',
      UNMembershipAdmissionDate: '1975-12-10T23:00:00.000Z',
    },
    {
      name: 'Democratic Republic of Congo',
      code: 'CD',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
      UNMembershipAdmissionDate: '20-09-77',
    },
    {
      name: 'Egypt',
      code: 'EG',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
      UNMembershipAdmissionDate: '1968-12-10T23:00:00.000Z',
    },
    {
      name: 'Eritrea',
      code: 'ER',
      UNMembershipAdmissionDate: '28-05-93',
    },
    {
      name: 'eSwatini',
      code: 'SZ',
      UNMembershipAdmissionDate: '24-09-68',
    },
    {
      name: 'Ethiopia',
      code: 'ET',
      UNMembershipAdmissionDate: '13-11-45',
    },
    {
      name: 'Gabon',
      code: 'GA',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Gambia',
      code: 'GM',
      UNMembershipAdmissionDate: '21-09-65',
    },
    {
      name: 'Ghana',
      code: 'GH',
      UNMembershipAdmissionDate: '1957-08-02T23:00:00.000Z',
    },
    {
      name: 'Guinea',
      code: 'GN',
      UNMembershipAdmissionDate: '1958-12-11T23:00:00.000Z',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
      UNMembershipAdmissionDate: '17-09-74',
    },
    {
      name: "Côte d'Ivoire",
      code: 'CI',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Kenya',
      code: 'KE',
      UNMembershipAdmissionDate: '16-12-63',
    },
    {
      name: 'Lesotho',
      code: 'LS',
      UNMembershipAdmissionDate: '17-10-66',
    },
    {
      name: 'Liberia',
      code: 'LR',
      UNMembershipAdmissionDate: '1945-02-10T23:00:00.000Z',
    },
    {
      name: 'Libya',
      code: 'LY',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Madagascar',
      code: 'MG',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Malawi',
      code: 'MW',
      UNMembershipAdmissionDate: '1964-01-11T23:00:00.000Z',
    },
    {
      name: 'Mali',
      code: 'ML',
      UNMembershipAdmissionDate: '28-09-60',
    },
    {
      name: 'Mauritania',
      code: 'MR',
      UNMembershipAdmissionDate: '27-10-61',
    },
    {
      name: 'Mauritius',
      code: 'MU',
      UNMembershipAdmissionDate: '24-04-68',
    },
    {
      name: 'Morocco',
      code: 'MA',
      UNMembershipAdmissionDate: '1956-12-10T23:00:00.000Z',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
      UNMembershipAdmissionDate: '16-09-75',
    },
    {
      name: 'Namibia',
      code: 'NA',
      UNMembershipAdmissionDate: '23-04-90',
    },
    {
      name: 'Niger',
      code: 'NE',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Nigeria',
      code: 'NG',
      UNMembershipAdmissionDate: '1960-07-09T23:00:00.000Z',
    },
    {
      name: 'Republic of Congo',
      code: 'CG',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Rwanda',
      code: 'RW',
      UNMembershipAdmissionDate: '18-09-62',
    },
    {
      name: 'Saint Helena',
      code: 'SH',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Sao Tome and Principe',
      code: 'ST',
      UNMembershipAdmissionDate: '16-09-75',
    },
    {
      name: 'Senegal',
      code: 'SN',
      UNMembershipAdmissionDate: '28-09-60',
    },
    {
      name: 'Seychelles',
      code: 'SC',
      UNMembershipAdmissionDate: '21-09-76',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
      UNMembershipAdmissionDate: '27-09-61',
    },
    {
      name: 'Somalia',
      code: 'SO',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'South Africa',
      code: 'ZA',
      UNMembershipAdmissionDate: '1945-07-10T22:00:00.000Z',
    },
    {
      name: 'South Sudan',
      code: 'SS',
      UNMembershipAdmissionDate: '14-07-11',
    },
    {
      name: 'Sudan',
      code: 'SD',
      UNMembershipAdmissionDate: '1956-12-10T23:00:00.000Z',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
      UNMembershipAdmissionDate: '14-12-61',
    },
    {
      name: 'Togo',
      code: 'TG',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Tunisia',
      code: 'TN',
      UNMembershipAdmissionDate: '1956-12-10T23:00:00.000Z',
    },
    {
      name: 'Uganda',
      code: 'UG',
      UNMembershipAdmissionDate: '25-10-62',
    },
    {
      name: 'Western Sahara',
      code: 'EH',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Zambia',
      code: 'ZM',
      UNMembershipAdmissionDate: '1964-01-11T23:00:00.000Z',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
      UNMembershipAdmissionDate: '25-08-80',
    },
  ],
  Oceania: [
    {
      name: 'American Samoa',
      code: 'AS',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Australia',
      code: 'AU',
      UNMembershipAdmissionDate: '1945-01-10T23:00:00.000Z',
    },
    {
      name: 'Cook Islands',
      code: 'CK',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Federated States of Micronesia',
      code: 'FM',
      UNMembershipAdmissionDate: '17-09-91',
    },
    {
      name: 'Fiji',
      code: 'FJ',
      UNMembershipAdmissionDate: '13-10-70',
    },
    {
      name: 'French Polynesia',
      code: 'PF',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Guam',
      code: 'GU',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Kiribati',
      code: 'KI',
      UNMembershipAdmissionDate: '14-09-99',
    },
    {
      name: 'Marshall Islands',
      code: 'MH',
      UNMembershipAdmissionDate: '17-09-91',
    },
    {
      name: 'Nauru',
      code: 'NR',
      UNMembershipAdmissionDate: '14-09-99',
    },
    {
      name: 'New Caledonia',
      code: 'NC',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'New Zealand',
      code: 'NZ',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Niue',
      code: 'NU',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Norfolk Island',
      code: 'NF',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Palau',
      code: 'PW',
      UNMembershipAdmissionDate: '15-12-94',
    },
    {
      name: 'Papua New Guinea',
      code: 'PG',
      UNMembershipAdmissionDate: '1975-10-09T23:00:00.000Z',
    },
    {
      name: 'Pitcairn Islands',
      code: 'PN',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Samoa',
      code: 'WS',
      UNMembershipAdmissionDate: '15-12-76',
    },
    {
      name: 'Solomon Islands',
      code: 'SB',
      UNMembershipAdmissionDate: '19-09-78',
    },
    {
      name: 'Tokelau',
      code: 'TK',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Tonga',
      code: 'TO',
      UNMembershipAdmissionDate: '14-09-99',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
      UNMembershipAdmissionDate: '2000-05-08T22:00:00.000Z',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
      UNMembershipAdmissionDate: '15-09-81',
    },
    {
      name: 'Wallis and Futuna',
      code: 'WF',
      UNMembershipAdmissionDate: null,
    },
  ],
  'Central America and the Caribbean': [
    {
      name: 'Anguilla',
      code: 'AI',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Antigua and Barbuda',
      code: 'AG',
      UNMembershipAdmissionDate: '1981-11-10T23:00:00.000Z',
    },
    {
      name: 'Aruba',
      code: 'AW',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Bahamas',
      code: 'BS',
      UNMembershipAdmissionDate: '18-09-73',
    },
    {
      name: 'Barbados',
      code: 'BB',
      UNMembershipAdmissionDate: '1966-09-11T23:00:00.000Z',
    },
    {
      name: 'Belize',
      code: 'BZ',
      UNMembershipAdmissionDate: '25-09-81',
    },
    {
      name: 'British Virgin Islands',
      code: 'VG',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Cayman Islands',
      code: 'KY',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Costa Rica',
      code: 'CR',
      UNMembershipAdmissionDate: '1945-02-10T23:00:00.000Z',
    },
    {
      name: 'Cuba',
      code: 'CU',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Curaçao',
      code: 'CW',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Dominica',
      code: 'DM',
      UNMembershipAdmissionDate: '18-12-78',
    },
    {
      name: 'Dominican Republic',
      code: 'DO',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'El Salvador',
      code: 'SV',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Grenada',
      code: 'GD',
      UNMembershipAdmissionDate: '17-09-74',
    },
    {
      name: 'Guatemala',
      code: 'GT',
      UNMembershipAdmissionDate: '21-11-45',
    },
    {
      name: 'Haiti',
      code: 'HT',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Honduras',
      code: 'HN',
      UNMembershipAdmissionDate: '17-12-45',
    },
    {
      name: 'Jamaica',
      code: 'JM',
      UNMembershipAdmissionDate: '18-09-62',
    },
    {
      name: 'Montserrat',
      code: 'MS',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Nicaragua',
      code: 'NI',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Panama',
      code: 'PA',
      UNMembershipAdmissionDate: '13-11-45',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Saint Barthelemy',
      code: 'BL',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Saint Kitts and Nevis',
      code: 'KN',
      UNMembershipAdmissionDate: '23-09-83',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
      UNMembershipAdmissionDate: '18-09-79',
    },
    {
      name: 'Saint Martin',
      code: 'MF',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Saint Vincent and the Grenadines',
      code: 'VC',
      UNMembershipAdmissionDate: '16-09-80',
    },
    {
      name: 'Sint Maarten',
      code: 'SX',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Trinidad and Tobago',
      code: 'TT',
      UNMembershipAdmissionDate: '18-09-62',
    },
    {
      name: 'Turks and Caicos Islands',
      code: 'TC',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'US Virgin Islands',
      code: 'VI',
      UNMembershipAdmissionDate: null,
    },
  ],
  'Antarctic Region': [
    {
      name: 'Antarctica',
      code: 'AQ',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Bouvet Island',
      code: 'BV',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Heard Island and McDonald Islands',
      code: 'HM',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'South Georgia and South Sandwich Islands',
      code: 'GS',
      UNMembershipAdmissionDate: null,
    },
  ],
  'South America': [
    {
      name: 'Argentina',
      code: 'AR',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Bolivia',
      code: 'BO',
      UNMembershipAdmissionDate: '14-11-45',
    },
    {
      name: 'Brazil',
      code: 'BR',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Chile',
      code: 'CL',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Colombia',
      code: 'CO',
      UNMembershipAdmissionDate: '1945-05-10T22:00:00.000Z',
    },
    {
      name: 'Ecuador',
      code: 'EC',
      UNMembershipAdmissionDate: '21-12-45',
    },
    {
      name: 'Falkland Islands',
      code: 'FK',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Guyana',
      code: 'GY',
      UNMembershipAdmissionDate: '20-09-66',
    },
    {
      name: 'Paraguay',
      code: 'PY',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Peru',
      code: 'PE',
      UNMembershipAdmissionDate: '31-10-45',
    },
    {
      name: 'Suriname',
      code: 'SR',
      UNMembershipAdmissionDate: '1975-04-11T23:00:00.000Z',
    },
    {
      name: 'Uruguay',
      code: 'UY',
      UNMembershipAdmissionDate: '18-12-45',
    },
    {
      name: 'Venezuela',
      code: 'VE',
      UNMembershipAdmissionDate: '15-11-45',
    },
  ],
  'Middle East': [
    {
      name: 'Bahrain',
      code: 'BH',
      UNMembershipAdmissionDate: '21-09-71',
    },
    {
      name: 'Cyprus',
      code: 'CY',
      UNMembershipAdmissionDate: '20-09-60',
    },
    {
      name: 'Iran',
      code: 'IR',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Iraq',
      code: 'IQ',
      UNMembershipAdmissionDate: '21-12-45',
    },
    {
      name: 'Israel',
      code: 'IL',
      UNMembershipAdmissionDate: '1949-11-04T23:00:00.000Z',
    },
    {
      name: 'Jordan',
      code: 'JO',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Kuwait',
      code: 'KW',
      UNMembershipAdmissionDate: '14-05-63',
    },
    {
      name: 'Lebanon',
      code: 'LB',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Oman',
      code: 'OM',
      UNMembershipAdmissionDate: '1971-07-09T23:00:00.000Z',
    },
    {
      name: 'Palestinian Territories',
      code: 'PS',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Qatar',
      code: 'QA',
      UNMembershipAdmissionDate: '21-09-71',
    },
    {
      name: 'Saudi Arabia',
      code: 'SA',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Syria',
      code: 'SY',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Turkey',
      code: 'TR',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'United Arab Emirates',
      code: 'AE',
      UNMembershipAdmissionDate: '1971-09-11T23:00:00.000Z',
    },
    {
      name: 'Yemen',
      code: 'YE',
      UNMembershipAdmissionDate: '30-09-47',
    },
  ],
  'North America': [
    {
      name: 'Bermuda',
      code: 'BM',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Canada',
      code: 'CA',
      UNMembershipAdmissionDate: '1945-09-10T22:00:00.000Z',
    },
    {
      name: 'Mexico',
      code: 'MX',
      UNMembershipAdmissionDate: '1945-07-10T22:00:00.000Z',
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'United States',
      code: 'US',
      UNMembershipAdmissionDate: '24-10-45',
    },
  ],
  'Political Map of the World': [
    {
      name: 'British Indian Ocean Territory',
      code: 'IO',
      UNMembershipAdmissionDate: null,
    },
  ],
  'Southeast Asia': [
    {
      name: 'Brunei',
      code: 'BN',
      UNMembershipAdmissionDate: '21-09-84',
    },
    {
      name: 'Cambodia',
      code: 'KH',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Christmas Island',
      code: 'CX',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Cocos (Keeling) Islands',
      code: 'CC',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Timor-Leste',
      code: 'TL',
      UNMembershipAdmissionDate: '27-09-02',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Indonesia',
      code: 'ID',
      UNMembershipAdmissionDate: '28-09-50',
    },
    {
      name: "Lao People's Democratic Republic",
      code: 'LA',
      UNMembershipAdmissionDate: '14-12-55',
    },
    {
      name: 'Macau',
      code: 'MO',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Malaysia',
      code: 'MY',
      UNMembershipAdmissionDate: '17-09-57',
    },
    {
      name: 'Myanmar',
      code: 'MM',
      UNMembershipAdmissionDate: '19-04-48',
    },
    {
      name: 'Philippines',
      code: 'PH',
      UNMembershipAdmissionDate: '24-10-45',
    },
    {
      name: 'Singapore',
      code: 'SG',
      UNMembershipAdmissionDate: '21-09-65',
    },
    {
      name: 'Taiwan',
      code: 'TW',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Thailand',
      code: 'TH',
      UNMembershipAdmissionDate: '16-12-46',
    },
    {
      name: 'Vietnam',
      code: 'VN',
      UNMembershipAdmissionDate: '20-09-77',
    },
  ],
  'Arctic Region': [
    {
      name: 'Greenland',
      code: 'GL',
      UNMembershipAdmissionDate: null,
    },
    {
      name: 'Iceland',
      code: 'IS',
      UNMembershipAdmissionDate: '19-11-46',
    },
  ],
  AsiaEurope: [
    {
      name: 'Ukraine',
      code: 'UA',
      // flagSVG: 'https://restcountries.eu/data/ukr.svg',
      UNMembershipAdmissionDate: '24-10-45',
    },
  ],
};

export const initialKeyAndTableData = {
  keyPath: '',
  countriesListToMap: initialCountriesListToMap,
  keyTitle: '',
  tableData: [],
};

export const countryAudioUrlsHash = { // client TODO: temp - move to own server
  Yemen: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/YM.mp3?1538604761',
  'Wallis and Futuna': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/WF.mp3?1538604761',
  Vietnam: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/VM.mp3?1538604761',
  Venezuela: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/VE.mp3?1538604761',
  'Vatican City': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/VT.mp3?1538604754',
  Vanuatu: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/NH.mp3?1538604761',
  Uzbekistan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/UZ.mp3?1538604761',
  Uruguay: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/UY.mp3?1538604761',
  'US Virgin Islands': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/VQ.mp3?1538604761',
  'United States': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/US.mp3?1538604761',
  'United Kingdom': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/UK.mp3?1538604761',
  'United Arab Emirates': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AE.mp3?1538604761',
  Ukraine: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/UP.mp3?1538604761',
  Turkmenistan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TX.mp3?1538604761',
  Turkey: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TU.mp3?1538604761',
  Tunisia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TS.mp3?1538604760',
  'Trinidad and Tobago': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TD.mp3?1538604760',
  Togo: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TO.mp3?1538604760',
  Thailand: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TH.mp3?1538604760',
  Tanzania: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TZ.mp3?1538604760',
  Tajikistan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TI.mp3?1538604760',
  Syria: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SY.mp3?1538604760',
  Switzerland: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SZ.mp3?1538604760',
  Sweden: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SW.mp3?1538604760',
  Sudan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SU.mp3?1538604760',
  'Sri Lanka': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CE.mp3?1538604760',
  Spain: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SP.mp3?1538604760',
  'South Korea': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/KS.mp3?1538604755',
  'South Africa': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SF.mp3?1538604759',
  Slovenia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SI.mp3?1538604759',
  Slovakia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/LO.mp3?1538604759',
  Singapore: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SN.mp3?1538604759',
  'Sierra Leone': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SL.mp3?1538604759',
  Seychelles: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SE.mp3?1538604759',
  Serbia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/RI.mp3?1538604759',
  Senegal: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SG.mp3?1538604759',
  'Saudi Arabia': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SA.mp3?1538604759',
  'Sao Tome and Principe': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TP.mp3?1538604759',
  'Saint Pierre and Miquelon': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SB.mp3?1538604759',
  'Saint Kitts and Nevis': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SC.mp3?1538604759',
  'Saint Helena': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/SH.mp3?1538604759',
  Rwanda: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/RW.mp3?1538604759',
  Russia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/RS.mp3?1538604759',
  Romania: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/RO.mp3?1538604759',
  'Republic of Congo': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CF.mp3?1538604752',
  Qatar: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/QA.mp3?1538604758',
  'Puerto Rico': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/RQ.mp3?1538604758',
  Portugal: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/PO.mp3?1538604758',
  Poland: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/PL.mp3?1538604758',
  Philippines: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/RP.mp3?1538604758',
  Peru: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/PE.mp3?1538604758',
  Paraguay: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/PA.mp3?1538604758',
  'Papua New Guinea': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/PP.mp3?1538604758',
  Panama: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/PM.mp3?1538604758',
  Palau: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/PS.mp3?1538604758',
  Pakistan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/PK.mp3?1538604758',
  Oman: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MU.mp3?1538604758',
  Norway: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/NO.mp3?1538604758',
  'Northern Mariana Islands': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CQ.mp3?1538604758',
  'North Macedonia': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MK.mp3?1538604756',
  Nigeria: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/NI.mp3?1538604757',
  Nicaragua: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/NU.mp3?1538604757',
  'New Zealand': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/NZ.mp3?1538604757',
  Netherlands: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/NL.mp3?1538604757',
  Nepal: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/NP.mp3?1538604757',
  Namibia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/WA.mp3?1538604757',
  Myanmar: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BM.mp3?1538604751',
  Mozambique: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MZ.mp3?1538604757',
  Morocco: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MO.mp3?1538604757',
  Montserrat: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MH.mp3?1538604757',
  Montenegro: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MJ.mp3?1538604757',
  Monaco: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MN.mp3?1538604757',
  Moldova: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MD.mp3?1538604757',
  Mexico: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MX.mp3?1538604757',
  Mauritius: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MP.mp3?1538604757',
  Mauritania: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MR.mp3?1538604756',
  'Marshall Islands': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/RM.mp3?1538604756',
  Malta: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MT.mp3?1538604756',
  Maldives: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MV.mp3?1538604756',
  Malaysia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MY.mp3?1538604756',
  Malawi: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MI.mp3?1538604756',
  Madagascar: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MA.mp3?1538604756',
  Macau: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/MC.mp3?1538604756',
  Luxembourg: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/LU.mp3?1538604756',
  Lithuania: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/LH.mp3?1538604756',
  Liechtenstein: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/LS.mp3?1538604756',
  Liberia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/LI.mp3?1538604756',
  Lebanon: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/LE.mp3?1538604756',
  Latvia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/LG.mp3?1538604756',
  "Lao People's Democratic Republic": 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/LA.mp3?1538604756',
  Kyrgyzstan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/KG.mp3?1538604755',
  Kuwait: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/KU.mp3?1538604755',
  Kosovo: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/KV.mp3?1538604755',
  Kenya: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/KE.mp3?1538604755',
  Kazakhstan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/KZ.mp3?1538604755',
  Jordan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/JO.mp3?1538604755',
  Japan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/JA.mp3?1538604755',
  Jamaica: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/JM.mp3?1538604755',
  "Côte d'Ivoire": 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/IV.mp3?1538604752',
  Italy: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/IT.mp3?1538604755',
  Israel: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/IS.mp3?1538604755',
  Ireland: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/EI.mp3?1538604755',
  Iraq: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/IZ.mp3?1538604755',
  Indonesia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/ID.mp3?1538604755',
  India: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/IN.mp3?1538604755',
  Iceland: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/IC.mp3?1538604754',
  Hungary: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/HU.mp3?1538604754',
  'Hong Kong': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/HK.mp3?1538604754',
  Honduras: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/HO.mp3?1538604754',
  Haiti: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/HA.mp3?1538604754',
  Guyana: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/GY.mp3?1538604754',
  'Guinea-Bissau': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/PU.mp3?1538604754',
  Guinea: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/GV.mp3?1538604754',
  Guatemala: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/GT.mp3?1538604754',
  Greece: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/GR.mp3?1538604754',
  Ghana: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/GH.mp3?1538604754',
  Germany: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/GM.mp3?1538604754',
  Georgia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/GG.mp3?1538604754',
  Gambia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/GA.mp3?1538604753',
  Gabon: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/GB.mp3?1538604753',
  France: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/FR.mp3?1538604753',
  Finland: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/FI.mp3?1538604753',
  Fiji: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/FJ.mp3?1538604753',
  'Federated States of Micronesia': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/FM.mp3?1538604757',
  Ethiopia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/ET.mp3?1538604753',
  eSwatini: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/WZ.mp3?1538604760',
  Estonia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/EN.mp3?1538604753',
  Eritrea: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/ER.mp3?1538604753',
  'El Salvador': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/ES.mp3?1538604753',
  Egypt: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/EG.mp3?1538604752',
  Ecuador: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/EC.mp3?1538604752',
  'Timor-Leste': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/TT.mp3?1538604760',
  'Dominican Republic': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/DR.mp3?1538604752',
  Dominica: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/DO.mp3?1538604752',
  Djibouti: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/DJ.mp3?1538604752',
  Denmark: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/DA.mp3?1538604752',
  Czechia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/EZ.mp3?1538604752',
  Cuba: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CU.mp3?1538604752',
  Croatia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/HR.mp3?1538604752',
  'Costa Rica': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CS.mp3?1538604752',
  'Cook Islands': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CW.mp3?1538604752',
  Comoros: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CN.mp3?1538604751',
  Colombia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CO.mp3?1538604751',
  'Cocos (Keeling) Islands': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CK.mp3?1538604751',
  'Christmas Island': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/KT.mp3?1538604751',
  China: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CH.mp3?1538604751',
  Chile: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CI.mp3?1538604751',
  'Central African Republic': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CT.mp3?1538604751',
  'Cape Verde': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CV.mp3?1538604751',
  Canada: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CA.mp3?1538604751',
  Cameroon: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CM.mp3?1538604751',
  Cambodia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/CB.mp3?1538604751',
  'Burkina Faso': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/UV.mp3?1538604751',
  Bulgaria: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BU.mp3?1538604751',
  Brunei: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BX.mp3?1538604750',
  'British Virgin Islands': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/VI.mp3?1538604750',
  Brazil: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BR.mp3?1538604750',
  Botswana: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BC.mp3?1538604750',
  'Bosnia and Herzegovina': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BK.mp3?1538604750',
  Bolivia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BL.mp3?1538604750',
  Benin: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BN.mp3?1538604750',
  Belize: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BH.mp3?1538604750',
  Belgium: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BE.mp3?1538604750',
  Belarus: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BO.mp3?1538604750',
  Barbados: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BB.mp3?1538604750',
  Bangladesh: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BG.mp3?1538604750',
  Bahrain: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BA.mp3?1538604750',
  Bahamas: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/BF.mp3?1538604749',
  Azerbaijan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AJ.mp3?1538604749',
  Austria: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AU.mp3?1538604749',
  Australia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AS.mp3?1538604749',
  Aruba: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AA.mp3?1538604749',
  Armenia: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AM.mp3?1538604749',
  Argentina: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AR.mp3?1538604749',
  'Antigua and Barbuda': 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AC.mp3?1538604749',
  Angola: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AO.mp3?1538604749',
  Algeria: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AG.mp3?1538604749',
  Albania: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AL.mp3?1538604749',
  Afghanistan: 'https://www.cia.gov/library/publications/the-world-factbook/attachments/audios/original/AF.mp3?1538604748',
};
