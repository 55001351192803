import styled from 'styled-components/macro';
import { Select } from 'antd';

import { sharedStyles, COLORS } from '../../../routes/App/App.styles';

export const { Option, OptGroup } = Select;

export const PrivateSelect = styled(Select)`
  width: 100%;
  height: 100%;
  border: 1px solid ${COLORS.border};
  border-radius: 5px;

  && .ant-select-selector {
    height: 100%;
    .ant-select-selection-placeholder{
      ${sharedStyles.fontStyleMedium}
      font-size: 14px;
      margin: auto;
      font-weight: 500;
      color: ${COLORS.lightGrey};
    }

    // input element
    .ant-select-selection-search input {
      line-height: 58px;
      vertical-align: -webkit-baseline-middle;
    }
    
    // ?
    && .ant-select-selection-item {
    }
  }

  .ant-select-arrow {
    color: ${COLORS.darkGrey};
    z-index: 2;
  }

`;
const PrivateSelectRefContainer = styled.span`
    // dropdown div
    .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft { 
      border-radius: 15px;
      background: ${COLORS.white} !important;
    }

    // inner dropdown div
    .rc-virtual-list-holder-inner { 
      color: white;
    }

    // list item - hover
    .ant-select-item.ant-select-item-option.ant-select-item-option-grouped.ant-select-item-option-active {
      background: ${COLORS.lightBG} !important;
    }

    // list item - selected
    .ant-select-item.ant-select-item-option.ant-select-item-option-grouped.ant-select-item-option-selected {
      background: ${COLORS.lightBG} !important;
    }
`;

export default {
  PrivateSelectRefContainer,
};
