import styled from 'styled-components/macro';
import { sharedStyles, COLORS } from '../../routes/App/App.styles';

const ListItem = styled.span`
  ${sharedStyles.fontStyleMedium}
  font-size: 16px;
  color: ${COLORS.darkGrey};
  font-weight: 500;
  height: 100%;
`;

export default {
  ListItem,
};
