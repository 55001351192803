import styled from 'styled-components/macro';
import { sharedStyles, COLORS, dimensions } from '../../routes/App/App.styles';

const Nav = styled.nav`
  width: 100%;
  height: ${dimensions.navbarHeight}px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${COLORS.border};
`;

export const StyledColLogo = styled.div`
  flex: 1;
  font-size: x-large;
  margin: auto;
  height: 39px;
`;

const StyledImg = styled.img`
  height: 100%;
  margin-right: 5px;
  
  &[alt=name] {
    @media (max-width: 550px) {
      display: none;
    }
  }
`;

const LogoName = styled.span`
  ${sharedStyles.fontStyleBold}
  font-size: 25px;
  letter-spacing: 1px;
  line-height: 58px;
  color: ${COLORS.darkGrey};
  text-align: right;
`;

const NavTab = styled.span`
  ${sharedStyles.fontStyleSemiBold}
  font-size: 17px;
  margin-left: 25px;
  letter-spacing: 0px;
  line-height: 58px;
  color: ${COLORS.darkGrey};
  text-align: right;
`;

export default {
  StyledImg,
  Nav,
  LogoName,
  NavTab,
};
