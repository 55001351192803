// Modules
import React, { useContext, useCallback, useState } from 'react';
import { isNumber } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import Reel from 'react-reel';

// Internals
import { MyContext } from '../../../context/myContext';
import { addCommasToNumber, myStartCase } from '../../../internals/utils';
import { useBreakpoint, BREAKPOINTS } from '../../../context/mediaQueriesHelpers';

// Components
import Tooltip from '../Tooltip';

// Styles
import Styles, { theme, selectedTheme } from './KeyValueBody.styles';

function KeyValue({
  tab,
  name,
  value,
  valueName,
  units,
  // rank,
  date = '',
  male,
  female,
  tooltip,
  source = ''
}) {
  const breakPoint = useBreakpoint();
  const history = useHistory();
  const { _setKey } = useContext(MyContext);

  const [isKeyHover, setIsKeyHover] = useState(false);
  const [isValueHover, setIsValueHover] = useState(false);

  const __setKey = useCallback(() => {
    const keyPath = `${tab}.${name}`;
    if (breakPoint[BREAKPOINTS.PHONE] || breakPoint[BREAKPOINTS.TABLET]) {
      history.push(`/list/?key=${keyPath}`);
    } else {
      _setKey(keyPath);
    }
  }, [tab, name, _setKey, history, breakPoint]);

  const nameStartCase = myStartCase(name);
  let unitsStartCase = myStartCase(units);
  const valueWithCommas = addCommasToNumber(value);
  unitsStartCase = unitsStartCase?.replace('Percent',`%`);

  if (breakPoint[BREAKPOINTS.PHONE]) {
    unitsStartCase = unitsStartCase?.replace('Per',`/`);
    unitsStartCase = unitsStartCase?.replace('Population','p');
  }

  if (name) {
    if (!male) {
      // eslint-disable-next-line no-nested-ternary 
      const newValue = value
        ? isNumber(value)
          ? valueWithCommas
          : myStartCase(value)
        : '-';

      return (
        <Styles.Wrapper>
          <Styles.KeyWrapper
            onMouseOver={ () => setIsKeyHover(true) }
            onFocus={ () => setIsKeyHover(true) }
            onMouseOut={ () => setIsKeyHover(false) }
            onBlur={ () => setIsKeyHover(false) }
            isValueHover={ isValueHover }
          >
            <Tooltip title={ tooltip }>
              {/* <> */}
                { tab
                  ? <span style={{cursor: 'point'}} onClick={ __setKey }>{ nameStartCase }</span>
                  : nameStartCase }
              {/* </> */}
            </Tooltip>
            {/* { date && ` (${date})` }:&nbsp; */ }
          </Styles.KeyWrapper>
          <Styles.ValueWrapper
            onMouseOver={ () => setIsValueHover(true) }
            onFocus={ () => setIsValueHover(true) }
            onMouseOut={ () => setIsValueHover(false) }
            onBlur={ () => setIsValueHover(false) }
            isKeyHover={ isKeyHover }
          >
            {
              isNumber(value)
                ? (
                  <Styles.ReelWrapper>
                    <Reel text={ newValue } theme={ isKeyHover ? selectedTheme : theme } />
                  </Styles.ReelWrapper>
                )
                : <span>{ newValue }</span>
            }
            <span>
              { value && units ? ` ${unitsStartCase}` : '' }
              {/* { rank && ` (#${rank})` } */ }
              { valueName && ` (${valueName})` }
              { (source || date) && (
                <Tooltip placement='top' title={`${date}${source && date && ' - '}${source && 'source'}`}>
                  {source ? (
                    <a target="_blank" rel="noopener noreferrer" href={ source }>
                      <sup style={{cursor: 'point'}}>i</sup>
                    </a>
                  ) : (<sup style={{cursor: 'help'}}>i</sup>)}
                </Tooltip>
              ) }
            </span>
          </Styles.ValueWrapper>
        </Styles.Wrapper>
      );
    }

    return (
      <Styles.Wrapper>
        <Styles.KeyWrapper
          onMouseOver={ () => setIsKeyHover(true) }
          onFocus={ () => setIsKeyHover(true) }
          onMouseOut={ () => setIsKeyHover(false) }
          onBlur={ () => setIsKeyHover(false) }
          isValueHover={ isValueHover }
        >
          <Tooltip title={ tooltip } >
            { tab
              ? <span onClick={ __setKey }>{ nameStartCase }</span>
              : nameStartCase }
            {/* { date && ` (${date})` }:&nbsp; */ }
          </Tooltip>
        </Styles.KeyWrapper>
        <Styles.ValueWrapper
          onMouseOver={ () => setIsValueHover(true) }
          onFocus={ () => setIsValueHover(true) }
          onMouseOut={ () => setIsValueHover(false) }
          onBlur={ () => setIsValueHover(false) }
          isKeyHover={ isKeyHover }
        >
          {
            value && isNumber(value)
              ? (
                <Styles.ReelWrapper>
                  <Reel text={ valueWithCommas } theme={ isKeyHover ? selectedTheme : theme } />
                </Styles.ReelWrapper>
              )
              : value
          }
          { value && units ? ` ${unitsStartCase} ` : ' ' }

        { !breakPoint[BREAKPOINTS.PHONE] && ( // We don't show male-female on mobile view
          <>
          {'(♂ - '}
          <Styles.ReelWrapper>
            <Reel 
              text={ `${male}` }
              theme={ isKeyHover ? selectedTheme : theme }
            />
          </Styles.ReelWrapper>
          { units
            ? ` ${unitsStartCase}`
            : ''
          }
          {'♀ - '}
          <Styles.ReelWrapper>
            <Reel
              text={ `${female}` }
              theme={ isKeyHover ? selectedTheme : theme }
            />
          </Styles.ReelWrapper>
          { units
            ? ` ${unitsStartCase}` 
            : ''
          }
          {')'}
          </>
        )}
          </Styles.ValueWrapper>
      </Styles.Wrapper>
    );
  }
}

export default React.memo(KeyValue);
