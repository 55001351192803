import React from 'react';
import { Helmet } from 'react-helmet';

import { CONSTANTS } from '../../internals/constants';

function MainHeader({ keyTitle }) {
  return (
    <Helmet>
      <title>{ `${CONSTANTS.LIST_TITLE} ${keyTitle}` }</title>
      <meta
        name="description"
        content={ `${CONSTANTS.LIST_DESCRIPTION} ${keyTitle}` }
      />
    </Helmet>
  );
}

export default MainHeader;
