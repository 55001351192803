/* eslint-disable max-len, camelcase */

// Modules
import React from "react";
import { isNumber } from "lodash-es";

// Internals
import { CATEGORIES } from "../../internals/constants";
import { addCommasToNumber } from "../../internals/utils";

// Components
import NoDataOnCountry from "./NoDataOnCountry";
import KeyValue from "../common/KeyValue";
import Notes from "../common/Notes";
import PieChart from "../common/PieChart";
import { showMissingValues } from "../../internals/utils";

// Styles
import Styles from "./Tab.styles";

function People({ data }) {
  if (!data || Object.keys(data).length === 0) {
    return <NoDataOnCountry />;
  }
  showMissingValues(data);

  const renderLanguages = () => {
    let languages;
    let LanguagesData;
    if (data.languages?.language && data.languages.language.length > 0) {
      const isAllLangWithPercent = data.languages.language.every(
        (lan) => typeof lan.percent === "number"
      );

      if (isAllLangWithPercent) {
        languages = data.languages.language.reduce(
          (acc, lan) => {
            acc.series.push(lan.percent);
            acc.labels.push(
              lan.note && lan.note.includes("official")
                ? `${lan.name} (official)`
                : lan.name
            );

            return acc;
          },
          {
            series: [],
            labels: [],
          }
        );

        LanguagesData = () => (
          <PieChart
            series={languages.series}
            labels={languages.labels}
            suffix="%"
          />
        );
      } else {
        LanguagesData = () => (
          <ul>
            {data.languages.language.map((lan, index) => (
              <KeyValue
                key={index}
                listItem
                name={lan.name}
                value={lan.percent}
                rank={lan.note}
              />
            ))}
          </ul>
        );
      }
    }

    return (
      <>
        <Styles.Title>
          Languages {data?.languages?.date && `(${data?.languages?.date})`}
        </Styles.Title>
        {data.languages?.language && data.languages.language.length > 0 && (
          <LanguagesData />
        )}
      </>
    );
  };

  const renderMajorAreas = () => {
    let majorUrbanAreas;
    if (
      data.major_urban_areas?.places &&
      data.major_urban_areas.places.length > 0
    ) {
      const isAllLangWithPercent = data.major_urban_areas.places.every(
        (place) => isNumber(place.population)
      );

      if (isAllLangWithPercent) {
        majorUrbanAreas = data.major_urban_areas.places.reduce(
          (acc, place) => {
            acc.series.push(place.population);
            acc.labels.push(
              place.note && place.note.includes("capital")
                ? `${place.place} (official)`
                : place.place
            );

            return acc;
          },
          {
            series: [],
            labels: [],
          }
        );

        return (
          <>
            <Styles.Title>
              Major Areas{" "}
              {data?.major_urban_areas?.date &&
                `(${data?.major_urban_areas?.date})`}
            </Styles.Title>
            <PieChart
              series={majorUrbanAreas.series}
              labels={majorUrbanAreas.labels.map((i) => addCommasToNumber(i))}
              suffix=" people"
            />
          </>
        );
      }

      console.log("-------> major_urban_areas.places is corrupted <-------");
    }

    return "";
  };

  const renderEthnicGroups = () => {
    let EthnicData;
    let majorUrbanAreas;
    if (data.ethnic_groups?.ethnicity) {
      const isAllLangWithPercent = data.ethnic_groups?.ethnicity.every((type) =>
        isNumber(type.percent)
      );

      if (isAllLangWithPercent) {
        majorUrbanAreas = data.ethnic_groups?.ethnicity.reduce(
          (acc, type) => {
            acc.series.push(type.percent);
            acc.labels.push(type.name);

            return acc;
          },
          {
            series: [],
            labels: [],
          }
        );
        EthnicData = () => (
          <PieChart
            series={majorUrbanAreas.series}
            labels={majorUrbanAreas.labels}
            suffix="%"
          />
        );
      } else {
        EthnicData = () => (
          <ul>
            {data.ethnic_groups?.ethnicity.map((type, index) => (
              <KeyValue
                listItem
                key={index}
                name={type.name}
                value={type.percent}
                units="%"
              />
            ))}
          </ul>
        );
      }
    }

    return (
      <>
        <Styles.Title>
          Ethnic Groups{" "}
          {data?.ethnic_groups?.date && `(${data?.ethnic_groups?.date})`}
        </Styles.Title>
        {data.ethnic_groups?.ethnicity && <EthnicData />}
        <Notes note={data.ethnic_groups?.note} />
      </>
    );
  };

  const renderReligions = () => {
    let religions;
    let ReligionsData;
    if (data.religions?.religion) {
      const isAllLangWithPercent = data.religions?.religion.every((type) =>
        isNumber(type.percent)
      );

      if (isAllLangWithPercent) {
        religions = data.religions?.religion.reduce(
          (acc, type) => {
            acc.series.push(type.percent);
            acc.labels.push(type.name);

            return acc;
          },
          {
            series: [],
            labels: [],
          }
        );

        ReligionsData = () => (
          <PieChart
            series={religions.series}
            labels={religions.labels}
            suffix="%"
          />
        );
      } else {
        ReligionsData = () => (
          <ul>
            {data.religions?.religion.map((type, index) => (
              <KeyValue
                key={index}
                listItem
                name={type.name}
                value={type.percent}
                units="%"
              />
            ))}
          </ul>
        );
      }
    }

    return (
      <>
        <Styles.Title>
          Religions {data.religions?.date && `(${data.religions?.date})`}
        </Styles.Title>
        {data.religions?.religion && <ReligionsData />}
        <Notes note={data.religions?.note} />
      </>
    );
  };

  return (
    <div>
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="nationality"
        value={data.nationality?.adjective}
      />

      <Styles.Title>Demographics</Styles.Title>
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="population"
        date={data.population?.date}
        value={data.population?.total}
        rank={data.population?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="population_growth_rate"
        date={data.population_growth_rate?.date}
        units="%"
        value={data.population_growth_rate?.growth_rate}
        rank={data.population_growth_rate?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="birth_rate"
        date={data.birth_rate?.date}
        value={data.birth_rate?.births_per_1000_population}
        units="births_per_1000_population"
        rank={data.birth_rate?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="total_fertility_rate"
        date={data.total_fertility_rate?.date}
        value={data.total_fertility_rate?.children_born_per_woman}
        units="children_born_per_woman"
        rank={data.total_fertility_rate?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="contraceptive_prevalence_rate"
        date={data.contraceptive_prevalence_rate?.date}
        value={data.contraceptive_prevalence_rate?.value}
        units={data.contraceptive_prevalence_rate?.units}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="infant_mortality_rate"
        date={data.infant_mortality_rate?.date}
        value={data.infant_mortality_rate?.total?.value}
        male={data.infant_mortality_rate?.male?.value}
        female={data.infant_mortality_rate?.female?.value}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="net_migration_rate"
        value={data.net_migration_rate?.migrants_per_1000_population}
        units="migrants_per_1000_population"
        rank={data.net_migration_rate?.global_rank}
      />

      <Styles.Title>Health</Styles.Title>
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="hiv_aids.adult_prevalence_rate"
        date={data.hiv_aids?.adult_prevalence_rate?.date}
        value={data.hiv_aids?.adult_prevalence_rate?.percent_of_adults}
        units="%"
        rank={data.hiv_aids?.adult_prevalence_rate?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="adult_obesity"
        date={data.adult_obesity?.date}
        value={data.adult_obesity?.percent_of_adults}
        units="%"
        rank={data.adult_obesity?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="physicians_density"
        date={data.physicians_density?.date}
        units="physicians_per_1000_population"
        value={data.physicians_density?.physicians_per_1000_population}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="death_rate"
        date={data.death_rate?.date}
        value={data.death_rate?.deaths_per_1000_population}
        units="deaths_per_1000_population"
        rank={data.death_rate?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="maternal_mortality_rate"
        date={data.maternal_mortality_rate?.date}
        value={data.maternal_mortality_rate?.deaths_per_100k_live_births}
        units="deaths_per_100k_live_births"
        rank={data.maternal_mortality_rate?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="hospital_bed_density"
        date={data.hospital_bed_density?.date}
        value={data.hospital_bed_density?.beds_per_1000_population}
        units="beds_per_1000_population"
        rank=""
      />

      <Styles.Title>General</Styles.Title>
      <KeyValue
        tab={`${CATEGORIES.PEOPLE}.urbanization`}
        name="urban_population"
        date={data.urbanization?.urban_population?.date}
        value={data.urbanization?.urban_population?.value}
        units={data.urbanization?.urban_population?.units}
        rank=""
      />
      <KeyValue
        tab={`${CATEGORIES.PEOPLE}.urbanization`}
        name="rate_of_urbanization"
        date=""
        value={data.urbanization?.rate_of_urbanization?.value}
        units={data.urbanization?.rate_of_urbanization?.units}
        rank=""
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="education_expenditures"
        date={data.education_expenditures?.date}
        value={data.education_expenditures?.percent_of_gdp}
        units="percent_of_gdp"
        rank={data.education_expenditures?.global_rank}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="literacy"
        tooltip={data.literacy?.definition}
        rank={data.literacy?.definition}
        units={data.literacy?.male?.units}
        date=""
        value={data.literacy?.total_population.value}
        male={data.literacy?.male?.value}
        female={data.literacy?.female?.value}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="school_life_expectancy"
        date={data.school_life_expectancy?.date}
        value={data.school_life_expectancy?.total?.value}
        male={data.school_life_expectancy?.male?.value}
        female={data.school_life_expectancy?.female?.value}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="youth_unemployment"
        date={data.youth_unemployment?.date}
        value={data.youth_unemployment?.total?.value}
        units="%"
        male={data.youth_unemployment?.male?.value}
        female={data.youth_unemployment?.female?.value}
      />

      <Styles.Title>Ages</Styles.Title>
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="life_expectancy_at_birth"
        date={data.life_expectancy_at_birth?.date}
        value={data.life_expectancy_at_birth?.total_population?.value}
        male={data.life_expectancy_at_birth?.male?.value}
        female={data.life_expectancy_at_birth?.female?.value}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="mothers_mean_age_at_first_birth"
        date={data.mothers_mean_age_at_first_birth?.date}
        value={data.mothers_mean_age_at_first_birth?.age}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="median_age"
        date={data.median_age?.date}
        value={data.median_age?.total?.value}
        male={data.median_age?.male?.value}
        female={data.median_age?.female?.value}
      />
      <KeyValue
        tab={CATEGORIES.PEOPLE}
        name="age_structure"
        date={data.age_structure?.date}
      />
      {data.age_structure && (
        <PieChart
          series={[
            data.age_structure["0_to_14"]?.percent,
            data.age_structure["15_to_24"]?.percent,
            data.age_structure["25_to_54"]?.percent,
            data.age_structure["55_to_64"]?.percent,
            data.age_structure["65_and_over"]?.percent,
          ]}
          labels={["0-14", "15-24", "25-54", "55-64", "65 and over"]}
          suffix="%"
        />
      )}
      <br />

      {renderLanguages()}
      <br />

      {renderMajorAreas()}
      <br />

      {renderEthnicGroups()}
      <br />
      <br />

      {renderReligions()}
    </div>
  );
}

export default People;
