import ACTION_TYPES from '../actionTypes';

const initialState = {
  country: {
    AMChartsName: '',
    AMChartsCode: '',
    data: {},
  },
  tab: '',
  key: {
    keyPath: '',
    keyTitle: '',
  },
  countriesListToMap: [],
  tableData: [],
  // isTooltipHidden: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_COUNTRY:
      return {
        ...state,
        country: {
          AMChartsName: action.payload?.General?.AMChartsName,
          AMChartsCode: action.payload?.General?.AMChartsCode,
          data: action.payload,
        },
      };
    case ACTION_TYPES.INIT_COUNTRY:
      return {
        ...state,
        country: initialState.country,
      };

    case ACTION_TYPES.UPDATE_TAB:
      return {
        ...state,
        tab: action.payload,
      };

    case ACTION_TYPES.UPDATE_KEY: {
      const {
        keyPath,
        keyTitle,
        countriesListToMap,
        tableData,
      } = action.payload;

      return {
        ...state,
        key: { keyPath, keyTitle },
        countriesListToMap,
        tableData,
      };
    }
    case ACTION_TYPES.INIT_KEY:
      return {
        ...state,
        key: initialState.key,
        countriesListToMap: [],
        tableData: initialState.tableData,
      };

    default:
      return state;
  }
}

export default reducer;
