/* eslint-disable */
// Modules 
import React from 'react';
import { Link } from 'react-router-dom';

// Internals
import facebookIcon from '../../assets/icons/facebook.png';
import gmailIcon from '../../assets/icons/gmail.png';
import instagramIcon from '../../assets/icons/instagram.png';
import twitterIcon from '../../assets/icons/twitter.png';
import text from '../../assets/logo/text.svg';

// Styles
import Styles, { StyledColLogo } from './Footer.styles';


const FooterBar = () => {
  return (
    <Styles.Wrapper>
      <span>{`© ${new Date().getFullYear()} COUNTRYOPEDIA - All rights reserved`}</span>
      <span>
        <Styles.link href="https://www.instagram.com/countryopedia/">
          <Styles.StyledImg alt="instagram" src={ instagramIcon } />
        </Styles.link>
        <Styles.link href="https://twitter.com/countryopedia">
          <Styles.StyledImg alt="twitter" src={ twitterIcon } />
        </Styles.link>
        <Styles.link href="https://www.facebook.com/Countryopedia-113320473907840">
          <Styles.StyledImg alt="facebook" src={ facebookIcon } />
        </Styles.link>
        <Link to="/contact">
          <Styles.StyledImg alt="contact" src={ gmailIcon } />
          </Link>
      </span>
    </Styles.Wrapper>
  );
};

export default FooterBar;
