import React, { useState, useEffect } from "react";
import { IoIosArrowDropupCircle } from "react-icons/io";
import styled from "styled-components/macro";
import {
  BREAKPOINTS,
  useBreakpoint,
} from "../../../context/mediaQueriesHelpers";

const StyledIoIosArrowDropupCircle = styled(IoIosArrowDropupCircle)`
  position: fixed;
  width: 100%;
  bottom: 60px;
  height: 50px;
  z-index: 1000;
  cursor: pointer;
  transition: all 1s ease;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;

const ScrollTopArrow = () => {
  const [showScroll, setShowScroll] = useState(false);
  const breakpoint = useBreakpoint();

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: -100, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);

    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });

  return (
    <>
      {breakpoint[BREAKPOINTS.PHONE] || breakpoint[BREAKPOINTS.TABLET] ? (
        <StyledIoIosArrowDropupCircle
          onClick={scrollTop}
          style={{
            marginBottom: showScroll ? 10 : -150,
            marginLeft: "calc(100vw - 60px)",
            width: "50px",
          }}
        />
      ) : (
        <StyledIoIosArrowDropupCircle
          onClick={scrollTop}
          style={{
            marginBottom: showScroll ? 0 : "-150px",
            marginLeft: 50,
            width: "50px",
          }}
        />
      )}
    </>
  );
};

export default ScrollTopArrow;
