import React from 'react';
import { Helmet } from 'react-helmet';

import { CONSTANTS } from '../../internals/constants';

function AboutHeader() {
  return (
    <Helmet>
      <title>{ CONSTANTS.ABOUT_TITLE }</title>
      <meta
        name="description"
        content={ CONSTANTS.APP_DESCRIPTION }
      />
    </Helmet>
  );
}

export default AboutHeader;
